import React, { useCallback, useEffect, useRef, useState } from "react";
import { BsEmojiSmile, BsThreeDotsVertical } from "react-icons/bs";
import { GrAttachment } from "react-icons/gr";
import { RiSendPlaneFill } from "react-icons/ri";
import useWebSocket from "react-use-websocket";
import ChatLoader from "./ChatLoader";
import { Field, Form, Formik } from "formik";
import Markdown from "react-markdown";
import {
  get_ChatData,
  get_ChatList,
  get_ChatSessionDelete,
} from "../services/chatservice";
import { FiTrash } from "react-icons/fi";
import toast from "react-hot-toast";
import { useLocation, useNavigation, useSearchParams } from "react-router-dom";

interface Message {
  message: string;
  type: string;
  [`time stamp`]: string;
}

interface Chat {
  session_id: number;
  timestamp: string;
  session_description: string | "New Chat";
}

function ChatSection() {
  const data: Message[] = [];
  const navigate = useLocation()

  console.log(navigate.pathname)

  const base_url = navigate.pathname === "/smartsurgn/demochat" ? process.env.REACT_APP_CHAT_DEMO_URL : process.env.REACT_APP_CHAT_URL;
  const socketOptions = {
    protocols: "",
  };
  let [searchParams, setSearchParams] = useSearchParams();
  const [loader, setLoader] = useState(true);
  const [pendingMessage, setPendingMessage] = useState(0);
  const [messageInput, setMessageInput] = useState<string>("");
  const [messages, setMessages] = useState<Message[]>(data);
  const [chatId, setChatId] = useState<number | null>(null);
  const [socketUrl, setSocketUrl] = useState(
    `${base_url}?user_id=${localStorage.getItem(
      "userId"
    )}&auth_token=${localStorage.getItem("token")}`
  );
  const [chats, setChats] = useState<Chat[]>([]);
  const { lastMessage, sendMessage, readyState } = useWebSocket(socketUrl);
  const chatContainerRef = useRef<HTMLDivElement>(null);

  const scrollToNewMessage = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };
  console.log(socketUrl);
  const handleClickChangeSocketUrl = useCallback(
    (id: any) =>
      setSocketUrl(
        `${base_url}?user_id=${localStorage.getItem(
          "userId"
        )}&session_id=${id}&auth_token=${localStorage.getItem("token")}`
      ),
    []
  );
  const handleClickChangeSocketUrlWithoutID = useCallback(
    () =>
      setSocketUrl(
        `${base_url}?user_id=${localStorage.getItem(
          "userId"
        )}&auth_token=${localStorage.getItem("token")}`
      ),
    []
  );
  const handleSendMessage = async () => {
    if (messageInput.length > 0) {
      const newMessage: Message = {
        message: messageInput,
        type: "human",
        [`time stamp`]: new Date().toString(),
      };
      await setMessages((prevMessages) => [...prevMessages, newMessage]);
      await sendMessage(messageInput);
      await setPendingMessage(pendingMessage + 1);
      await setMessageInput("");
      await scrollToNewMessage();
    }
  };

  const receiveMessagehandler = async () => {
    if (lastMessage) {
      const newMessage: Message = {
        message: lastMessage.data,
        type: "ai",
        [`time stamp`]: new Date().toDateString(),
      };
      await setPendingMessage(pendingMessage - 1);
      await setMessages((prevMessages) => [...prevMessages, newMessage]);
      setTimeout(async () => {
        await scrollToNewMessage();
      }, 1000);
    }
  };

 

  // Update messages when a new message is received
  useEffect(() => {
    receiveMessagehandler();
    scrollToNewMessage();
  }, [lastMessage]);

  const deleteSession = async (id: any) => {
    if (id === 1) {
      const deleteNew = chats.slice(1);
      setChats(deleteNew);
      notify("Session deleted");
      console.log(chats[0].session_id);
      setChatId(chats.length > 1 ? chats[1].session_id : null);
    } else {
      searchParams.delete("chat");
      setSearchParams(searchParams);

      if (id === chatId) {
        setMessages([]);
      }
      const response = await get_ChatSessionDelete(id);
      if (response === "Session deleted") {
        notify("Session deleted");
        if (searchParams.get("chat") === null) {
          initialload(false);
        } else {
          initialload(true);
        }
      }
    }
  };
  const newChatHandler = async () => {
    await setMessages([]);
    await setChatId(null);
    await handleClickChangeSocketUrlWithoutID();
    await initialload(false);
  };

  const chatdataHandler = async (id: any) => {
    setChatId(id);
    searchParams.set("chat", id);
    setSearchParams(searchParams);
    initialload(true);
    setMessages([]);
    handleClickChangeSocketUrl(id);
    const response = await get_ChatData(id);
    if (response) {
      if (response?.data?.length > 0) {
        setMessages(response.data);
        scrollToNewMessage();
      }
    }
  };

  useEffect(() => {
    if (searchParams.get("chat") === null && loader === true) {
      setLoader(false);
      initialload(false);
    } else if (searchParams.get("chat") !== null && loader === true) {
      setLoader(false);
      initialload(true);
      chatdataHandler(searchParams.get("chat"));
    }
  }, [loader]);

  const initialload = async (param: boolean) => {
    const response = await get_ChatList();
    if (response) {
      if (response?.data?.length > 0) {
        if (param === true) {
          const list = response.data.sort(
            (a: any, b: any) =>
              new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
          );
          setChats(list);
        } else {
          const list = response.data.sort(
            (a: any, b: any) =>
              new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
          );
          const data = [
            {
              session_id: 1,
              timestamp: new Date(),
              session_description: "New Chat",
            },
            ...list,
          ];
          setChats(data);
          setChatId(data[0].session_id);
          // chatdataHandler(list[0].session_id);
        }
      }
    }
  };

  const notify = (value: any) => {
    toast.success(value);
    // simulate authentication success
  };
  const notifyFailure = (value: string) => {
    toast.error(value.toUpperCase());
    // simulate authentication failure
  };

  return (
    <div className="flex h-full">
      <div className="flex flex-col gap-3 bg-gray-200 p-4 h-full w-1/4">
        <button
          className="bg-primary px-4 py-2 mb-4 rounded-md"
          onClick={() => newChatHandler()}
        >
          New Chat
        </button>

        {chats.map((chat, index) => (
          <div
            className={`w-full rounded-3xl  text-sm font-medium leading-5 focus:outline-none cursor-pointer px-4 py-2 ${
              chatId === chat.session_id
                ? "bg-secondary shadow text-white font-semibold"
                : " text-black"
            } `}
            key={chat.session_id}
          >
            <div className="w-full flex justify-between whitespace-nowrap">
              <div
                className=" font-semibold w-full whitespace-break-spaces"
                onClick={() => chatdataHandler(chat.session_id)}
              >
                {chat.session_description}
              </div>
              <FiTrash
                className="my-auto"
                onClick={() => deleteSession(chat.session_id)}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="flex flex-col flex-auto h-full w-3/4">
        <div className="flex flex-col flex-auto flex-shrink-0 rounded-2xl">
          <div
            className="flex flex-col h-full overflow-x-auto chatHeight scroll-smooth "
            ref={chatContainerRef}
          >
            <div className="flex flex-col h-full">
              <div className="grid grid-cols-12 gap-y-2">
                {messages?.map((item) => (
                  <div
                    key={item.message}
                    className={`${
                      item.type === "ai"
                        ? "col-start-1 col-end-11"
                        : "col-start-6 col-end-13"
                    } p-3 rounded-lg`}
                  >
                    <div
                      className={`${
                        item.type === "ai"
                          ? "flex-row"
                          : "justify-start flex-row-reverse"
                      } flex  items-center`}
                    >
                      <div className="flex items-center justify-center h-10 w-10 rounded-full bg-blue-200 flex-shrink-0">
                        {item.type === "human" ? "B" : "S"}
                      </div>
                      <div
                        className={`${
                          item.type === "ai"
                            ? "bg-gray-100 ml-3"
                            : "bg-indigo-100 mr-3"
                        } relative  text-sm  py-2 px-4 shadow rounded-xl`}
                      >
                        <Markdown components={{ a: LinkRenderer }}>
                          {item.message}
                        </Markdown>
                      </div>
                    </div>
                  </div>
                ))}
                {Array.from({ length: pendingMessage }).map((_, index) => (
                  <div
                    // key={index}
                    className={`${"col-start-1 col-end-8"} p-3 rounded-lg`}
                  >
                    <div className={`${"flex-row"} flex  items-center`}>
                      <div className="flex items-center justify-center h-10 w-10 rounded-full bg-blue-200 flex-shrink-0">
                        S
                      </div>
                      <div
                        className={`${"bg-gray-100 ml-3"} relative  text-sm  py-2 px-4 shadow rounded-xl `}
                      >
                        <div className="">
                          <ChatLoader />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {readyState === 1 ? (
          <Formik
            initialValues={{ input: "" }}
            onSubmit={handleSendMessage}
            enableReinitialize
          >
            {(formik) => (
              <Form>
                <div className="flex flex-row items-center sticky  border-t-2  bottom-0  bg-white w-full py-4 px-2">
                  <div className="flex-grow ml-3">
                    <div className="relative w-full">
                      <Field
                        type="text"
                        name="input"
                        className="flex w-full border rounded-xl focus:outline-none focus:border-indigo-300 px-4 h-10 bg-gray-100"
                        onChange={(e: any) => setMessageInput(e.target.value)}
                        value={messageInput}
                      />
                    </div>
                  </div>
                  <div className="ml-3">
                    <button
                      className="flex items-center justify-center  bg-[#0B4A7B] rounded-xl text-white px-4 py-1 flex-shrink-0"
                      type="submit"
                      // onClick={() => handleSendMessage()}
                    >
                      <span className=" my-auto">
                        <RiSendPlaneFill />
                      </span>
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <div className=" text-center sticky  border-t-2  bottom-0 text-red-500  bg-white w-full py-4 px-2">
            Disconnected
          </div>
        )}
      </div>
    </div>
  );
}

export default ChatSection;
export {};

function LinkRenderer(props: any) {
  return (
    <a
      href={props.href}
      target="_blank"
      rel="noreferrer"
      className="text-blue-400"
    >
      {props.children}
    </a>
  );
}
