import React, { useEffect, useState } from "react";
import Secondarytable from "./secondarytable";
import {
  BiDotsHorizontalRounded,
  BiDotsVerticalRounded,
  BiSearchAlt2,
  BiTrashAlt,
} from "react-icons/bi";
import { IoCloseSharp } from "react-icons/io5";
import { HiMiniBars3, HiMiniBars3CenterLeft } from "react-icons/hi2";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { FiLink2 } from "react-icons/fi";
import { IoMdAdd } from "react-icons/io";
import { getRoleListApi } from "../../services/authServices";
import { useNavigate } from "react-router-dom";
import { AuthLogic } from "../../services/authlogic";
import {
  delete_Device,
  get_Country,
  get_Device,
  get_Device_Page_Count,
  get_Device_Page_Wise_Data,
} from "../../services/adminService";
import ModalComponent from "../../fieldComponent/modalComponent";
import toast from "react-hot-toast";
import { MdModeEdit } from "react-icons/md";
import Pagination from "../pagination/pagination";
import { Backdrop, CircularProgress } from "@mui/material";

interface Data {
  name: string;
  description: string;
  id: number;
  country_id: number;
}

function DevicesComponent() {
  const [data, setData] = useState<Data[]>([]);

  useEffect(() => {
    fetchDevicePageCount(perPage);
    localStorage.removeItem("route");
    initialload();
  }, []);

  const [filterDeviceName, setFilterDeviceName] = useState("");
  const [filterRegion, setFilterRegion] = useState("");
  const [deleteValue, setDeleteValue] = useState(null);
  const [showDeleteModal, setshowDeleteModal] = useState<boolean>(false);
  const [pageNo, setPageNo] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [loader, setLoader] = useState(false);
  const [countryData, setCountryData] = useState<Data[]>([]);

  const handleFilterDeviceNameChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setFilterDeviceName(e.target.value);
    // setPage(1);
  };

  const handleFilterRegion = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFilterRegion(e.target.value);
    // setPage(1);
  };

  // const uniqueUploadedByNames = Array.from(
  //   new Set(data?.map((file) => file.country_name))
  // );

  const filteredFiles = data?.reduce((filtered: any, file) => {
    if (
      filterDeviceName &&
      file.name !== filterDeviceName
      // ||      (filterRegion && file.country_name !== filterRegion)
    ) {
      return filtered;
    }

    return [...filtered, file];
  }, []);
  useEffect(() => {
    initialAuth();
  }, []);
  const navigate = useNavigate();

  const initialAuth = async () => {
    AuthLogic("device list", navigate);
  };
  const handleRowClick = (row: any) => {
    if (row.is_active) {
      navigate(`/smartsurgn/analysis?tab=1&deviceId=${row.id}`);
    } else {
      notifyFailure("Please link guideline to activate device");
    }
  };

  const handleDelete = (value: any) => {
    setDeleteValue(value.id);
    setshowDeleteModal(true);
  };
  const handleEdit = (value: any) => {
    navigate(`/smartsurgn/devices/addDevice?edit=true&deviceId=${value.id}`);
  };
  const fetchDevicePageCount = (perPageValue: number) => {
    setLoader(true);
    const payload = `page_size=${perPageValue}`;
    get_Device_Page_Count(payload)
      .then((res) => {
        if (res.success === true) {
          setTotalPages(res.data.page_count);
          setPageNo(1);
          fetchDeviceData(pageNo, perPageValue);
          setLoader(false);
        }
      })
      .catch((err) => console.log(err));
  };
  const fetchDeviceData = (pageNoValue: number, perPageValue: number) => {
    setLoader(true);
    const payload = `page=${pageNoValue - 1}&page_size=${perPageValue}`;
    get_Device_Page_Wise_Data(payload)
      .then((res) => {
        setData(res?.data);
        setLoader(false);
      })

      .catch((err) => console.log(err));
  };
  const confirmDeleteModalHandler = async () => {
    const response = await delete_Device(deleteValue);
    if (response) {
      notify("Successfully Guideline Deleted");
      fetchDevicePageCount(perPage);
      setDeleteValue(null);
      setshowDeleteModal(false);
    }
  };

  const onNext = () => {
    if (pageNo <= totalPages) {
      setPageNo(pageNo + 1);
      fetchDeviceData(pageNo + 1, perPage);
    }
  };
  const onPrevious = () => {
    if (pageNo > 1) {
      setPageNo(pageNo - 1);
      fetchDeviceData(pageNo - 1, perPage);
    }
  };

  const handleSetPage = (page: number) => {
    setPageNo(page);
    fetchDeviceData(page, perPage);
  };

  const handlePerPage = (value: any) => {
    setPerPage(parseInt(value));
    fetchDevicePageCount(parseInt(value));
  };

  const notify = (value: any) => {
    toast.success(value);
    // simulate authentication success
  };
  const notifyFailure = (value: any) => {
    toast.error(value);
    // simulate authentication failure
  };
  const closeDeleteModalHandler = () => {
    setshowDeleteModal(false);
    setDeleteValue(null);
  };
  const initialload = () => {
    get_Country()
      .then((res) => setCountryData(res?.data))
      .catch((err) => console.log(err));
  };
  return (
    <div className="container-screen z-10">
      <ModalComponent
        showModal={showDeleteModal}
        closeModal={() => setshowDeleteModal(false)}
        header="Confirmation Modal"
        mainBody={ModalBody(confirmDeleteModalHandler, closeDeleteModalHandler)}
      />
      <div className="p-[18px] bg-[#F9F7F7] rounded-xl ">
        <div className="flex justify-between">
          <label className="main-title font-bold">Devices</label>
          <div className="flex w-1/2 gap-2.5">
            <div className="rounded-2xl flex  w-full bg-white">
              <p className="m-auto text-[#060606] opacity-50">Device Name</p>
            </div>
            <button
              className=" rounded-2xl bg-white px-[7px] py-[5px] flex"
              onClick={() => navigate("/smartsurgn/devices/addDevice")}
            >
              <span className="flex gap-2.5 whitespace-nowrap">
                <IoMdAdd className="h-6 w-6 bg-primary text-white p-[5px] rounded-full" />
                Create Device
              </span>
            </button>
          </div>
        </div>
        <div className="p-[18px] bg-[#FBFAFA] mt-2.5">
          <div className=" w-full">
            <div className="flex justify-between mb-10">
              <label className="text-[#060606] font-bold">Devices</label>
              <div className="flex gap-2 w-1/2">
                <select
                  name="deviceName"
                  id="deviceName"
                  className="inputField w-full"
                  value={filterDeviceName}
                  onChange={handleFilterDeviceNameChange}
                >
                  <option value="">Device Name</option>
                  {data?.map((item, index) => (
                    <option key={index} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </select>

                {/* <select
                  name="region"
                  id="region"
                  className="inputField w-full"
                  value={filterRegion}
                  onChange={handleFilterRegion}
                >
                  <option value="">Region</option>
                  {uniqueUploadedByNames?.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </select> */}
              </div>
            </div>
            <div className="z-0">
              <table className="w-full my-6 text-sm ">
                <thead>
                  <tr className="border-b-2">
                    <th className="pb-2.5 text-start">Device Name</th>
                    <th className="pb-2.5 text-center">Description</th>
                    <th className="pb-2.5 text-center">Country</th>
                    <th className="pb-2.5 text-center">Updated By</th>
                    <th className="pb-2.5 text-center">Active</th>
                    <th className="pb-2.5 text-start">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredFiles?.map((row: any) => (
                    <React.Fragment key={row.id}>
                      <tr className=" ">
                        <td
                          className="py-2.5 text-start cursor-pointer hover:font-bold hover:text-blue-800"
                          onClick={() => handleRowClick(row)}
                        >
                          {row.name}
                        </td>
                        <td className="py-2.5 text-center">
                          {row.description}
                        </td>
                        <td className="py-2.5 text-center">
                          {
                            countryData.filter(
                              (item: any) => item.id === row.country_id
                            )[0].name
                          }
                        </td>
                        <td className="py-2.5 text-center">
                          {row.updated_by}
</td>
                        <td className="py-2.5 text-center">
                          {row.is_active? "Yes": "No"}
</td>
                        <td className="py-2.5 text-end">
                          <div className="flex text-blue-500 justify-between">
                            <BiTrashAlt
                              className="text-lg cursor-pointer"
                              onClick={() => handleDelete(row)}
                            />
                            {/* <HiMiniBars3 className="text-lg cursor-pointer" />
                            <HiMiniBars3CenterLeft className="text-lg rotate-180 cursor-pointer" />
                            <IoCloseSharp className="text-lg cursor-pointer" />
                            <AiOutlineArrowLeft className="text-lg cursor-pointer" />
                            <AiOutlineArrowRight className="text-lg cursor-pointer" /> */}
                            <MdModeEdit
                              className="text-lg cursor-pointer "
                              onClick={() => handleEdit(row)}
                            />
                            <BiDotsVerticalRounded className="text-lg cursor-pointer" />
                            {/* <BiDotsHorizontalRounded className="text-lg cursor-pointer" />
                            <FiLink2 className="text-lg cursor-pointer" />
                            <BiSearchAlt2 className="text-lg cursor-pointer" /> */}
                          </div>
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                  <tr>
                    <td colSpan={6}>
                      <Pagination
                        currentPage={pageNo}
                        onNext={onNext}
                        onPrevious={onPrevious}
                        from={pageNo * perPage - perPage}
                        to={pageNo * perPage}
                        totalItems={totalPages * perPage}
                        perPage={perPage}
                        totalPages={totalPages}
                        setPage={handleSetPage}
                        setperPage={handlePerPage}
                      />
                    </td>{" "}
                  </tr>
                </tbody>
              </table>
            </div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loader}
              // onClick={handleClose}
            >
              <CircularProgress style={{ color: "#083558", zIndex: 1 }} />
            </Backdrop>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DevicesComponent;

const ModalBody = (
  confirmDeleteModalHandler: any,
  closeDeleteModalHandler: any
) => {
  return (
    <div className="p-6 space-y-[30px] bg-[#EEEEEE] rounded-2xl">
      <div>Are you sure you want to delete this Device?</div>
      <h2 className="flex justify-between   rounded-md mt-10 w-full">
        <div className="my-auto">
          <p className="  text-[14px] text-black my-auto "></p>
        </div>
        <div className=" flex gap-3 ">
          <button
            type="button"
            onClick={() => closeDeleteModalHandler()}
            className="rounded-2xl bg-secondary px-3 py-[5px] flex text-white "
          >
            No
          </button>
          <button
            type="button"
            className="rounded-2xl bg-white px-3 py-[5px] flex"
            onClick={() => confirmDeleteModalHandler()}
          >
            <span className="flex gap-2.5 whitespace-nowrap">
              <IoMdAdd className="h-6 w-6 bg-primary text-white p-[5px] rounded-full" />
              YES
            </span>
          </button>
        </div>
      </h2>
    </div>
  );
};
