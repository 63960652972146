import React, { useEffect, useState } from "react";
import { IoMdAdd } from "react-icons/io";
import ModalComponent from "../../fieldComponent/modalComponent";
import CreateRoleModalBody from "./createRoleModalBody";
import { getRoleListApi, postCreateRoleApi, postUpdateRoleApi } from "../../services/authServices";
import toast from "react-hot-toast";
import { MdOutlineModeEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";

interface Data {
  name: string;
  id: number;
  sections: sections[];
}

interface sections {
  name: string;
  tabs: tabs[];
}

interface tabs {
  name: string;
}
const data: Data[] = [
  {
    id: 1,
    name: "Role_2",
    sections: [
      {
        name: "Analysis",
        tabs: [
          {
            name: "Risk Management",
          },
        ],
      },
      {
        name: "Device DashBoard",
        tabs: [
          {
            name: "Guidelines",
          },
        ],
      },
    ],
  },
];
function CreateRole() {
  useEffect(() => {
    initialload();
  }, []);
const navigate = useNavigate()
  const initialload = async () => {
    try {
      const response = await getRoleListApi(navigate);
      if(response?.status === 403){
        // notifyFailure("Session Expired")
        return;
      }
      if (!response || !response.data) {
        console.error("Invalid response format.");
        return;
      }

      const formattedData: Data[] = Object.keys(response.data).map(
        (roleKey) => {
          const role = response.data[roleKey];

          return {
            id: role.id,
            name: roleKey,
            sections: Object.keys(role)
              .map((sectionKey) => {
                // Exclude "id" property from being treated as a section
                if (sectionKey !== "id") {
                  const tabs = role[sectionKey];
                  return {
                    name: sectionKey,
                    tabs: Array.isArray(tabs)
                      ? tabs.map((tab) => ({ name: tab }))
                      : [],
                  };
                }
                return null; // Skip "id" property
              })
              .filter(Boolean) as sections[], // Explicitly cast to sections[]
          };
        }
      );

      setdatas(formattedData);
    } catch (error) {
      console.error("Error fetching role list:", error);
    }
  };

  const [showModal, setshowModal] = useState<boolean>(false);
  const [datas, setdatas] = useState<Data[]>([]);
  const [editValue, setEditValue] = useState(null);

  const closeModalHandler = () => {
    setshowModal(false);
    setEditValue(null)
  };
  const confirmModalHandler = async (value: Data, edit: boolean) => {
    if(!edit){
      const response = await postCreateRoleApi(value);
      console.log(response);
      if (response.Success == true && response["Inserted IDs"]?.length > 0) {
        notify("Role created successfully");
        setshowModal(false);
        initialload();
      }
    }else if(edit){
      const response = await postUpdateRoleApi(value);
      console.log(response);
      if (response.Success == true) {
        notify("Role Updated successfully");
        setEditValue(null)
        setshowModal(false);
        initialload();
      }
    }
  };
  const editHandler = (value: any) => {
    setEditValue(value);
    setshowModal(true);
  };
  const notify = (value: any) => {
    toast.success(value);
    // simulate authentication success
  };
  const notifyFailure = (value: any) => {
    toast.error(value);
    // simulate authentication failure
  };
  return (
    <div className="p-8 flex flex-col gap-y-10">
      <div className="bg-[#EEEEEE] p-5 rounded-2xl">
        <div className="mb-10 flex justify-between">
          <label className="text-[#060606] font-bold text-xl">Roles</label>
          <button
            type="button"
            onClick={() => setshowModal(true)}
            className="rounded-2xl bg-white px-[7px] py-[5px] flex"
          >
            <span className="flex gap-2.5 whitespace-nowrap">
              <IoMdAdd className="h-6 w-6 bg-primary text-white p-[5px] rounded-full" />
              Add Role
            </span>
          </button>
        </div>
        <ModalComponent
          showModal={showModal}
          closeModal={closeModalHandler}
          header="Add New Role"
          mainBody={CreateRoleModalBody(
            confirmModalHandler,
            closeModalHandler,
            editValue
          )}
        />
        <div>
          <table className="w-full my-6 text-sm">
            <thead className="border-b-2 border-b-[#E1DFDF]">
              <tr>
                <th className="py-2 px-4 text-start font-semibold">Name</th>
                <th className="py-2 px-4 text-center font-semibold">
                  Sections
                </th>
                <th className="py-2 px-4 text-cenetr font-semibold">Tabs</th>
                <th className="py-2 px-4 text-end font-semibold">Action</th>
              </tr>
            </thead>

            <tbody className="">
              {datas?.length > 0 &&
                datas?.map((item) => (
                  <tr
                    key={item.id}
                    className=" cursor-pointer border-b-2 border-b-[#E1DFDF]"
                  >
                    <td className="py-3 px-4 text-start font-semibold">
                      {item.name}
                    </td>

                    <td className="py-3 px-4 text-center">
                      <div className="flex flex-col">
                        {item?.sections?.map((section) => (
                          <p className="text-sm font-semibold mb-4 my-auto">
                            {section.name}
                          </p>
                        ))}
                      </div>
                    </td>

                    <td className=" px-4 text-center">
                      <div className="flex flex-col">
                        {item?.sections?.map((section) => (
                          <div className="mb-4">
                            {section?.tabs?.map((tab) => (
                              <span className="rounded-full bg-white px-3 py-1 text-sm border border-gray-300 mr-2 mb-2">
                                {tab.name}
                              </span>
                            ))}
                          </div>
                        ))}
                      </div>
                    </td>
                    <td className="text-end flex">
                      <div
                        className="ml-auto flex hover:font-bold text-secondary"
                        onClick={() => editHandler(item)}
                      >
                        <MdOutlineModeEdit className="text-xl" />
                        Edit
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default CreateRole;
