import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SettingsIcon from "@mui/icons-material/Settings";
import { NavLink, Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { Theme } from "@mui/material/styles";
import {
  Backdrop,
  CircularProgress,
  Collapse,
  Grid,
  ListItemButton,
  SvgIcon,
  TextField,
} from "@mui/material";
import logo from "../assets/images/logo-mark-OG.png";
import logo1 from "../assets/images/logo 1.png";
import smartsurgn from "../assets/images/word-mark-OG.png";
import openenterprise from "../assets/images/logo 1.png";
import "./sidebar.css";
import ProfileButton from "./profile";
import GridViewIcon from "@mui/icons-material/GridView";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import { useState } from "react";
import ChecklistIcon from "@mui/icons-material/Checklist";
import EngineeringIcon from "@mui/icons-material/Engineering";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import GroupsIcon from "@mui/icons-material/Groups";
import LayersIcon from "@mui/icons-material/Layers";
import { Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { getRoleListApi } from "../services/authServices";

const expandedDrawerWidth = 240;

interface Data {
  name: string;
}

const openedMixin = (theme: Theme) => ({
  width: expandedDrawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  overflowY: "hidden",
});

const closedMixin = (theme: Theme) => ({
  backgound: "green",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
});
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  background: "yellow",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }: { theme: Theme; open?: boolean }) => ({
  zIndex: theme.zIndex.drawer + 1,
  color: theme.palette.secondary.contrastText,
  background: theme.palette.secondary.main,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: expandedDrawerWidth,
    width: `calc(100% - ${expandedDrawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }: { theme: Theme; open?: boolean }) => ({
  width: expandedDrawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const RoundedListItem = styled(ListItem)(({ theme }) => ({
  borderRadius: "20px", // Adjust the border radius as per your preference
  marginbottom: theme.spacing(1), // Add margin bottom to create space between buttons
  marginLeft: theme.spacing(1.5),
  marginTop: theme.spacing(1),
  backgroundColor: "white",
  color: "#E65F2B",
  width: "90%",
}));

const SubList = styled(List)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
}));

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
}));

enum eUserIconsDisplayState {
  doesNotCare,
  wantsItOpen,
  wantsItClosed,
}

enum eDrawerState {
  iconMenu,
  fullMenu,
  hidden,
}

interface ParentPreferenceCompProps {
  preferenceComp?: React.ReactNode;
  statusComp?: React.ReactNode;
  children?: React.ReactNode;
}

export const MiniDrawer: React.FC<ParentPreferenceCompProps> = ({
  preferenceComp,
  statusComp,
  children,
}) => {
  const theme = useTheme();
  const [openFullText, setOpenFullText] = React.useState(false);
  //   const [openIcons, setOpenIcons] = React.useState(true);
  const [userIconDrawerDisplayState, setUserIconDrawerDisplayState] =
    React.useState(eUserIconsDisplayState.doesNotCare);
  const displayIconDrawerSizewise = useMediaQuery(theme.breakpoints.up("sm"));

  const drawerState: eDrawerState =
    displayIconDrawerSizewise && openFullText
      ? // large enough to display icon drawer and in openFulltext Mode:
        eDrawerState.fullMenu
      : displayIconDrawerSizewise && !openFullText
      ? // large enough to display icons and not commanded to full text
        eDrawerState.iconMenu
      : !displayIconDrawerSizewise &&
        (userIconDrawerDisplayState === eUserIconsDisplayState.doesNotCare ||
          userIconDrawerDisplayState === eUserIconsDisplayState.wantsItClosed)
      ? // too small to display filter and not commanded to different or commanded to be closed:
        eDrawerState.hidden
      : !displayIconDrawerSizewise &&
        userIconDrawerDisplayState === eUserIconsDisplayState.wantsItOpen
      ? // too small to display filter but commanded to be open. command overides
        eDrawerState.iconMenu
      : // else show only content -- should not happen
        eDrawerState.iconMenu;

  const handleDrawerDisplayChange = () => {
    if (displayIconDrawerSizewise) {
      // large enough to open iconDrawer
      if (drawerState === eDrawerState.fullMenu) {
        setOpenFullText(false);
      } else if (drawerState === eDrawerState.iconMenu) {
        setOpenFullText(true);
      }
    } else {
      // too small for iconDrawer
      if (drawerState === eDrawerState.iconMenu) {
        setUserIconDrawerDisplayState(eUserIconsDisplayState.wantsItClosed);
      } else {
        setUserIconDrawerDisplayState(eUserIconsDisplayState.wantsItOpen);
      }
    }
  };

  // capital D ! to be recognized
  const DrawerIcon: typeof SvgIcon =
    drawerState === eDrawerState.iconMenu && displayIconDrawerSizewise
      ? MenuIcon
      : drawerState === eDrawerState.iconMenu && !displayIconDrawerSizewise
      ? ExpandLessIcon
      : drawerState === eDrawerState.fullMenu
      ? MenuOpenIcon
      : drawerState === eDrawerState.hidden
      ? ExpandMoreIcon
      : MenuIcon;

  const [loader, setLoader] = useState(false);
  const [authenticate, setAuthenticate] = useState(false);
  const [sections, setSections] = useState<Data[]>([]);

  console.log(sections)
  const location = useLocation();

  React.useEffect(() => {
    if (isAuthenticated()) {
      setAuthenticate(true);
      setLoader(true);
      initialload();
    } else {
      setAuthenticate(false);
    }
  }, [location]);
  
const navigate = useNavigate()
  const initialload = async () => {
    try {
      const rolesString = localStorage.getItem("role");
      // Get roles from localStorage
      if (!rolesString) {
        setLoader(false);
        console.error("Roles not found in localStorage.");
        return;
      }

      const roles: string[] = JSON.parse(rolesString);

      // Fetch roles data from the API
      const response = await getRoleListApi(navigate);
      if(response?.status === 403){
        setLoader(false);
        // notifyFailure("Session Expired")
        return;
      }
      if (!response || !response.data) {
        console.error("Invalid response format.");
        setLoader(false)
        return;
      }

      const formattedData: { name: string }[] = roles?.reduce(
        (acc, roleName) => {
          const role = response.data[roleName];

          if (role) {
            const sections = Object.keys(role)
              .filter((sectionKey) => sectionKey !== "id") // Exclude "id" section
              .map((sectionKey) => ({
                name: sectionKey,
              }));

            acc.push(...sections);
          } else {
            console.error(`Role "${roleName}" not found in API response.`);
          }
          return acc;
        },
        [] as { name: string }[]
        );
        
        setLoader(false);
      // Filter out repeated and unique sections
      const uniqueSections = Array.from(
        new Set(formattedData.map((s) => s.name))
      ).map((name) => ({
        name,
      }));

      setSections(uniqueSections);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error fetching role list:", error);
    }
  };
  const notifyFailure = (value: string) => {
    toast.error(value.toUpperCase());
    // simulate authentication failure
  };

  return (
    <div className="flex-1 ">
      <AppBar position="fixed" theme={theme} className="bg-white">
        <Toolbar sx={{ display: "flex" }}>
          <Grid container spacing={1} alignItems={"center"}>
            {authenticate ? (
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerDisplayChange}
                  edge="start"
                >
                  <DrawerIcon />
                </IconButton>
              </Grid>
            ) : null}
            <Grid item xs sx={{ display: { xs: "none", sm: "block" } }}>
              <Typography
                variant="h6"
                color="textSecondary"
                //sx={{ textDecoration: "none" }}
                noWrap
                component={RouterLink}
                to="/login"
              >
                {process.env.REACT_APP_TYPE === "smsn" && (
                  <>
                    <img className="logo" alt="Logo" src={logo} />
                    <img
                      className="w-52 ml-12"
                      alt="smartsurgn"
                      src={smartsurgn}
                    />
                  </>
                )}
                {process.env.REACT_APP_TYPE === "oeai" && (
                  <img
                    className="w-52 ml-12"
                    alt="openenterprise"
                    src={openenterprise}
                  />
                )}
              </Typography>
            </Grid>
                  {authenticate && 
            <Grid item>
              <div className="textbox bg-[#F4F0F0]">
                <div className="frame-wrapper ">
                  <TextField
                    className="bg-[#F4F0F0]"
                    size="small"
                    placeholder="Search for anything..."
                    sx={{
                      width: "300px",
                      mt: "5px",
                      minWidth: "320px",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "rgba(126, 139, 182, 0.3)",
                        borderRadius: "8px",
                        backgroundColor: "white",
                        "&:hover fieldset": {
                          borderColor: "green",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "green",
                        },
                        "& input, & .MuiOutlinedInput-input": {
                          color: "black", // Adjust the text color here
                          background: "transparent", // Set transparent background for text input
                        },
                        "& input::placeholder, & .MuiOutlinedInput-input::placeholder":
                          {
                            color: "rgba(0, 0, 0, 0.54)", // Adjust the placeholder color here
                          },
                      },
                    }}
                  />
                </div>
                <ProfileButton sections={sections} />
              </div>
            </Grid>
                }
          </Grid>
        </Toolbar>
      </AppBar>
      {authenticate ? (
        <Drawer
          variant="permanent"
          theme={theme}
          open={openFullText}
          sx={{
            width: "0",
            ...(drawerState === eDrawerState.hidden && { display: "none" }),
          }}
        >
          <DrawerHeader />
          <Divider />
          <List>
            {sections?.some((item) => item.name.toLowerCase() === "admin") && (
              <NavLink
                to="smartsurgn/admin"
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "text-primary" : ""
                }
              >
                {({ isActive, isPending, isTransitioning }) => (
                  <RoundedListItem
                    id="Admin"
                    sx={{
                      bgcolor: (theme) =>
                        isActive
                          ? theme.palette.secondary.contrastText
                          : "inherit",
                    }}
                  >
                    <div className="flex">
                      <ListItemIcon>
                        <AdminPanelSettingsIcon
                          style={{
                            color: isActive ? "#00A92F" : "#000",
                          }}
                        />
                      </ListItemIcon>
                      <StyledListItemText
                        style={{ color: isActive ? "#00A92F" : "#000" }}
                        primary="Admin"
                      />
                    </div>
                  </RoundedListItem>
                )}
              </NavLink>
            )}
            {sections?.some((item) => item.name.toLowerCase() === "device list") && (
              <NavLink
                to="smartsurgn/devices"
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "text-[#00A92F]" : ""
                }
              >
                {({ isActive, isPending, isTransitioning }) => (
                  <RoundedListItem
                    id="devices"
                    sx={{
                      bgcolor: (theme) =>
                        isActive
                          ? theme.palette.secondary.contrastText
                          : "inherit",
                    }}
                  >
                    <div className="flex">
                      <ListItemIcon>
                        <DashboardOutlinedIcon
                          style={{
                            color: isActive ? "#00A92F" : "black",
                          }}
                        />
                      </ListItemIcon>
                      <StyledListItemText
                        style={{ color: isActive ? "#00A92F" : "black" }}
                        primary="Devices List"
                      />
                    </div>
                  </RoundedListItem>
                )}
              </NavLink>
            )}
            {sections?.some(
              (item) => item.name.toLowerCase() === "device dashboard"
            ) && (
              <NavLink
                to="smartsurgn/deviceDashboard"
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "text-[#00A92F]" : ""
                }
              >
                {({ isActive, isPending, isTransitioning }) => (
                  <RoundedListItem
                    id="analysis"
                    sx={{
                      bgcolor: (theme) =>
                        isActive
                          ? theme.palette.secondary.contrastText
                          : "inherit",
                    }}
                  >
                    <div className="flex">
                      <ListItemIcon>
                        <EngineeringIcon
                          style={{
                            color: isActive ? "#00A92F" : "black",
                          }}
                        />
                      </ListItemIcon>
                      <StyledListItemText
                        style={{ color: isActive ? "#00A92F" : "black" }}
                        primary="Device Dashboard"
                      />
                    </div>
                  </RoundedListItem>
                )}
              </NavLink>
            )}
          </List>
        </Drawer>
      ) : null}
      {/* Use a auto box to extend the children 
       for xs, no margins around the browser window
       for sm, margin 1 to right and depending on drawer state 0 or 8 to the left */}
      <Box
        sx={{
          width: "100%",
          // marginInlineEnd: { xs: 0, sm: 1 },
        }}
      >
        {/*  Shift down the content with an empty DrawerHeader:
          https://stackoverflow.com/questions/48508449/content-beneath-fixed-appbar
          */}
        <DrawerHeader />
        <Box
          marginTop={0}
          component="main"
          sx={
            isAuthenticated()
              ? {
                  flexGrow: 1,
                  p: 3,
                  marginLeft:
                    drawerState === eDrawerState.iconMenu
                      ? "56px"
                      : `${expandedDrawerWidth + 10}px`,
                  transition: theme.transitions.create("margin", {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                  }),
                }
              : null
          }
        >
          {children}
        </Box>
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
        // onClick={handleClose}
      >
        <CircularProgress style={{ color: "#083558" }} />
      </Backdrop>
    </div>
  );
};

function isAuthenticated() {
  // check if the user is authenticated
  return localStorage.getItem("token") !== null;
}
