import React, { useEffect, useState } from "react";
import { IoMdAdd } from "react-icons/io";
import ModalComponent from "../../fieldComponent/modalComponent";
import CreateUserModalBody from "./createUserModalBody";
import {
  deleteUserApi,
  postResetPasswordUserApi,
  postUpdateUserApi,
  signupApi,
  userDetailApi,
} from "../../services/authServices";
import toast from "react-hot-toast";
import { RiLockPasswordLine } from "react-icons/ri";
import { MdLockReset, MdOutlineModeEdit } from "react-icons/md";
import { GoTrash } from "react-icons/go";

interface Data {
  name: string;
  username: string;
  password: string;
  user_id: number;
  roles_list: [""];
}

function CreateUser() {
  const [showModal, setshowModal] = useState<boolean>(false);
  const [showDeleteModal, setshowDeleteModal] = useState<boolean>(false);
  const [datas, setdatas] = useState<Data[]>([]);
  const [editValue, setEditValue] = useState(null);
  const [deleteValue, setDeleteValue] = useState(null);
  const [edit, setEdit] = useState<string>("null");

  const closeModalHandler = () => {
    setshowModal(false);
    setEdit("null");
    setEditValue(null);
  };
  useEffect(() => {
    initialLoad();
  }, []);

  const initialLoad = async () => {
    await userDetailApi()
      .then((res) => {
        setdatas(res?.data);
      })
      .catch((err) => notifyFailure("some error occurs"));
  };
  const confirmModalHandler = async (payload: Data) => {
    if (edit === "update") {
      await postUpdateUserApi(payload)
        .then((res) => {
          if (res?.success === "Details changed") {
            setshowModal(false);
            setEdit("null");
            setEditValue(null);
            notify(res?.success);
            initialLoad();
          } else if (res?.error === "Username already exist") {
            notifyFailure(res?.error);
            setEdit("null");
            setEditValue(null);
            setshowModal(false);
            initialLoad();
          }
        })
        .catch((err) => notifyFailure("some error occurs"));
    } else if (edit === "password") {
      await postResetPasswordUserApi(payload)
        .then((res) => {
          if (res?.success === "Password successfully changed") {
            setshowModal(false);
            setEdit("null");
            setEditValue(null);
            notify(res?.success);
            initialLoad();
          } else if (res?.error == " Details does not match") {
            notifyFailure(res?.error);
            setEdit("null");
            setEditValue(null);
            setshowModal(false);
            initialLoad();
          }
        })
        .catch((err) => notifyFailure("some error occurs"));
      // setdatas((prevDatas) => [...prevDatas, value]);
    } else if (edit == "null") {
      await signupApi(payload)
        .then((res) => {
          if (res?.data !== "Username already exist") {
            setshowModal(false);
            notify(res?.data);
            initialLoad();
          } else {
            notifyFailure(res?.data);
            setshowModal(false);
            initialLoad();
          }
        })
        .catch((err) => notifyFailure("some error occurs"));
      // setdatas((prevDatas) => [...prevDatas, value]);
    }
  };

  const handleUpdate = (value: any) => {
    setEditValue(value);
    setEdit("update");
    setshowModal(true);
  };
  const handleReset = (value: any) => {
    setEditValue(value);
    setEdit("password");
    setshowModal(true);
  };
  const AddUserHandler = () => {
    if (datas?.length > 6) {
      notifyFailure(
        `Additional user creation is currently restricted.
         Please reach out to support@OpenEnterprise.ai for assistance.`
      );
    } else {
      setshowModal(true);
    }
  };
  const notify = (value: any) => {
    toast.success(value);
    // simulate authentication success
  };
  const notifyFailure = (value: any) => {
    toast.error(value);
    // simulate authentication failure
  };
  const handleDelete = (value: any) => {
    setDeleteValue(value.user_id);
    setshowDeleteModal(true);
  };
  const confirmDeleteModalHandler = async () => {
    const response = await deleteUserApi(deleteValue);
    console.log(response);
    if (response.data === "Successfully deleted the user") {
      notify(response.data)
      initialLoad();
      setDeleteValue(null);
      setshowDeleteModal(false);
    }
  };
  const closeDeleteModalHandler = () => {
    setshowDeleteModal(false);
    setDeleteValue(null);
  };



  return (
    <div className="p-8 flex flex-col gap-y-10">
      <div className="bg-[#EEEEEE] p-5 rounded-2xl">
        <div className="mb-10 flex justify-between">
          <label className="text-[#060606] font-bold text-xl">Users</label>
          <button
            type="button"
            onClick={() => AddUserHandler()}
            className="rounded-2xl bg-white px-[7px] py-[5px] flex"
          >
            <span className="flex gap-2.5 whitespace-nowrap">
              <IoMdAdd className="h-6 w-6 bg-primary text-white p-[5px] rounded-full" />
              Add Users
            </span>
          </button>
        </div>
        <ModalComponent
          showModal={showModal}
          closeModal={closeModalHandler}
          header={
            edit === "update"
              ? "Update User"
              : edit === "password"
              ? "Update Password"
              : "Add User"
          }
          mainBody={CreateUserModalBody(
            confirmModalHandler,
            closeModalHandler,
            editValue,
            edit
          )}
        />
        <ModalComponent
          showModal={showDeleteModal}
          closeModal={() => setshowDeleteModal(false)}
          header="Confirmation Modal"
          mainBody={ModalBody(
            confirmDeleteModalHandler,
            closeDeleteModalHandler
          )}
        />
        <div>
          <table className="w-full my-6 text-sm">
            <thead className="border-b-2 border-b-[#E1DFDF]">
              <tr>
                <th className="pb-2.5 text-start">Name</th>
                <th className="pb-2.5 text-center">UserName</th>
                <th className="pb-2.5 text-center">Roles</th>
                <th className="pb-2.5 text-end">Action</th>
              </tr>
            </thead>
            <tbody className="">
              {datas?.length > 0 &&
                datas?.map((item) => (
                  <tr
                    key={item.user_id}
                    className=" cursor-pointer border-b-2 border-b-[#E1DFDF] gap-3"
                  >
                    <td className="py-5 text-start">{item.name}</td>
                    <td className="py-5 text-center w-2/3">{item.username}</td>
                    <td className="p-5 text-center">
                      {item?.roles_list?.map((roles, index) => (
                        <span key={index}>
                          {roles}
                          {index !== item.roles_list.length - 1 ? "," : ""}{" "}
                        </span>
                      ))}
                    </td>
                    <td className="py-5 text-end flex gap-4 justify-around">
                      <MdOutlineModeEdit
                        title="Edit User"
                        className="text-xl hover:font-bold hover:text-blue-800 text-primary"
                        onClick={() => handleUpdate(item)}
                      />
                      <MdLockReset
                        title="Reset Password"
                        className="text-2xl  hover:font-bold hover:text-blue-800 text-secondary"
                        onClick={() => handleReset(item)}
                      />
                      <GoTrash
                        title="Delete User"
                        className="text-xl  hover:font-bold hover:text-red-900 text-secondary text-red-500"
                        onClick={() => handleDelete(item)}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default CreateUser;

const ModalBody = (
  confirmDeleteModalHandler: any,
  closeDeleteModalHandler: any
) => {
  return (
    <div className="p-6 space-y-[30px] bg-[#EEEEEE] rounded-2xl">
      <div>Are you sure you want to delete this User?</div>
      <h2 className="flex justify-between   rounded-md mt-10 w-full">
        <div className="my-auto">
          <p className="  text-[14px] text-black my-auto "></p>
        </div>
        <div className=" flex gap-3 ">
          <button
            type="button"
            onClick={() => closeDeleteModalHandler()}
            className="rounded-2xl bg-secondary px-3 py-[5px] flex text-white "
          >
            No
          </button>
          <button
            type="button"
            className="rounded-2xl bg-white px-3 py-[5px] flex"
            onClick={() => confirmDeleteModalHandler()}
          >
            <span className="flex gap-2.5 whitespace-nowrap">
              <IoMdAdd className="h-6 w-6 bg-primary text-white p-[5px] rounded-full" />
              YES
            </span>
          </button>
        </div>
      </h2>
    </div>
  );
};
