import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import { BsCheckCircle } from "react-icons/bs";

interface DeltaPdfDownloadProps {
  data: { [key: string]: { [key: string]: string } };
  filterCountry: string;
  filterFirstCountry: string;
  firstGuideline: string;
  secondGuideline: string;
}

const DeltaPdfDownload: React.FC<DeltaPdfDownloadProps> = ({
  data,
  filterCountry,
  filterFirstCountry,
  firstGuideline,
  secondGuideline
}) => {
  const styles = StyleSheet.create({
    page: {
      backgroundColor: "#ECE6E657",
      color: "#000",
      padding: 10,
    },
    section: {
      marginBottom: 10,
    },
    table: {
      flexDirection: "column",
      width: "100%",
      //   borderStyle: "solid",
      //   borderWidth: 1,
    },
    tableRow: {
      flexDirection: "row",
    },
    tableCol: {
      width: "14.28%", // 100% / 7 columns
      //   borderStyle: "solid",
      //   borderWidth: 1,
      paddingLeft: 5,
      fontSize: 8,
    },
    tableCell: {
      padding: 8,
      textAlign: "left",
    },
    tableHeader: {
      backgroundColor: "#E1DFDF",
      fontWeight: "bold",
    },
    expandedRow: {
      backgroundColor: "#F9F7F7",
      borderRadius: 20,
      width: "100%",
      marginBottom: 10,
    },
    expandedTable: {
      width: "100%",
    },
    expandedContent: {
      borderRadius: 20,
    },
    tableCellExpanded: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    icon: {
      fontSize: 20, // Adjust the font size as needed
      color: "#1A932E", // Set the color
      margin: "auto",
      // Add other styles as needed
    },
  });

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={{ fontSize: 16, fontWeight: "bold", marginBottom: 10 }}>
            Delta table
          </Text>
          <View style={styles.table}>
            {/* Table header */}
            <View
              style={[
                styles.tableRow,
                {
                  borderBottom: 1,
                  borderBottomColor: "#E1DFDF",
                  paddingBottom: "5px",
                },
              ]}
            >
              <Text style={styles.tableCol}>File Name</Text>
              <Text style={styles.tableCol}>Download Links</Text>
              <Text style={styles.tableCol}>Status</Text>
              <Text style={styles.tableCol}>Progress</Text>
              <Text style={styles.tableCol}>{filterCountry}</Text>
              <Text style={styles.tableCol}>{filterFirstCountry}</Text>
            </View>
            {/* Table rows */}
            {Object.entries(data)?.map(([section, details], index) => (
              <View
                style={{
                  borderBottom: 1,
                  borderBottomColor: "#E1DFDF",
                  paddingBottom: "5px",
                  marginTop: "5px",
                }}
              >
                <View key={index} style={styles.tableRow}>
                  <Text style={styles.tableCol}>{section}</Text>
                  <Text style={styles.tableCol}>https://example.com/file1</Text>
                  <Text style={styles.tableCol}>Completed</Text>
                  {/* Add your CircularProgress and icons here */}
                  <Text style={styles.tableCol}>100%</Text>
                  <Text style={styles.tableCol}>✅</Text>
                  <Text style={styles.tableCol}>✅</Text>
                </View>
                <View style={[styles.expandedRow]}>
                  <View style={styles.tableCellExpanded}>
                    <View style={styles.expandedContent}>
                      <View style={styles.expandedTable}>
                        {/* Expanded Table header */}
                        <View style={[styles.tableRow, styles.tableHeader]}>
                          <View
                            style={[
                              styles.tableCol,
                              styles.tableCell,
                              { width: "50%" },
                            ]}
                          >
                            <Text>{filterCountry}</Text>
                          </View>
                          <View
                            style={[
                              styles.tableCol,
                              styles.tableCell,
                              { width: "50%" },
                            ]}
                          >
                            <Text>{filterFirstCountry}</Text>
                          </View>
                        </View>
                        {/* Expanded Table rows */}
                        <View style={styles.tableRow}>
                          <View
                            style={[
                              styles.tableCol,
                              styles.tableCell,
                              { width: "50%" },
                            ]}
                          >
                            <Text>{details[`${firstGuideline}`]}</Text>
                          </View>
                          <View
                            style={[
                              styles.tableCol,
                              styles.tableCell,
                              { width: "50%" },
                            ]}
                          >
                            <Text>{details[`${secondGuideline}`]}</Text>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default DeltaPdfDownload;
