import React from "react";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";

const CertificateDetails = ({
  checklistData,
  handleCheckboxChange,
  handleSubmit,
}) => {
  return (
    <div className="flex flex-col gap-10">
      <button
        className="bg-primary rounded-md w-fit px-2 py-1"
        onClick={() => handleSubmit()}
      >
        Submit
      </button>
      {checklistData?.map((checklist) => (
        <div key={checklist.id}>
          <h2 className="mb-2 text-xl font-bold underline">{checklist.name}</h2>
          {Object.entries(checklist.checklist).map(
            ([category, categoryData]) => (
              <div key={category} className="mb-3">
                <h3 className="mb-3 text-lg font-semibold">{category}</h3>
                {categoryData.items.map((item) => (
                  <div className="flex gap-2 items-center" key={item.name}>
                    <div className="w-6">
                      {item.status ? (
                        <MdCheckBox
                          className="text-[#17C05B] text-2xl w-5"
                          onClick={() =>
                            handleCheckboxChange(
                              checklist.id,
                              category,
                              item.name
                            )
                          }
                        />
                      ) : (
                        <MdCheckBoxOutlineBlank
                          className="text-2xl w-5"
                          onClick={() =>
                            handleCheckboxChange(
                              checklist.id,
                              category,
                              item.name
                            )
                          }
                        />
                      )}
                    </div>
                    <input
                      type="checkbox"
                      id={item.name}
                      className="sr-only"
                      checked={item.status}
                      onChange={() =>
                        handleCheckboxChange(checklist.id, category, item.name)
                      }
                    />
                    <label
                      htmlFor={item.name}
                      className="cursor-pointer"
                      style={{ marginLeft: "5px" }}
                    >
                      {item.name}
                    </label>
                  </div>
                ))}
              </div>
            )
          )}
        </div>
      ))}
    </div>
  );
};

export default CertificateDetails;
