import React, { useRef } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";

interface TimeLineProps {
  data: {
    task_name: string;
    users: any;
    task_date: string;
    document_name: string;
    summary: string;
  }[];
}

const VerticalTimelineComponent: React.FC<TimeLineProps> = ({ data }) => {
  // const componentRef = useRef<HTMLDivElement>(null);

  // const handleDownloadPDF = () => {
  //   const element = componentRef.current;

  //   if (element) {
  //     html2canvas(element).then((canvas) => {
  //       const imgData = canvas.toDataURL("image/png");
  //       const pdf = new jsPDF("p", "mm", "a4");
  //       const imgWidth = 210;
  //       const pageHeight = 297;
  //       const imgHeight = (canvas.height * imgWidth) / canvas.width;
  //       let heightLeft = imgHeight;
  //       let position = 0;

  //       pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
  //       heightLeft -= pageHeight;

  //       while (heightLeft >= 0) {
  //         position = heightLeft - imgHeight;
  //         pdf.addPage();
  //         pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
  //         heightLeft -= pageHeight;
  //       }

  //       pdf.save("timeline.pdf");
  //     });
  //   }
  // };

  return (
    <div>
      {/* <button onClick={handleDownloadPDF}>Download PDF</button> */}
      <div
      //  ref={componentRef}
      >
        <VerticalTimeline>
          {data.map((item, index) => (
            <VerticalTimelineElement
              key={index}
              className="vertical-timeline-element--work"
              visible={true}
              contentStyle={
                index % 2 === 1
                  ? { background: "rgb(0, 169, 47)", color: "black" }
                  : {
                      background: "#32768B",
                      color: "#000",
                    }
              }
              contentArrowStyle={
                index % 2 === 1
                  ? { borderRight: "7px solid  rgb(0, 169, 47)" }
                  : { borderRight: "7px solid  #32768B" }
              }
              date={data.length-1 === index ? moment(data[index].task_date).format("DD/MM/YYYY") + "- present" : moment(data[index].task_date).format("DD/MM/YYYY") + " " + "-" + " " + moment(data[index+1].task_date).format("DD/MM/YYYY")  }
              iconStyle={
                index % 2 === 1
                  ? { background: "rgb(0, 169, 47)", color: "black" }
                  : {
                      background: "#32768B",
                      color: "#fff",
                    }
              }
              // icon={<WorkIcon />}
            >
              <h3 className="vertical-timeline-element-title font-black text-xl">
                {item.task_name}
              </h3>
              <h4 className="vertical-timeline-element-subtitle  text-lg">
                {item?.document_name}
              </h4>
              {item?.users?.users.map((item: any, index: number) => (
                <h4 key={index} className="vertical-timeline-element-subtitle">
                  {item}
                </h4>
              ))}
              <h4 className="vertical-timeline-element-subtitle">
                {item.task_date}
              </h4>
              <p>{item.summary}</p>
            </VerticalTimelineElement>
          ))}
        </VerticalTimeline>
      </div>
    </div>
  );
};

export default VerticalTimelineComponent;
