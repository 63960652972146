import React, { useState } from "react";
import { IoMdAdd } from "react-icons/io";
import Component from "./component";
import DeviceAdmin from "./deviceAdmin";
import ComponentDeviceRelation from "./componentDeviceRelation";
import CountryComponent from "./countryComponent";
import ManageAttributesComponent from "./manageAttributesComponent";

function SmartsurgAdmin() {

  return (
    <div className="p-8 flex flex-col gap-y-10">
      {/* <div className="bg-[#EEEEEE] p-5 rounded-2xl">
       <DeviceAdmin />
      </div> */}
      <div className="bg-[#EEEEEE] p-5 rounded-2xl">
       <Component />
      </div>
      {/* <div className="bg-[#EEEEEE] p-5 rounded-2xl">
       <CountryComponent />
      </div> */}
      <div className="bg-[#EEEEEE] p-5 rounded-2xl">
       <ComponentDeviceRelation />
      </div>
      <div className="bg-[#EEEEEE] p-5 rounded-2xl">
       <ManageAttributesComponent />
      </div>
    </div>
  );
}

export default SmartsurgAdmin;
