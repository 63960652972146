const base_url = process.env.REACT_APP_BASE_URL;

export async function get_ChatList() {
  const response = await fetch(
    base_url + `/fetch/sessions?userID=${localStorage.getItem("userId")}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  const resultData = await response.json();
  return resultData;
}
export async function get_ChatData(id: any) {
  const response = await fetch(base_url + `/fetch/history?sessionID=${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  const resultData = await response.json();
  return resultData;
}
export async function get_ChatSessionDelete(id: any) {
  const response = await fetch(base_url + `/delete/session?sessionID=${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  const resultData = await response.json();
  return resultData;
}
