import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
  get_Country,
  get_Device_Page_Count,
  get_Device_Page_Wise_Data
} from "../../../services/adminService";
import { IoMdAdd } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import toast from "react-hot-toast";
import Pagination from "../../pagination/pagination";

interface Data {
  name: string;
  description: string;
  id: number;
}
interface TableData {
  name: string;
  id: number;
  updated_by: string;
  description: string;
  meta_data: object;
  country_id: number;
  is_active: boolean;
}

function Searchlist() {
  const navigate = useNavigate();
  const initialValues = {
    device_name: "",
    
    country_id: "",
    submitter: "",
    device_identifier: "",
    intented_use: "",
  };
  const [countryData, setCountryData] = useState<Data[]>([]);
  const [data, setData] = useState<TableData[]>([]);
  const [loader, setLoader] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [submitData, setsubmitData] = useState("");

  useEffect(() => {
    initialload();
  }, []);

  const initialload = () => {
    get_Country()
      .then((res) => setCountryData(res?.data))
      .catch((err) => console.log(err));
  };

  const onSubmit = async (values: any, { resetForm }: any) => {
    const payload =
      (values?.device_name !== "" ? `name=${values.device_name}&` : "") +
      (values?.device_identifier !== ""
        ? `device_identifier=${values.device_identifier}&`
        : "") +
      (values?.submitter !== "" ? `submitter=${values.submitter}&` : "") +
      (values?.country_id !== "" ? `country_id=${values.country_id}&` : "") +
      (values?.intented_use !== ""
        ? `intended_use=${values.intented_use}&`
        : "");
    setsubmitData(payload);
    fetchDevicePageCount(perPage, payload);
  };

  const onNext = () => {
    if (pageNo >= totalPages) {
      setPageNo(pageNo + 1);
      fetchDeviceData(pageNo + 1, perPage, submitData);
    }
  };
  const onPrevious = () => {
    if (pageNo > 1) {
      setPageNo(pageNo - 1);
      fetchDeviceData(pageNo - 1, perPage, submitData);
    }
  };

  const handleSetPage = (page: number) => {
    setPageNo(page);
    fetchDeviceData(page, perPage, submitData);
  };

  const handlePerPage = (value: any) => {
    setPerPage(parseInt(value));
    fetchDevicePageCount(parseInt(value), submitData);
  };
  const fetchDevicePageCount = (perPageValue: number, data: any) => {
    setLoader(true);
    const payload = `page_size=${perPageValue + "&" + data}`;
    get_Device_Page_Count(payload)
      .then((res) => {
        if (res.success === true) {
          setTotalPages(res.data.page_count);
          setPageNo(1);
          fetchDeviceData(pageNo, perPageValue, data);
          setLoader(false);
        }
      })
      .catch((err) => console.log(err));
  };
  const fetchDeviceData = (
    pageNoValue: number,
    perPageValue: number,
    data: any
  ) => {
    setLoader(true);
    const payload = `page=${pageNoValue - 1}&page_size=${perPageValue}&${data}`;
    get_Device_Page_Wise_Data(payload)
      .then((res) => {
        setData(res?.data);
        setLoader(false);
      })

      .catch((err) => console.log(err));
  };
  const handleRowClick = (data: any) => {
    if (data.is_active) {
      navigate(`/smartsurgn/analysis?tab=1&deviceId=${data.id}`);
    } else {
      notifyFailure("Please link guidelines to activate device");
    }
  };

  const notifyFailure = (value: string) => {
    toast.error(value.toUpperCase());
    // simulate authentication failure
  };
  return (
    <div className="container-screen">
      <div className="p-[18px] bg-[#F9F7F7] rounded-xl h-full ">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {(formik) => (
            <Form className="grid grid-cols-4 gap-10 h-full">
              <Field
                type="text"
                name="device_name"
                placeholder="Device Name"
                className="inputField w-full"
              />
              <Field
                type="text"
                name="device_identifier"
                placeholder="Device Identifier"
                className="inputField w-full"
              />
              <Field
                type="text"
                name="submitter"
                placeholder="Submitter"
                className="inputField w-full"
              />
              <Field
                type="text"
                name="intented_use"
                placeholder="Intented Use"
                className="inputField w-full"
              />
              <div className={` my-auto col-span-3 w-full`}>
                <div className="flex flex-wrap gap-10 mb-7 h-full my-auto w-full ">
                  {countryData?.map((country) => (
                    <label key={country.id} className="flex my-auto">
                      <Field
                        type="radio"
                        name="country_id"
                        className="form-radio radio my-auto"
                        value={country.id}
                        onChange={(e: any) => {
                          formik.setFieldValue("country_id", e.target.value);
                        }}
                        checked={
                          parseInt(formik?.values?.country_id) == country.id
                        }
                      />

                      <span className="my-auto uppercase">{country.name}</span>
                    </label>
                  ))}
                </div>
              </div>
              <button
                type="submit"
                className=" rounded-2xl bg-white px-[7px] py-[5px] flex mb-4 mt-auto w-fit"
              >
                <span className="flex gap-2.5 whitespace-nowrap">
                  <IoMdAdd className="h-6 w-6 bg-primary text-white p-[5px] rounded-full" />
                  Find
                </span>
              </button>
            </Form>
          )}
        </Formik>
      </div>
      {data?.length > 0 && (
        <div>
          <div className="flex justify-between">
            <label className="main-title ">Devices</label>
          </div>

          <table className="w-full my-6 text-sm">
            <thead className="border-b-2 border-b-[#E1DFDF]">
              <tr>
                <th className="pb-2.5 text-start">Name</th>
                <th className="pb-2.5 text-center">Country Name</th>
                <th className="pb-2.5 text-center">Updated By</th>
                <th className="pb-2.5 text-center">Active</th>
                <th className="pb-2.5 text-end">Description</th>
                {/* <th className="pb-2.5 text-start">Actions</th> */}
              </tr>
            </thead>
            <tbody>
              {data?.map((data, index) => (
                <tr
                  key={index}
                  className="hover:font-bold hover:text-blue-800 cursor-pointer"
                  onClick={() => handleRowClick(data)}
                >
                  <td className="pb-2.5 text-start">{data?.name}</td>
                  <td className="pb-2.5 text-center">
                    {
                      countryData.filter(
                        (item: any) => item.id === data.country_id
                      )[0].name
                    }
                  </td>
                  <td className="pb-2.5 text-center">{data?.updated_by}</td>
                  <td className="py-2.5 text-center">
                    {data.is_active ? "Yes" : "No"}
                  </td>
                  <td className="pb-2.5 text-end">{data?.description}</td>
                </tr>
              ))}
              <tr>
                <td colSpan={5}>
                  <Pagination
                    currentPage={pageNo}
                    onNext={onNext}
                    onPrevious={onPrevious}
                    from={pageNo * perPage - perPage}
                    to={pageNo * perPage}
                    totalItems={totalPages * perPage}
                    perPage={perPage}
                    totalPages={totalPages}
                    setPage={handleSetPage}
                    setperPage={handlePerPage}
                  />
                </td>{" "}
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default Searchlist;
