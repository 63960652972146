import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ChatSection from "./ChatSection";
import { useLocation, useSearchParams } from "react-router-dom";
import ModalComponent from "../fieldComponent/modalComponent";
import { IoMdAdd } from "react-icons/io";
import openenterprise from "../assets/images/logo 1 copy.png";

interface ChatSheetProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

function ChatSheet({ open, setOpen }: ChatSheetProps) {
  let location = useLocation();

  const [showModal, setShowModal] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();

  const confirmModalHandler = () => {
    setShowModal(false);
    setOpen(false);
    searchParams.delete("chat");
    searchParams.delete("chatBar");
    setSearchParams(searchParams);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[9999] "
        onClose={() => setOpen(true)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <ModalComponent
                showModal={showModal}
                closeModal={closeModal}
                header="Closing Chat"
                mainBody={ModalBody(confirmModalHandler, closeModal)}
              />
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-screen max-w-5xl">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="rounded-md text-gray-300 hover:text-white focus:outline-none bg-red-500 h-7 w-7 hover:bg-red-700"
                        onClick={() => setShowModal(true)}
                      >
                        <span className="sr-only">Close panel</span>X
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex h-full flex-col overflow-y-scroll bg-white  shadow-xl">
                    <div className="px-2 sm:px-3 sticky top-0 bg-gray-200 p-4 z-10 border-b-2">
                      <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                        {/* {process.env.REACT_APP_TYPE === "smsn"
                          ? "SmartSurgN Coach"
                          : "OpenEnterprise Coach"} */}
                        <div className="flex items-center text-black gap-1 text-lg">
                          <img
                            src={openenterprise}
                            alt=""
                            className="w-7 ml-2 h-7"
                          />{" "}
                          Sherpa
                        </div>
                      </Dialog.Title>
                    </div>
                    <div className="relative flex-1">
                      <ChatSection />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default ChatSheet;

const ModalBody = (confirmModalHandler: any, closeModalHandler: any) => {
  return (
    <div className="p-6 space-y-[30px] bg-[#EEEEEE] rounded-2xl">
      <div>Are you sure you want to close this chat?</div>
      <h2 className="flex justify-between   rounded-md mt-10 w-full">
        <div className="my-auto">
          <p className="  text-[14px] text-black my-auto "></p>
        </div>
        <div className=" flex gap-3 ">
          <button
            type="button"
            onClick={() => closeModalHandler()}
            className="bg-secondary px-4 py-[5px] rounded-full self-center "
          >
            No
          </button>
          <button
            type="submit"
            onClick={() => confirmModalHandler()}
            className="rounded-2xl text-white px-4 py-[5px] flex bg-primary"
          >
            <span className="flex gap-2.5 whitespace-nowrap">YES</span>
          </button>
        </div>
      </h2>
    </div>
  );
};
