import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { IoMdAdd } from "react-icons/io";
import * as yup from "yup";
import AddDeviceForm from "./addDeviceForm";
import UploadFileDevice from "./uploadFileDevice";
import {
  get_device_by_Id,
  upsert_device,
} from "../../../services/adminService";
import toast from "react-hot-toast";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ModalComponent from "../../../fieldComponent/modalComponent";
import AddDocumentModalBody from "./addDocumentModalBody";

interface DeviceData {
  id: number;
  name: string;
  country_id: number;
  description: string | null;
  meta_data: {
    CompanyCode: string;
    DeviceIdentifier: string;
    Submitter: string;
    primaryCorrespondent: string;
    productClass: string;
    Panel: string;
    IntentedUse: string;
    Certifications: string;
    class: string;
    // Add other properties of meta_data if needed
  };
}

function AddDeviceComponent() {
  const navigate = useNavigate();
  const location = useLocation()
  let [searchParams, setSearchParams] = useSearchParams();

  const [editData, seteditData] = useState<DeviceData | null>(null);
  const [edit, setEdit] = useState<boolean>(false);
  const [navigateFlag, setNavigateFlag] = useState(false);
  const [countryId, setCountryId] = useState(null);


  useEffect(() => {
    if (
      searchParams.get("edit") !== null &&
      searchParams.get("edit") === "true"
    ) {
      setEdit(true)
      handlegetDataEdit();
    }
  }, [searchParams]);

  const handlegetDataEdit = async () => {
    const data = await get_device_by_Id(searchParams.get("deviceId"));
    if (data.success === true) {
      seteditData(data?.data?.entity_info[0]);
    }
  };

  const initialValues = {
    name: editData?.name || "",
    country_id: editData?.country_id || "",
    meta_data: {
      CompanyCode: editData?.meta_data?.CompanyCode || "",
      DeviceIdentifier: editData?.meta_data?.DeviceIdentifier || "",
      Submitter: editData?.meta_data?.Submitter || "",
      primaryCorrespondent: editData?.meta_data?.primaryCorrespondent || "",
      productClass: editData?.meta_data?.productClass || "",
      Panel: editData?.meta_data?.Panel || "",
      IntentedUse: editData?.meta_data?.IntentedUse || "",
      Certifications: editData?.meta_data?.Certifications || "",
      class: editData?.meta_data?.class || "",
    },
  };
  const validationSchema = yup.object().shape({});
  const onSubmit = async (values: any, { resetForm }: any) => {
    const formdata = new FormData()
      formdata.append("name", values.name)
      formdata.append("country_id", values.country_id)
      formdata.append("meta_data", values.meta_data !== null ? JSON.stringify(values.meta_data) : "")
      upsert_device(formdata).then((res) => {
      if (res?.success === true) {
        notify("Device Created Successfully");
        searchParams.set("edit", "true")
        searchParams.set("deviceId", res?.data)
        setSearchParams(searchParams)
        setCountryId(values.country_id)
        // navigate(
        //   `/smartsurgn/devices/addDevice/selectGuidelines/${res?.data.success[`${values.name}`].id}&${values.country_id}`
        // );
        setNavigateFlag(true)
        resetForm();
      } else if (res?.success === false) {
        notify("Some error occurs");
      }
    });
  };
  useEffect(() => {
    if (searchParams.get("deviceId") !== null && navigateFlag === true && location.pathname + location.search === `/smartsurgn/devices/addDevice?edit=true&deviceId=${searchParams.get("deviceId")}`) {
      navigate(`/smartsurgn/devices/addDevice/selectGuidelines/${searchParams.get("deviceId")}&${countryId}`);
      setNavigateFlag(false);
    }
  }, [searchParams, navigateFlag]);
  const onUpdate = async (values: any, { resetForm }: any) => {
    const formdata = new FormData()
    formdata.append("id", searchParams.get("deviceId") ?? "")
      formdata.append("name", values.name)
      formdata.append("country_id", values.country_id.toString())
      formdata.append("meta_data", values.meta_data !== null ? JSON.stringify(values.meta_data) : "")
      upsert_device(formdata).then((res) => {
      if (res?.success === true) {
        notify("Device Update Successfully");
        navigate(
          `/smartsurgn/devices/addDevice/selectGuidelines/${searchParams.get("deviceId")
          }&${values.country_id}?edit=true`
        );
        resetForm();
      } else if (res?.success === false) {
        notify("Some error occurs");
      }
    });
  };

  useEffect(() => {
    localStorage.removeItem("route");
  }, []);

  const notify = (value: any) => {
    toast.success(value);
    // simulate authentication success
  };
  const notifyFailure = (value: any) => {
    toast.error(value);
    // simulate authentication failure
  };
  return (
    <div className="p-8 flex flex-col gap-y-10">
      <div className="p-[18px] bg-[#F8F6F6] rounded-xl">
        <div className="flex justify-between">
          <label className="main-title font-bold">Add a Device</label>
        </div>
        <div className="p-[18px] bg-[#F4F0F0] mt-2.5 rounded-xl">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={edit ? onUpdate : onSubmit}
            enableReinitialize
          >
            {(formik) => (
              <Form>
                <AddDeviceForm formik={formik} />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default AddDeviceComponent;
