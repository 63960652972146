import React, { useEffect, useState } from "react";
import {
  get_Guideline_Page_Wise_Data,
  get_Guidelines,
  get_device_by_Id,
  insert_Add_Device_Guidelines,
  update_Add_Device_Guidelines,
} from "../../../services/adminService";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
import { Field, Form, Formik } from "formik";
import { IoMdAdd } from "react-icons/io";
import toast from "react-hot-toast";
import { FaArrowLeft } from "react-icons/fa";

interface Data {
  name: string;
  id: number;
}
interface DeviceData {
  id: number;
  device_id: string;
  guideline_id: number;
}

function SelectGuidelines() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [guidelineData, setGuidelineData] = useState<Data[]>([]);
  const [selectedGuidelines, setSelectedGuidelines] = useState<number[]>([]);
  let [searchParams, setSearchParams] = useSearchParams();

  const [editData, seteditData] = useState<DeviceData[]>([]);
  const [edit, setEdit] = useState<boolean>(false);

  useEffect(() => {
    console.log("edit", searchParams.get("edit"))

    if (
      searchParams.get("edit") !== null &&
      searchParams.get("edit") === "true" && id !== null
    ) {
      setEdit(true);
      handlegetDataEdit();
    }
  }, [searchParams, id]);

  const handlegetDataEdit = async () => {
    const getData = await get_device_by_Id(id?.split("&")[0]);
    if (getData.success === true) {
      const data = getData?.data?.guideline_device_relationship
      const guidelineIds = data.map((item: any) => item?.guideline_id);
      seteditData(data);

      setSelectedGuidelines(guidelineIds);
    }
  };

  useEffect(() => {
    fetchDevice(id?.split("&")[1]);
  }, [id]);

  const fetchDevice = (countryId: any) => {
   
    get_Guideline_Page_Wise_Data(`country_id=${countryId}`)
      .then((res) => setGuidelineData(res?.data || []))
      .catch((err) => console.log(err));
  };

  const notify = (value: any) => {
    toast.success(value);
  };

  const initialValues = {
    deviceId: id?.split("&")[0] || "",
    guideline_ids: editData?.map((item) => item?.guideline_id) || [], // Return guideline_id here
  };

  const toggleGuideline = (id: number) => {
    if (selectedGuidelines.includes(id)) {
      setSelectedGuidelines(selectedGuidelines.filter((gid) => gid !== id));
    } else {
      setSelectedGuidelines([...selectedGuidelines, id]);
    }
  };

  const onSubmit = async (values: any, { resetForm }: any) => {
    const payload = {
      device_id: id?.split("&")[0] || "",
      guideline_ids: selectedGuidelines,
    };
    insert_Add_Device_Guidelines(payload).then((res) => {
      if (res?.success === true) {
        notify("Guideline Added Successfully");
        navigate(
          `/smartsurgn/devices/addDevice/uploadDocument/${id?.split("&")[0]}`
        );
      } else if (res?.success === false) {
        notify("Some error occurs");
      }
    });
  };
  const onUpdate = async (values: any, { resetForm }: any) => {
    const payload = {
      device_id: id?.split("&")[0] || "",
      guideline_ids: selectedGuidelines,
    };
    update_Add_Device_Guidelines(payload).then((res) => {
      if (res?.success === true) {
        notify("Guideline Update Successfully");
        navigate(
          `/smartsurgn/devices/addDevice/uploadDocument/${id?.split("&")[0]}?edit=true`
        );
      } else if (res?.success === false) {
        notify("Some error occurs");
      }
    });
  };

  return (
    <div className="p-8 flex flex-col gap-y-10">
      <div className="p-[18px] bg-[#F8F6F6] rounded-xl">
        <div className="flex justify-between">
          <label className="main-title font-bold">Select Guidelines</label>
        </div>
        <div className="bg-[#F8F5F5] p-4 rounded-xl mt-4">
          <Formik
            initialValues={initialValues}
            onSubmit={edit ? onUpdate : onSubmit}
            enableReinitialize
          >
            {(formik) => (
              <Form>
                <div className="grid grid-cols-2 w-full gap-4">
                  {guidelineData.map((checkbox: Data, index: number) => (
                    <div key={checkbox.id}>
                      <label
                        htmlFor={checkbox.name}
                        className="text-sm gap-3 w-fit"
                        style={{ display: "flex", alignItems: "center" }}
                        onClick={() => toggleGuideline(checkbox.id)}
                      >
                        <div className="w-6">
                          {selectedGuidelines.includes(checkbox.id) ? (
                            <MdCheckBox className="text-[#17C05B] text-2xl w-5" />
                          ) : (
                            <MdCheckBoxOutlineBlank className=" text-2xl w-5" />
                          )}
                        </div>
                        <span className="my-auto break-words">
                          {checkbox.name}
                        </span>
                      </label>
                    </div>
                  ))}
                </div>
                <div className="h-full flex gap-10 mt-10">
                  <button
                    type="submit"
                    className=" rounded-2xl bg-white px-[7px] py-[5px] flex mb-4 mt-auto"
                  >
                    <span className="flex gap-2.5 whitespace-nowrap">
                      <IoMdAdd className="h-6 w-6 bg-primary text-white p-[5px] rounded-full" />
                      {edit ? "Update and Next" : "Save and Next"}
                    </span>
                  </button>
                  <button
                    type="button"
                    className="rounded-2xl bg-white px-[7px] py-[5px] flex mb-4 mt-auto"
                    onClick={() => navigate(-1)}
                  >
                    <span className="flex gap-2.5 whitespace-nowrap">
                      <FaArrowLeft className="h-6 w-6 bg-secondary text-white p-[5px] rounded-full" />
                      Previous Step
                    </span>
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default SelectGuidelines;
