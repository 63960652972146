import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {useEffect} from "react"

export const ProtectedRoute = () => {
  const navigate = useNavigate();
  const location = useLocation()
  useEffect(() => {

    if (!isAuthenticated()) {
      localStorage.setItem("route", location.pathname)
      
      navigate("/login");
    }
  }, [navigate]);

  return (
    <>
    <Outlet />
    </>
  );
};
function isAuthenticated() {
  // check if the user is authenticated
  return localStorage.getItem("token") !== null;
}
