import React, { useState, useEffect } from "react";
import { IoMdAdd } from "react-icons/io";

import GuidelinesModalBody from "./guidelinesModalBody";
import toast from "react-hot-toast";
import { GrView } from "react-icons/gr";
import {
  delete_Guideline,
  get_Country,
  get_GuidelinePdf,
  get_Guideline_Page_Count,
  get_Guideline_Page_Wise_Data,
  get_Guidelines,
  get_GuidelinesType,
  insert_Guidelines,
} from "../../../services/adminService";
import ModalComponent from "../../../fieldComponent/modalComponent";
import { BsCheckCircle } from "react-icons/bs";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { GoTrash } from "react-icons/go";
import { MdModeEdit } from "react-icons/md";
import Pagination from "../../pagination/pagination";
import { Backdrop, CircularProgress } from "@mui/material";

interface Data {
  name: string;
  description: string;
  id: number;
  is_processed: boolean;
  guideline_type_id: string;
  country_id: string;
  is_guideline_linked: boolean;
}

interface CountryData {
  name: string;
  id: number;
}

function Guidelines() {
  const [showDeviceModal, setshowDeviceModal] = useState(false);
  const [showEditDeviceModal, setshowEditDeviceModal] = useState(false);
  const [filterCountryId, setfilterCountryId] = useState(null);
  const [data, setData] = useState<Data[]>([]);
  const [countryData, setCountryData] = useState<CountryData[]>([]);
  const [guidelineData, setGuidelineData] = useState<CountryData[]>([]);
  const [deleteValue, setDeleteValue] = useState<Data>({
    name: "",
    description: "",
    id: 0,
    is_processed: false,
    guideline_type_id: "",
    country_id: "",
    is_guideline_linked: false,
  });
  const [showDeleteModal, setshowDeleteModal] = useState<boolean>(false);
  const [editData, setEditData] = useState(null);
  const [pageNo, setPageNo] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    fetchGuidelinesPageCount(perPage, null);
    fetchCountry();
    fetchGuidelines();
  }, []);

  const fetchCountry = () => {
    get_Country()
      .then((res) => setCountryData(res?.data))
      .catch((err) => console.log(err));
  };
  const fetchGuidelines = () => {
    get_GuidelinesType()
      .then((res) => setGuidelineData(res?.data))
      .catch((err) => console.log(err));
  };

  const fetchGuidelinesPageCount = (perPageValue: number, countryid: any) => {
    setLoader(true);
    const payload = `page_size=${perPageValue}${
      countryid !== null ? `&country_id=${countryid}` : ""
    }`;
    get_Guideline_Page_Count(payload)
      .then((res: any) => {
        if (res.success === true) {
          setTotalPages(res.data.page_count);
          setPageNo(1);
          fetchGuidelinesData(pageNo, perPageValue, countryid);
          setLoader(false);
        }
      })
      .catch((err: any) => console.log(err));
  };
  const fetchGuidelinesData = (
    pageNoValue: number,
    perPageValue: number,
    countryId: any
  ) => {
    setLoader(true);
    const payload = `page=${pageNoValue - 1}&page_size=${perPageValue}${
      countryId !== null ? `&country_id=${countryId}` : ""
    }`;
    get_Guideline_Page_Wise_Data(payload)
      .then((res) => {
        setData(res?.data);
        setLoader(false);
      })

      .catch((err) => console.log(err));
  };

  const countryHandler = async (e: any) => {
    if (e.target.value === "") {
      await setfilterCountryId(null);
      await fetchGuidelinesPageCount(perPage, null);
    } else {
      await setfilterCountryId(e.target.value);
      await fetchGuidelinesPageCount(perPage, e.target.value);
    }
  };
  const closeModalHandler = () => {
    setshowDeviceModal(false);
  };
  const confirmModalHandler = async (formData: any) => {
    const result = await insert_Guidelines(formData);

    if (result?.success === true) {
      setshowDeviceModal(false);
      notify("Guidelines Added Successfully!");
      fetchGuidelinesPageCount(perPage, filterCountryId);
    }
  };
  const closeEditModalHandler = () => {
    setshowEditDeviceModal(false);
    setEditData(null);
  };
  const confirmEditModalHandler = async (formData: any) => {
    const result = await insert_Guidelines(formData);
    if (result?.success === true) {
      setshowEditDeviceModal(false);
      setEditData(null);
      notify("Guidelines Edit Successfully!");
      fetchGuidelinesPageCount(perPage, filterCountryId);
    }
  };

  const handleViewClick = async (id: any) => {
    try {
      // Make the API call to fetch base64-encoded PDF data
      const response = await get_GuidelinePdf(id); // Replace with your actual API call
      const base64Data = response.data.file_content;

      // Create a Blob from base64 data
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" });

      // Create a data URL
      const dataUrl = URL.createObjectURL(blob);

      // Open a new tab with the data URL
      window.open(dataUrl, "_blank");
    } catch (error) {
      console.error("Error fetching or displaying PDF:");
      // Handle error, show a message to the user, etc.
    }
  };

  const handleDelete = (value: any) => {
    setDeleteValue(value);
    setshowDeleteModal(true);
  };
  const confirmDeleteModalHandler = async () => {
    if (deleteValue?.id !== 0) {
      setLoader(true);
      const response = await delete_Guideline(deleteValue?.id);
      if (response) {
        setLoader(false);
        notify("Successfully Guideline Deleted");
        fetchGuidelinesPageCount(perPage, null);
        fetchCountry();
        setDeleteValue({
          name: "",
          description: "",
          id: 0,
          is_processed: false,
          guideline_type_id: "",
          country_id: "",
          is_guideline_linked: false,
        });
        setshowDeleteModal(false);
      }
    }
  };
  const closeDeleteModalHandler = () => {
    setshowDeleteModal(false);
    setDeleteValue({
      name: "",
      description: "",
      id: 0,
      is_processed: false,
      guideline_type_id: "",
      country_id: "",
      is_guideline_linked: false,
    });
  };

  const onNext = () => {
    if (pageNo <= totalPages) {
      setPageNo(pageNo + 1);
      fetchGuidelinesData(pageNo + 1, perPage, filterCountryId);
    }
  };
  const onPrevious = () => {
    if (pageNo > 1) {
      setPageNo(pageNo - 1);
      fetchGuidelinesData(pageNo - 1, perPage, filterCountryId);
    }
  };

  const handleSetPage = (page: number) => {
    setPageNo(page);
    fetchGuidelinesData(page, perPage, filterCountryId);
  };

  const handlePerPage = (value: any) => {
    setPerPage(parseInt(value));
    fetchGuidelinesPageCount(parseInt(value), filterCountryId);
  };

  const notify = (value: any) => {
    toast.success(value);
    // simulate authentication success
  };
  const notifyFailure = (value: any) => {
    toast.error(value);
    // simulate authentication failure
  };

  const handleEdit = (data: any) => {
    setEditData(data);
    setshowEditDeviceModal(true);
  };

  return (
    <div className="bg-[#EEEEEE] p-5 rounded-2xl">
      <div className="mb-10 flex justify-between">
        <ModalComponent
          showModal={showDeleteModal}
          closeModal={() => setshowDeleteModal(false)}
          header="Confirmation Modal"
          mainBody={ModalBody(
            confirmDeleteModalHandler,
            closeDeleteModalHandler,
            deleteValue
          )}
        />
        <label className="text-[#060606] font-bold text-xl">Guidelines</label>
        <div className="flex gap-5">
          <select
            className="inputField px-10"
            name="country_id"
            placeholder="Country"
            value={filterCountryId || ""}
            onChange={countryHandler}
          >
            <option value="">Country</option>
            {countryData?.map((item, index) => (
              <option key={index} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>
          <button
            type="button"
            onClick={() => setshowDeviceModal(true)}
            className="rounded-2xl bg-white px-[7px] py-[5px] flex"
          >
            <span className="flex gap-2.5 whitespace-nowrap m-auto">
              <IoMdAdd className="h-6 w-6 bg-primary text-white p-[5px] rounded-full" />
              Add Guidelines
            </span>
          </button>
        </div>
      </div>
      <ModalComponent
        showModal={showDeviceModal}
        closeModal={closeModalHandler}
        header="Add Guidelines"
        mainBody={GuidelinesModalBody(
          confirmModalHandler,
          closeModalHandler,
          null
        )}
      />
      <ModalComponent
        showModal={showEditDeviceModal}
        closeModal={closeEditModalHandler}
        header="Edit Guidelines"
        mainBody={GuidelinesModalBody(
          confirmEditModalHandler,
          closeEditModalHandler,
          editData
        )}
      />
      <div>
        <table className="w-full my-6 text-sm">
          <thead className="border-b-2 border-b-[#E1DFDF]">
            <tr>
              <th className="pb-2.5 text-start">Name</th>
              {/* <th className="pb-2.5 text-center">Description</th> */}
              <th className="pb-2.5 text-center">Guideline Type</th>
              <th className="pb-2.5 text-center">Country</th>
              <th className="pb-2.5 text-center">Processed</th>
              <th className="pb-2.5 text-end">Action</th>
            </tr>
          </thead>
          <tbody className="">
            {data?.length > 0 &&
              data?.map((item, index) => (
                <React.Fragment key={index}>
                  <tr
                    key={item.id}
                    className="hover:font-bold hover:text-blue-800 cursor-pointer"
                  >
                    <td className="py-5 text-start">{item.name}</td>
                    {/* <td className="py-5 text-center w-1/2">
                      {item.description}
                    </td> */}
                    <td className="py-5 text-center">
                      {
                        guidelineData?.filter(
                          (guideline: any) =>
                            guideline.id === item.guideline_type_id
                        )[0]?.name
                      }
                    </td>
                    <td className="py-5 text-center">
                      {
                        countryData.filter(
                          (country: any) => country.id === item.country_id
                        )[0].name
                      }
                    </td>
                    <td className="py-5 text-center">
                      {item.is_processed ? (
                        <BsCheckCircle className="text-xl text-[#1A932E] m-auto ring-4 ring-[#1A932E] rounded-full ring-offset-2" />
                      ) : (
                        <AiOutlineCloseCircle className="text-2xl text-[#EE201C] m-auto ring-4 ring-[#EE201C] rounded-full " />
                      )}
                    </td>
                    <td className="py-5 text-end flex gap-4 justify-around">
                      <GrView
                        className="ml-auto text-xl"
                        onClick={() => handleViewClick(item.id)}
                      />
                      <MdModeEdit
                        className="text-lg cursor-pointer "
                        onClick={() => handleEdit(item)}
                      />
                      <GoTrash
                        title="Delete Guideline"
                        className="text-xl  hover:font-bold hover:text-red-900 text-secondary text-red-500"
                        onClick={() => handleDelete(item)}
                      />
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            <tr>
              <td colSpan={6}>
                <Pagination
                  currentPage={pageNo}
                  onNext={onNext}
                  onPrevious={onPrevious}
                  from={pageNo * perPage - perPage}
                  to={pageNo * perPage}
                  totalItems={totalPages * perPage}
                  perPage={perPage}
                  totalPages={totalPages}
                  setPage={handleSetPage}
                  setperPage={handlePerPage}
                />
              </td>{" "}
            </tr>
          </tbody>
        </table>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
        // onClick={handleClose}
      >
        <CircularProgress style={{ color: "#083558", zIndex: 1 }} />
      </Backdrop>
    </div>
  );
}

export default Guidelines;

const ModalBody = (
  confirmDeleteModalHandler: any,
  closeDeleteModalHandler: any,
  deleteValue: Data
) => {
  const [data, setdata] = useState();
  useEffect(() => {
    if(deleteValue.id !== 0){

      loadData();
    }
  }, [deleteValue]);

  const loadData = async () => {
    const getdata = await get_GuidelinePdf(deleteValue.id);
    console.log(getdata);
    setdata(getdata.data);
  };

  return (
    <div className="p-6 space-y-[30px] bg-[#EEEEEE] rounded-2xl">
      <div>
        Are you sure you want to delete this Guideline
        {deleteValue.is_guideline_linked && ". This Guideline is Linked"}?
      </div>
      <h2 className="flex justify-between   rounded-md mt-10 w-full">
        <div className="my-auto">
          <p className="  text-[14px] text-black my-auto "></p>
        </div>
        <div className=" flex gap-3 ">
          <button
            type="button"
            onClick={() => closeDeleteModalHandler()}
            className="rounded-2xl bg-secondary px-3 py-[5px] flex text-white "
          >
            No
          </button>
          <button
            type="button"
            className="rounded-2xl bg-white px-3 py-[5px] flex"
            onClick={() => confirmDeleteModalHandler()}
          >
            <span className="flex gap-2.5 whitespace-nowrap">
              <IoMdAdd className="h-6 w-6 bg-primary text-white p-[5px] rounded-full" />
              YES
            </span>
          </button>
        </div>
      </h2>
    </div>
  );
};
