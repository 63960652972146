import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import toast from "react-hot-toast";
import { IoMdAdd } from "react-icons/io";
import { MdDeleteForever, MdModeEdit, MdOutlineAddBox } from "react-icons/md";
import {
  delete_Attribute_Dropdown,
  delete_Value_Dropdown,
  get_Attribute_Dropdown,
  get_Value_Dropdown,
  insert_Attribute_Dropdown,
  insert_Value_Dropdown,
} from "../../services/adminService";
import ModalComponent from "../../fieldComponent/modalComponent";
import { IoCloseCircleOutline } from "react-icons/io5";

interface Data {
  name: string;
  description: string;
  id: number;
}
interface Value {
  value: string;
  id: number;
}

function ManageAttributesComponent() {
  const [attributeData, setAttributeData] = useState<Data[]>([]);
  const [valueData, setValueData] = useState<Value[]>([]);
  const [attributeToggle, setAttributeToggle] = useState(false);
  const [valueToggle, setValueToggle] = useState(false);
  const [showModal, setshowModal] = useState(false);
  const [showAttributeModal, setshowAttributeModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteAttributeId, setDeleteAttributeId] = useState(null);

  useEffect(() => {
    initialLoadAttribute();
  }, []);

  const initialLoadAttribute = () => {
    get_Attribute_Dropdown()
      .then((res) => setAttributeData(res?.data))
      .catch((err) => console.log(err));
  };
  const initialLoadValue = (value: any) => {
    console.log("object");
    get_Value_Dropdown(value)
      .then((res) => setValueData(res?.data))
      .catch((err) => console.log(err));
  };

  const initialValues = {
    attribute: "",
    attributeInput: "",
    value: "",
    valueInput: "",
  };
  const AddAttributeHandler = (formik: any) => {
    const value = {
      name: formik.values.attributeInput,
    };
    insert_Attribute_Dropdown(value)
      .then((res) => {
        initialLoadAttribute();
        setAttributeToggle(false);
        notify(res?.data);
      })
      .catch((err) => console.log(err));
  };
  const AddValueHandler = (formik: any) => {
    const value = {
      value: formik.values.valueInput,
      dropdown_id: formik.values.attribute,
    };
    if (value.value !== "") {
      insert_Value_Dropdown(value)
        .then((res) => {
          console.log(formik.values.attribute);
          initialLoadValue(formik.values.attribute);
          setValueToggle(false);
          formik.setFieldValue("valueInput", "");
          notify(res?.data);
        })
        .catch((err) => console.log(err));
    } else {
      formik.setFieldError("valueInput", "please enter the value");
    }
  };

  const notify = (value: any) => {
    toast.success(value);
    // simulate authentication success
  };
  const notifyFailure = (value: any) => {
    toast.error(value);
    // simulate authentication failure
  };

  const handleAttributeChange = (formik: any, e: any) => {
    formik.setFieldValue("attribute", e.target.value);
    initialLoadValue(e.target.value);
  };

  const deletehandler = (id: any) => {
    setshowModal(true);
    setDeleteId(id);
  };
  const deleteAttributehandler = (id: any) => {
    console.log(id)
    setshowAttributeModal(true);
    setDeleteAttributeId(id);
  };
  const confirmModalHandler = (formik: any) => {
    delete_Value_Dropdown(deleteId)
      .then((res) => {
        initialLoadValue(formik.values.attribute);
        setshowModal(false);

        notify(res?.data);
      })
      .catch((err) => console.log(err));
  };
  const closeModalHandler = () => {
    setshowModal(false);
  };
  const confirmModalAttributeHandler = (formik: any) => {
    delete_Attribute_Dropdown(deleteAttributeId)
      .then((res) => {
        initialLoadAttribute();
        setshowAttributeModal(false);

        notify(res?.data);
      })
      .catch((err) => console.log(err));
  };
  const closeModalAttributeHandler = () => {
    setshowAttributeModal(false);
  };
  const onSubmit = (value: any) => {};

  return (
    <div>
      <div className="mb-10 flex justify-between">
        <label className="text-[#060606] font-bold text-xl">Manage Attributes</label>
      </div>
      <div>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {(formik) => (
            <Form>
              <div className="flex justify-between gap-10 mb-10">
                <ModalComponent
                  showModal={showModal}
                  closeModal={() => setshowModal(false)}
                  header="Confirmation Modal"
                  mainBody={ModalBody(
                    confirmModalHandler,
                    closeModalHandler,
                    formik
                  )}
                />
                <ModalComponent
                  showModal={showAttributeModal}
                  closeModal={() => setshowAttributeModal(false)}
                  header="Confirmation Modal"
                  mainBody={ModalBody(
                    confirmModalAttributeHandler,
                    closeModalAttributeHandler,
                    formik
                  )}
                />
                <div className="flex gap-5 w-full">

                <Field
                  as="select"
                  name="attribute"
                  id="attribute"
                  className="inputField w-1/2"
                  onChange={(e: any) => handleAttributeChange(formik, e)}
                  value={formik.values.attribute}
                  >
                  <option value="">Select Attribute</option>
                  {attributeData?.map((item) => (
                    <option value={item.id}>{item.name}</option>
                    ))}
                </Field>
                {formik.values.attribute != "" &&
                    formik.values.attribute > "0" ? (
                      <button
                        className="flex gap-2 text-red-700 hover:text-red-400 hover:font-bold my-auto"
                        onClick={() =>
                          deleteAttributehandler(formik.values.attribute)
                        }
                      >
                        <MdDeleteForever className="text-2xl my-auto" />
                        Delete
                      </button>
                    ) : null}
                    </div>

                {!attributeToggle ? (
                  <>
                
                    <button
                      type="button"
                      onClick={() => setAttributeToggle(true)}
                      className="rounded-2xl bg-white px-[7px] py-[5px] flex whitespace-nowrap "
                    >
                      <span className="flex gap-2.5  m-auto">
                      
                        <IoMdAdd className="h-6 w-6 bg-primary text-white p-[5px] rounded-full" />
                        Add New Attribute Data
                      </span>
                    </button>
                  </>
                ) : (
                  <>
                    <Field
                      name="attributeInput"
                      className="inputField w-1/2"
                      placeholder="Add Attribute"
                    />
                    <button
                      type="button"
                      onClick={() => setAttributeToggle(false)}
                      className=" flex"
                    >
                      <IoCloseCircleOutline className="h-6 w-6 text-[#E65F2B] rounded-full my-auto" />
                    </button>
                    <button
                      type="button"
                      onClick={() => AddAttributeHandler(formik)}
                      className="rounded-2xl bg-white px-[7px] py-[5px] flex"
                    >
                      <span className="flex gap-2.5 whitespace-nowrap m-auto">
                        <IoMdAdd className="h-6 w-6 bg-primary text-white p-[5px] rounded-full" />
                        Submit New Attribute
                      </span>
                    </button>
                  </>
                )}
              </div>

              {formik.values.attribute != "" &&
              formik.values.attribute > "0" ? (
                <div>
                  {valueToggle ? (
                    <div className="py-5 text-center justify-between flex">
                      <div className="flex gap-5 w-full">

                      <Field
                        name="valueInput"
                        className="inputField w-1/2"
                        placeholder="Add Value"
                        style={
                          formik.errors.valueInput && formik.touched.valueInput
                            ? { borderBottom: "2px solid #E61E50" }
                            : {}
                        }
                      />
                       <button
                      type="button"
                      onClick={() => setValueToggle(false)}
                      className=" flex"
                    >
                      <IoCloseCircleOutline className="h-6 w-6 text-[#E65F2B] rounded-full my-auto" />
                    </button>
                    </div>
                      <button
                        type="button"
                        onClick={() => AddValueHandler(formik)}
                        className="rounded-2xl bg-white px-[7px] py-[5px] flex"
                      >
                        <span className="flex gap-2.5 whitespace-nowrap m-auto">
                          <IoMdAdd className="h-6 w-6 bg-primary text-white p-[5px] rounded-full" />
                          Submit New Value
                        </span>
                      </button>
                    </div>
                  ) : (
                    <button
                      type="button"
                      onClick={() => setValueToggle(true)}
                      className="rounded-2xl bg-white px-[7px] py-[5px] flex"
                    >
                      <span className="flex gap-2.5 whitespace-nowrap m-auto">
                        <IoMdAdd className="h-6 w-6 bg-primary text-white p-[5px] rounded-full" />
                        Add New Value
                      </span>
                    </button>
                  )}

                  <table className="w-full my-6 text-sm">
                    <thead className="border-b-2 border-b-[#E1DFDF]">
                      <tr>
                        <th className="pb-2.5 text-start">Value</th>

                        <th className="pb-2.5 text-end">Action</th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {valueData?.length > 0 &&
                        valueData?.map((item) => (
                          <>
                            <tr key={item.id} className=" cursor-pointer">
                              <td className="py-5 text-start">{item.value}</td>

                              <td className="py-5 text-end flex justify-between w-1/3 ml-auto">
                                <div className="flex gap-2 text-yellow-600 hover:text-yellow-400 hover:font-bold ">
                                  <MdModeEdit className="text-2xl " />
                                  Edit
                                </div>
                                <div
                                  className="flex gap-2 text-red-700 hover:text-red-400 hover:font-bold "
                                  onClick={() => deletehandler(item.id)}
                                >
                                  <MdDeleteForever className="text-2xl " />
                                  Delete
                                </div>
                              </td>
                            </tr>
                          </>
                        ))}
                    </tbody>
                  </table>
                </div>
              ) : null}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default ManageAttributesComponent;

const ModalBody = (
  confirmModalHandler: any,
  closeModalHandler: any,
  formik: any
) => {
  return (
    <div className="p-6 space-y-[30px] bg-[#EEEEEE] rounded-2xl">
      <div>Are you sure you want to delete this value?</div>
      <h2 className="flex justify-between   rounded-md mt-10 w-full">
        <div className="my-auto">
          <p className="  text-[14px] text-black my-auto "></p>
        </div>
        <div className=" flex gap-3 ">
          <button
            type="button"
            onClick={() => closeModalHandler()}
            className="rounded-2xl bg-secondary px-3 py-[5px] flex text-white "
          >
            No
          </button>
          <button
            type="submit"
            onClick={() => confirmModalHandler(formik)}
            className="rounded-2xl bg-white px-3 py-[5px] flex"
          >
            <span className="flex gap-2.5 whitespace-nowrap">
              <IoMdAdd className="h-6 w-6 bg-primary text-white p-[5px] rounded-full" />
              YES
            </span>
          </button>
        </div>
      </h2>
    </div>
  );
};
