import React from 'react'
import "../assets/css/ChatLoader.css"

function ChatLoader() {
  return (
<div className="loading">
  <span className="loading__dot"></span>
  <span className="loading__dot"></span>
  <span className="loading__dot"></span>
</div>
  )
}

export default ChatLoader