import React from 'react'
import "../assets/css/loader.css"

function Loader() {
  return (
    <div className="loader"></div>
  )
}

export default Loader
