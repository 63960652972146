import { useNavigate } from "react-router-dom";

const base_url = process.env.REACT_APP_BASE_URL;


export async function loginApi(data: any) {
  const response = await fetch(base_url + "/auth/user/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    mode: "cors",
  });
  const resultData = await response.json();
  return resultData;
}
export async function signupApi(data: any) {
  const response = await fetch(base_url + "/user/signup", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const resultData = await response.json();
  return resultData;
}
export async function userDetailApi() {
  const response = await fetch(base_url + "/fetch/userDetails", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  const resultData = await response.json();
  return resultData;
}

export async function sectionsApi() {
  const response = await fetch(base_url + "/fetch/section", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  const resultData = await response.json();
  return resultData;
}
export async function getRoleListApi(navigate: any) {
  const response = await fetch(base_url + "/fetch/roles", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  const resultData = await response.json();
  if (response.status === 403) {
   
    navigate("/login")
    const payload = {
      status : response.status,
      message: "Session Expired"
    }
    return payload
  } else {
    return resultData;
  }
}
export async function postCreateRoleApi(payload: any) {
  const response = await fetch(base_url + "/insert/role", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(payload),
  });
  const resultData = await response.json();
  return resultData;
}
export async function postUpdateRoleApi(payload: any) {
  const response = await fetch(base_url + "/update/roleInfo", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(payload),
  });
  const resultData = await response.json();
  return resultData;
}
export async function postUpdateUserApi(payload: any) {
  const response = await fetch(base_url + "/update/userDetails", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(payload),
  });
  const resultData = await response.json();
  return resultData;
}
export async function postResetPasswordUserApi(payload: any) {
  const response = await fetch(base_url + "/user/changepassword", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(payload),
  });
  const resultData = await response.json();
  return resultData;
}
export async function deleteUserApi(id: any) {
  const response = await fetch(base_url + `/user/delete?id=${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  const resultData = await response.json();
  return resultData;
}
