import React, { useEffect } from "react";
import ChatSection from "./ChatSection";
import { useLocation, useNavigate } from "react-router-dom";
import { getRoleListApi } from "../services/authServices";
import toast from "react-hot-toast";
import { AuthLogic } from "../services/authlogic";
import openenterprise from "../assets/images/logo 1 copy.png";

function ChatPageOnly() {
  let location = useLocation();
  useEffect(() => {
    localStorage.removeItem("route");
    initialAuth();
  }, []);

  const navigate = useNavigate();

  const initialAuth = async () => {
    AuthLogic("chat", navigate);
  };
  const logoutHandler = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("section");
    navigate("/login");
    notify("Logout Successfully");
  };
  const notify = (value: any) => {
    toast.success(value);
    // simulate authentication success
  };
  return (
    <div className="h-[100vh]">
      <div className="flex h-full flex-col overflow-y-scroll bg-white  shadow-xl">
        <div className="px-2 sm:px-3 sticky top-0 bg-gray-200 p-4 z-10 border-b-2 flex justify-between">
          <div className="text-base font-semibold leading-6 text-gray-900">
            {/* {process.env.REACT_APP_TYPE === "smsn"
              ? "SmartSurgN Coach" */}
            {/* :  */}
            <div className="flex items-center text-black text-lg gap-1">
              <img src={openenterprise} alt="" className="w-7 h-7 ml-2" />{" "}
              Sherpa
            </div>
            {/* } */}
          </div>
          <div>
            <button
              type="button"
              className="hover:bg-red-500 hover:text-white text-red-500 group flex w-full items-center rounded-md px-2 py-2 text-sm justify-center"
              onClick={logoutHandler}
            >
              Logout
            </button>
          </div>
        </div>
        <div className="relative flex-1">
          <ChatSection />
        </div>
      </div>
    </div>
  );
}

export default ChatPageOnly;
