import toast from "react-hot-toast";
import { getRoleListApi } from "./authServices";

export const AuthTabs = async (navigate: any) => {
    try {
        const rolesString = localStorage.getItem("role");
        
  
        // Get roles from localStorage
        if (!rolesString) {
          console.error("Roles not found in localStorage.");
          return;
        }
  
        const roles: string[] = JSON.parse(rolesString);
  
        // Fetch roles data from the API
        const response = await getRoleListApi(navigate);
        if(response?.status === 403){
          // notifyFailure("Session Expired")
          return;
        }
        if (!response || !response.data) {
          console.error("Invalid response format.");
          return;
        }
  
        const formattedData: { name: string }[] = roles?.reduce(
          (acc, roleName) => {
            const role = response.data[roleName];
            if (role) {
              // Extract subsections from each role
              const subsections = Object.values(role)
                .filter((value) => Array.isArray(value))
                .flat();
  
              // Filter out repeated and unique subsections
              const uniqueSubsections = Array.from(new Set(subsections)).map(
                (name) => ({
                  name: name as string,
                })
              );
  
              acc.push(...uniqueSubsections);
            } else {
              console.error(`Role "${roleName}" not found in API response.`);
            }
            return acc;
          },
          [] as { name: string }[]
        );
  
        return formattedData ;
      } catch (error) {
        console.error("Error fetching role list:", error);
        return error;
      }
}

const notifyFailure = (value: string) => {
  toast.error(value.toUpperCase());
  // simulate authentication failure
};