import React, { useEffect, useState } from "react";
import { IoMdAdd } from "react-icons/io";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import ComponentModalBody from "./componentModalBody";
import toast from "react-hot-toast";
import { get_Component, insert_Component } from "../../services/adminService";
import ModalComponent from "../../fieldComponent/modalComponent";

interface Data {
  name: string;
  description: string;
  id: number;
  country_name: string;
}

function Component() {
  const [showComponentsModal, setshowComponentsModal] = useState(false);
  const [data, setData] = useState<Data[]>([]);

  useEffect(() => {
    fetchComponent();
  }, []);

  const fetchComponent = () => {
    get_Component()
      .then((res) => setData(res?.data))
      .catch((err) => notifyFailure(err));
  };

  const closeModalHandler = () => {
    setshowComponentsModal(false);
  };

  const confirmModalHandler = async (value: any) => {
    const result = await insert_Component(value);

    if (result?.success === 1) {
      setshowComponentsModal(false);
      notify("Component Added SuccessFully");
      fetchComponent();
    } else if (result?.success === 0) {
      setshowComponentsModal(false);
      notifyFailure(result?.failed[0].reason);
    }
  };

  const notify = (value: any) => {
    toast.success(value);
    // simulate authentication success
  };
  const notifyFailure = (value: any) => {
    toast.error(value);
    // simulate authentication failure
  };
  return (
    <div>
      <div className="mb-10 flex justify-between">
        <label className="text-[#060606] font-bold text-xl">Component</label>
        <button
          type="button"
          onClick={() => setshowComponentsModal(true)}
          className="rounded-2xl bg-white px-[7px] py-[5px] flex"
        >
          <span className="flex gap-2.5 whitespace-nowrap">
            <IoMdAdd className="h-6 w-6 bg-primary text-white p-[5px] rounded-full" />
            Add Component
          </span>
        </button>
      </div>
      <ModalComponent
        showModal={showComponentsModal}
        closeModal={closeModalHandler}
        header="Add Component"
        mainBody={ComponentModalBody(confirmModalHandler, closeModalHandler)}
      />
      <div>
        <table className="w-full my-6 text-sm">
          <thead className="border-b-2 border-b-[#E1DFDF]">
            <tr>
              <th className="pb-2.5 text-start">Name</th>
              <th className="pb-2.5 text-center">Description</th>
              <th className="pb-2.5 text-end">Country</th>
            </tr>
          </thead>
          <tbody className="">
            {data.length > 0 &&
              data?.map((item) => (
                <>
                  <tr
                    key={item.id}
                    className="hover:font-bold hover:text-blue-800 cursor-pointer"
                  >
                    <td className="py-5 text-start">{item.name}</td>
                    <td className="py-5 text-center w-2/3">
                      {item.description}
                    </td>
                    <td className="py-5 text-end">{item.country_name}</td>
                  </tr>
                </>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Component;
