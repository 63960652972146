import React, { useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
import SmartsurgAdmin from "./smartsurgAdmin";
import Guidelines from "./guidelines/guidelines";
import { getRoleListApi } from "../../services/authServices";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthLogic } from "../../services/authlogic";
import { AuthTabs } from "../../services/authtabs";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}
interface Data {
  name: string;
}
function AdminComponent() {
  const tabList = ["Devices", "Guidelines"];
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const [sections, setSections] = useState<Data[]>([]);
  useEffect(() => {
    initialload();
    initialAuth()
    
      localStorage.removeItem("route")
  }, []);
  const initialAuth = async () => {
   AuthLogic("admin", navigate)
  };
  const initialload = async () => {
    const data: Data[] = await AuthTabs(navigate) as Data[];
    setSections(data);
  };

  const handleClick = (index: any) => {
    searchParams.set("tab", index);
    setSearchParams(searchParams);
  };


  return (
    <div>
      <Tab.Group selectedIndex={searchParams.get("tab") === "1" ? 1 : 0}>
        <Tab.List className="flex space-x-1 rounded-3xl w-2/3  p-1 bg-[#CACACA]">
          {tabList.map((category, index) => {
            const sectionExists = sections?.some(
              (section) => section.name === category
            );

            return sectionExists ? (
              <Tab
                key={category}
                onClick={() => handleClick(index)}
                className={({ selected }) =>
                  classNames(
                    "w-full rounded-3xl py-2.5 text-sm font-medium leading-5 focus:outline-none",
                    selected
                      ? "bg-secondary shadow text-white font-semibold"
                      : "text-black"
                  )
                }
              >
                {category}
              </Tab>
            ) : null;
          })}
        </Tab.List>
        <Tab.Panels className="bg-white p-5 mt-3 rounded-lg">
            {sections?.some((section) => section.name === "Devices") && (
          <Tab.Panel>
              <SmartsurgAdmin />
          </Tab.Panel>
            )}
            {sections?.some((section) => section.name === "Guidelines") && (
          <Tab.Panel>
              <Guidelines />
          </Tab.Panel>
            )}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}

export default AdminComponent;
