  import React, { useEffect, useState } from "react";
  import { Tab } from "@headlessui/react";
  import CreateUser from "./createUser";
  import CreateRole from "./createRole";
  import { getRoleListApi } from "../../services/authServices";
  import { useNavigate } from "react-router-dom";
  import { AuthLogic } from "../../services/authlogic";
  import { AuthTabs } from "../../services/authtabs";
  import { useSearchParams } from "react-router-dom";

  interface Data {
    name: string;
  }

  function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
  }
  function UserComponent() {
    const tabList = ["User", "Role"];
    let [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    useEffect(() => {
      initialAuth();
      initialload();
      localStorage.removeItem("route")
    }, []);

    const [sections, setSections] = useState<Data[]>([]);
    const [tab, setTab] = useState(0);

    const initialAuth = async () => {
      AuthLogic("user", navigate);
    };
    const initialload = async () => {
      const data: Data[] = (await AuthTabs(navigate)) as Data[];
      setSections(data);
    };

    const handleClick = (index: any) => {
      searchParams.set("tab", index);
      setSearchParams(searchParams);
    };

    useEffect(() => {
      if (searchParams.get("tab") === null) {
        setTab(0);
      } else if (searchParams.get("tab") === "1") {
        setTab(1);
      }else if (searchParams.get("tab") === "0") {
        setTab(0);
      }
    }, [searchParams]);

    console.log(tab)

    return (
      <div>
        <Tab.Group selectedIndex={searchParams.get("tab") === "1" ? 1 : 0}>
          <Tab.List className="flex space-x-1 rounded-3xl w-2/3  p-1 bg-[#CACACA]">
            {tabList.map((category, index) => {
              const sectionExists = sections?.some(
                (section) => section.name === category
              );

              return sectionExists ? (
                <Tab
                  key={category}
                  onClick={() => handleClick(index)}
                  className={({ selected }) =>
                    classNames(
                      "w-full rounded-3xl py-2.5 text-sm font-medium leading-5 focus:outline-none",
                      selected
                        ? "bg-secondary shadow text-white font-semibold"
                        : "text-black"
                    )
                  }
                >
                  {category}
                </Tab>
              ) : null;
            })}
          </Tab.List>
          <Tab.Panels className="bg-white p-5 mt-3 rounded-lg">
            {sections?.some((section) => section.name === "User") && (
              <Tab.Panel>
                <CreateUser />
              </Tab.Panel>
            )}
            {sections?.some((section) => section.name === "Role") && (
              <Tab.Panel>
                <CreateRole />
              </Tab.Panel>
            )}
          </Tab.Panels>
        </Tab.Group>
      </div>
    );
  }

  export default UserComponent;
