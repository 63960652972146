import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

interface Task {
  task_name: string;
  users: any;
  task_date: string;
  document_name: string;
  summary: string;
}

interface TimelinePdfProps {
  data: Task[];
}

const TimelinePdf: React.FC<TimelinePdfProps> = ({ data }) => {
  const styles = StyleSheet.create({
    page: {
      flexDirection: "row",
      backgroundColor: "#E4E4E4",
      padding: 10,
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
      position: "relative",
    },
    timelineElement: {
      marginBottom: 10,
      padding: 10,
      borderRadius: 5,
      position: "relative",
    },
    evenElement: {
      backgroundColor: "#32768B",
      color: "#000",
      width: "45%",
    },
    oddElement: {
      backgroundColor: "rgb(0, 169, 47)",
      color: "black",
      width: "45%",
      marginLeft: "auto",
    },
    timeline: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: "50%",
      width: 2,
      backgroundColor: "red", // Change the color to white
    },
    arrow: {
      position: "absolute",
      top: "50%",
      left: "137%",
      width: 15,
      height: 15,
      borderRadius: 50,
      backgroundColor: "#32768B",
      transform: "translate(-50%, -50%) rotate(45deg)",
    },
    arrowleft: {
      position: "absolute",
      backgroundColor: "rgb(0, 169, 47)",
      top: "50%",
      right: "99%",
      width: 15,
      height: 15,
      borderRadius: 50,
      transform: "translate(-50%, -50%) rotate(45deg)",
    },
    taskName: {
      fontSize: 12,
      fontWeight: "black",
      marginBottom: 5,
      color: "white",
    },
    otherText: {
      fontSize: 10,
      fontWeight: "thin",
      marginBottom: 2,
      color: "white",
    },
  });
  const chunks = [];
  for (let i = 0; i < data.length; i += 3) {
    chunks.push(data.slice(i, i + 3));
  }

  return (
    <Document>
      {chunks.map((chunk, pageIndex) => (
        <Page size="A4" style={styles.page} key={pageIndex}>
          <View style={styles.section}>
            <View style={styles.timeline} />
            {chunk.map((item, index) => (
              <View
                key={index}
                style={{
                  ...styles.timelineElement,
                  ...(pageIndex % 2 === 0
                    ? index % 2 === 0
                      ? styles.evenElement
                      : styles.oddElement
                    : index % 2 === 1
                    ? styles.evenElement
                    : styles.oddElement),
                }}
              >
                <View
                  style={
                    pageIndex % 2 === 0
                      ? index % 2 === 0
                        ? styles.arrow
                        : styles.arrowleft
                      : index % 2 === 1
                      ? styles.arrow
                      : styles.arrowleft
                  }
                />
                <Text style={styles.taskName}>{item.task_name}</Text>
                <Text style={styles.otherText}>{item.document_name}</Text>
                {Array.isArray(item.users) ? (
                  item.users.map((user: any, idx: any) => (
                    <Text key={idx} style={styles.otherText}>
                      {user}
                    </Text>
                  ))
                ) : (
                  <Text style={styles.otherText}>{item.users.users[0]}</Text>
                )}
                <Text style={styles.otherText}>{item.task_date}</Text>
                <Text style={styles.otherText}>{item.summary}</Text>
              </View>
            ))}
          </View>
        </Page>
      ))}
    </Document>
  );
};

export default TimelinePdf;
