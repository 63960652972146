import React, { useEffect, useState } from 'react'
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import { IoMdAdd } from 'react-icons/io';
import { get_Country } from '../../services/adminService';


interface Data {
    name: string;
    description: string;
    id: number;
    }

  
function ComponentModalBody(confirmModalHandler: any, closeModalHandler: any) {
    const [data, setData] = useState<Data[]>([]);

    useEffect(() => {
      fetchCountry();
    }, []);
  
    const fetchCountry = () => {
      get_Country()
        .then((res) => setData(res?.data))
        .catch((err) => console.log(err));
    };
    const initialValues = {
      name: "",
      description: "",
      country_id: "",
    };
  
    const validationSchema = yup.object().shape({
      name: yup
        .string()
        .max(60, "Character limit is 60")
        .required(" Please enter a template name. "),
      description: yup.string().max(400, "Character limit is 400").required(),
      country_id: yup.string().max(400, "please select").required(),
    });
    const onSubmit = (value: any) => {
       value.country_id = parseInt(value.country_id)
      confirmModalHandler(value);
    };
    return (
      <div className="bg-[#EEEEEE] rounded-2xl">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {(formik) => (
            <Form className="p-6 space-y-[30px]" action="#">
              <div className="">
                <div className="grid grid-cols-2 gap-x-4 gap-y-2.5   ">
                  <label className="m-auto">Name</label>
                  <Field
                    style={
                      formik.errors.name && formik.touched.name
                        ? { borderBottom: "2px solid #E61E50" }
                        : {}
                    }
                    type="text"
                    name="name"
                    placeholder="name"
                    maxlength="60"
                    className="inputField focus-all normal-case"
                  />
                  <label className="m-auto">Description</label>
                  <Field
                    style={
                      formik.errors.description && formik.touched.description
                        ? { borderBottom: "2px solid #E61E50" }
                        : {}
                    }
                    type="text"
                    name="description"
                    placeholder="description"
                    maxlength="60"
                    className="inputField focus-all normal-case"
                  />
                  <label className="m-auto">Country</label>
                
                  <Field
                    as="select"
                    className="inputField px-10"
                    name="country_id"
                    placeholder="Country"
                    style={
                      formik.errors.country_id && formik.touched.country_id
                        ? { borderBottom: "2px solid #E61E50" }
                        : {}
                    }
                  >
                    <option value="" disabled></option>
                    {data?.map((item)=> (
                        <option value={item.id}>{item.name}</option>
                    ))}
                  </Field>
                </div>
                <h2 className="flex justify-between   rounded-md mt-10 w-full">
                  <div className="my-auto">
                    <p className="  text-[14px] text-black my-auto "></p>
                  </div>
                  <div className=" flex gap-3 ">
                    <button
                      type="button"
                      onClick={() => closeModalHandler()}
                      className="rounded-2xl bg-secondary px-3 py-[5px] flex text-white "
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="rounded-2xl bg-white px-3 py-[5px] flex"
                    >
                      <span className="flex gap-2.5 whitespace-nowrap">
                        <IoMdAdd className="h-6 w-6 bg-primary text-white p-[5px] rounded-full" />
                        Submit
                      </span>
                    </button>
                  </div>
                </h2>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
}

export default ComponentModalBody
