import React, { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import moment from "moment";
import BarGraphComponent from "./barGraph";

function RiskManagement() {
  const files = [
    {
      eventDate: new Date(),
      eventType: "Malfunction",
      deviceProblem: "Fogging",
      statuscode: "#1A932E",
      backcolor: "#BCD1BF",
    },
    {
      eventDate: new Date(),
      eventType: "Malfunction",
      deviceProblem: "Power Problem",
      statuscode: "#E65F2B",
      backcolor: "#E0C8BF",
    },
    {
      eventDate: new Date(),
      eventType: "Malfunction",
      deviceProblem: "At Risk",
      statuscode: "#EE201C",
      backcolor: "#E2BDBC",
    },
    {
      eventDate: new Date(),
      eventType: "Malfunction",
      deviceProblem: "No Display",
      statuscode: "#DFA510",
      backcolor: "#E0D7C0",
    },
  ];

  const data: any = [
    ["Adverse Event Without Identified Device or Use Problem", 3],
    ["Break", 2],
    ["Communication or Transmission Problem", 2],
    ["Computer Software Problem", 2],
    ["Crack", 2],
    ["Defective Component", 2],
    ["Detachment of Device or Device Component", 2],
    ["Device Damaged by Another Device", 2],
    ["Device Reprocessing Problem", 2],
    ["Display or Visual Feedback Problem", 2],
  ];

  // const [filterFileName, setFilterFileName] = useState("");
  // const [filterUploadedBy, setFilterUploadedBy] = useState("");
  // const [filterStatus, setFilterStatus] = useState<number | undefined>();

  // const handleFilterFileNameChange = (
  //   e: React.ChangeEvent<HTMLSelectElement>
  // ) => {
  //   setFilterFileName(e.target.value);
  // };

  // const handleFilterUploadedByChange = (
  //   e: React.ChangeEvent<HTMLSelectElement>
  // ) => {
  //   setFilterUploadedBy(e.target.value);
  // };

  // const handleFilterStatusChange = (
  //   e: React.ChangeEvent<HTMLSelectElement>
  // ) => {
  //   setFilterStatus(parseInt(e.target.value));
  // };

  // const filteredFiles = files.reduce((filtered, file) => {
  //   if (
  //     (filterFileName && file.filename !== filterFileName) ||
  //     (filterUploadedBy && file.uploadedBy !== filterUploadedBy) ||
  //     (filterStatus && file.status !== filterStatus)
  //   ) {
  //     return filtered;
  //   }

  //   return [...filtered, file];
  // }, [] as File[]);

  return (
    <div className="bg-[#EEEEEE] p-5 rounded-2xl">
      <div className="bg-[#DFDFDF] p-5 rounded-2xl">
        <div className="flex justify-between gap-36">
          <label className="text-[#060606] font-bold whitespace-nowrap my-auto">
            SSN ENDOSCOPY
          </label>
          <div className="flex gap-2 w-full">
            <select
              name="eventDate"
              id="eventDate"
              className="inputField w-full "
            >
              <option value="">Event Date</option>
            </select>
            <select
              name="eventType"
              id="eventType"
              className="inputField w-full "
            >
              <option value="">Event Type</option>
            </select>
            {/* <select
              name="Manufacturer"
              id="Manufacturer"
              className="inputField w-full "
            >
              <option value="">Manufacturer</option>
            </select> */}
            <select name="Region" id="Region" className="inputField w-full ">
              <option value="">Region</option>
            </select>
            <select name="status" id="status" className="inputField w-full ">
              <option value="">Status</option>
            </select>
            <select
              name="deviceProblem"
              id="deviceProblem"
              className="inputField w-full "
            >
              <option value="">Device Problem</option>
            </select>
          </div>
        </div>
        <div>
          <table className="w-full my-6 text-sm">
            <thead>
              <tr>
                <th className="pb-2.5 text-start">Event Date</th>
                <th className="pb-2.5 text-center">Event Type</th>
                <th className="pb-2.5 text-center">Device Problem</th>
                <th className="pb-2.5 text-end">Region</th>
              </tr>
            </thead>
            <tbody>
              {files?.map((file, index) => (
                <tr key={index} className="hover:font-bold hover:text-blue-800">
                  <td className="text-start">
                    {moment(file.eventDate).format("DD/MM/YYYY hh:mm:ss")}
                  </td>
                  <td className="text-center">{file.eventType}</td>
                  <td className="text-center flex justify-center ">
                    <div
                      className="flex justify-center items-center m-1 font-medium py-1 px-2 text-white rounded-full w-fit opac"
                      style={{ background: file.backcolor }}
                    >
                      <div
                        className="text-xs font-normal leading-none flex-initial "
                        style={{ color: file.statuscode }}
                      >
                        {file?.deviceProblem}
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="flex w-[96%] flex-col flex-1  justify-center rounded-md mt-10 bg-white py-4 px-4 ml-5 mr-5">
        {/* <div className="m-auto w-[100%] h-[1200px]"> */}
        <div className="flex justify-around mb-20">
          <label className="font-bold text-3xl ">Risk Analysis</label>
          <div className="flex justify-center gap-10">
            <div className="flex gap-2 ">
              <div className="bg-[#3B82F6] h-4 w-4 my-auto"></div>
              <div className=" my-auto">Others</div>
            </div>
            <div className="flex gap-2 ">
              <div className="bg-[#6B7280] h-4 w-4 my-auto"></div>
              <div className=" my-auto">Malfunction</div>
            </div>
            <div className="flex gap-2 ">
              <div className="bg-[#FACC15] h-4 w-4 my-auto"></div>
              <div className=" my-auto">Injury</div>
            </div>
            <div className="flex gap-2 ">
              <div className="bg-[#FB923C] h-4 w-4 my-auto"></div>
              <div className=" my-auto">Death</div>
            </div>
          </div>
        </div>
        <BarGraphComponent value={data} />
        <div className="flex justify-around mb-20">
          <label className="text-xl ">Potential Hazard/Failure Mode</label>
        </div>
      </div>
      <div className="flex w-[96%] flex-col flex-1  justify-center rounded-md mt-10 bg-white py-4 px-4 ml-5 mr-5">
        {/* <div className="m-auto w-[100%] h-[1200px]"> */}
        <div className="flex justify-around mb-20">
          <label className="font-bold text-3xl ">SSN Severity Analysis</label>
          <div className="flex justify-center gap-10">
            {/* <div className="flex gap-2 ">
              <div className="bg-[#3B82F6] h-4 w-4 my-auto"></div>
              <div className=" my-auto">Others</div>
            </div> */}
            <div className="flex gap-2 ">
              <div className="bg-[#6B7280] h-4 w-4 my-auto"></div>
              <div className=" my-auto">Malfunction</div>
            </div>
            <div className="flex gap-2 ">
              <div className="bg-[#FACC15] h-4 w-4 my-auto"></div>
              <div className=" my-auto">Injury</div>
            </div>
            <div className="flex gap-2 ">
              <div className="bg-[#FB923C] h-4 w-4 my-auto"></div>
              <div className=" my-auto">Customer Dissatisfaction</div>
            </div>
          </div>
        </div>
        <BarGraphComponent value={data} />
        <div className="flex justify-around mb-20">
          <label className="text-xl ">Potential Hazard/Failure Mode</label>
        </div>
      </div>
      <div className="flex w-[96%] flex-col flex-1  justify-center rounded-md mt-10 bg-white py-4 px-4 ml-5 mr-5">
        {/* <div className="m-auto w-[100%] h-[1200px]"> */}
        <div className="flex justify-around mb-20">
          <label className="font-bold text-3xl ">Residual Risk Index</label>
        </div>
        <BarGraphComponent value={data} />
      </div>
    </div>
  );
}

export default RiskManagement;
