import React, { useCallback, useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import { Box, CircularProgress } from "@mui/material";
import { BsCheckCircle } from "react-icons/bs";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { IoCopy } from "react-icons/io5";

import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import DeltaPdfDownload from "./deltaPdfDownload";
import {
  get_Country,
  get_GuidelineDeltaTableData,
  get_Guidelines,
} from "../../../services/adminService";
import Loader from "../../loader";
import toast from "react-hot-toast";

interface Data {
  name: string;
  description: string;
  id: number;
}

function DeltaComparision() {

  const [expandedRow, setExpandedRow] = useState(null);
  const [filterFileName, setFilterFileName] = useState("");
  const [countryData, setCountryData] = useState<Data[]>([]);
  const [firstGuidelinesData, setFirstGuidelinesData] = useState<Data[]>([]);
  const [secondGuidelinesData, setSecondGuidelinesData] = useState<Data[]>([]);
  const [data, setData] = useState([]);
  const [filterStatus, setFilterStatus] = useState<number | undefined>();
  const [filterCountry, setFilterCountry] = useState<string>("");
  const [filterFirstCountry, setFilterFirstCountry] = useState<string>("");
  const [firstGuidelineSelected, setFirstGuideline] = useState<string>("");
  const [secondGuidelineSelected, setSecondGuideline] = useState<string>("");
  const [loader, setloader] = useState(true);

  useEffect(() => {
    initialLoad();
  }, []);

  const initialLoad = useCallback(() => {
    get_Country()
      .then((res) => {
        setCountryData(res?.data);
        setloader(false);
      })
      .catch((err) => console.log(err));
  }, []);
  const getTableData = (firstId: any, secondId: any) => {
    get_GuidelineDeltaTableData(firstId, secondId)
      .then((res) => {
        setData(res);
        setloader(false);
      })
      .catch((err) => console.log(err));
  };

  const onSubmit = async (value: any) => {
    if(value.firstGuidelines == value.secondGuidelines){
      notifyFailure("Please Select The Guidelines Correctly");
    }else{

      setloader(true);
      const firstname = await countryData.filter(
      (item) => item.id == value.firstCountry
      )[0].name;
    const secondname = await countryData.filter(
      (item) => item.id == value.secondCountry
      )[0].name;
      const firstGuideline = await firstGuidelinesData.filter(
        (item) => item.id == value.firstGuidelines
        )[0].name;
      const secondGuideline = await secondGuidelinesData.filter(
        (item) => item.id == value.secondGuidelines
        )[0].name;

    setFilterCountry(firstname);
    setFilterFirstCountry(secondname);
    setFirstGuideline(firstGuideline);
    setSecondGuideline(secondGuideline);
    
    getTableData(value.firstGuidelines, value.secondGuidelines);
  }
  };
  const handleRowClick = (rowId: any) => {
    setExpandedRow(expandedRow === rowId ? null : rowId);
  };
  const handleCountryChange = (e: any, formik: any) => {
    formik.setFieldValue("firstCountry", e.target.value);
    get_Guidelines(e.target.value)
      .then((res) => {
        setFirstGuidelinesData(res?.data);
      })
      .catch((err) => console.log(err));
  };
  const handleSecondCountryChange = (e: any, formik: any) => {
    formik.setFieldValue("secondCountry", e.target.value);
    get_Guidelines(e.target.value)
      .then((res) => {
        setSecondGuidelinesData(res?.data);
      })
      .catch((err) => console.log(err));
  };

  const notifyFailure = (value: any) => {
    toast.error(value);
    // simulate authentication failure
  };
  return (
    <div className="p-5 bg-[#ECE6E657] rounded-xl gap-y-5 flex flex-col">
      <div className="flex justify-between">
        <label className="main-title ">Certification Steps</label>
      </div>
      <div className="flex justify-between">
        <Formik
          initialValues={{
            firstCountry: "",
            firstGuidelines: "",
            secondCountry: "",
            secondGuidelines: "",
          }}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {(formik) => (
            <Form className="flex gap-10">
              <div className="grid grid-cols-2 gap-10">

              <div className="w-96">
                <Field
                  as="select"
                  name="firstCountry"
                  placeholder="Country"
                  className="inputField w-96"
                  onChange={(e: any) => handleCountryChange(e, formik)}
                  >
                  <option value="">Select First Country</option>
                  {countryData?.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
                </Field>
              </div>
              {formik.values.firstCountry !== "" ? (
                <div className="w-96">
                  <Field
                    as="select"
                    name="firstGuidelines"
                    placeholder="guidelines"
                    className="inputField w-96"
                    >
                    <option value="">Select First Guidelines</option>
                    {firstGuidelinesData?.map((item) => (
                      <option value={item.id}>{item.name}</option>
                      ))}
                  </Field>
                </div>
              ) : null}
              {formik.values.firstGuidelines !== "" ? (
                <>
                  <div className="w-96">
                    <Field
                      as="select"
                      name="secondCountry"
                      placeholder="Country"
                      className="inputField w-96"
                      onChange={(e: any) =>
                        handleSecondCountryChange(e, formik)
                      }
                    >
                      <option value="">Select Second Country</option>
                      {countryData?.map((item) => (
                        <option value={item.id}>{item.name}</option>
                        ))}
                    </Field>
                  </div>
                  {formik.values.secondCountry !== "" ? (
                    <div className="w-96">
                      <Field
                        as="select"
                        name="secondGuidelines"
                        placeholder="guidelines"
                        className="inputField w-96"
                        >
                        <option value="">Select Second Guidelines</option>
                        {secondGuidelinesData.map((item) => (
                          <option value={item.id}>{item.name}</option>
                          ))}
                      </Field>
                    </div>
                  ) : null}
                </>
              ) : null}
              </div>
              <button
                type="submit"
                className="rounded-2xl bg-white px-3 py-[5px] flex h-11"
                disabled={loader}
              >
                <span className="flex gap-2.5 whitespace-nowrap m-auto">
                  <div className="h-6 w-6 bg-primary text-white p-[5px] rounded-full" />
                  Check Delta
                </span>
              </button>
            </Form>
          )}
        </Formik>
        <div className=" bg-secondary px-[7px] py-[5px] rounded-full self-center">
          <PDFDownloadLink
            document={
              <DeltaPdfDownload
                data={
                  data as unknown as {
                    [key: string]: { [key: string]: string };
                  }
                }
                filterFirstCountry={filterFirstCountry}
                filterCountry={filterCountry}
                firstGuideline={firstGuidelineSelected}
                secondGuideline={secondGuidelineSelected}
              />
            }
            fileName="delta.pdf"
          >
            {({ blob, url, loading, error }) =>
              loading ? "Generating PDF..." : "Download PDF"
            }
          </PDFDownloadLink>
        </div>
      </div>
      <div className="bg-white p-5 rounded-2xl">
        <div className="flex justify-between">
          <label className="text-[#060606] font-bold">
            Appplication Status
          </label>
        </div>
        <div>
          {!loader ? (
            <table className="w-full my-6 text-sm">
              <thead className="border-b-2 border-b-[#E1DFDF]">
                <tr>
                  <th className="pb-2.5 text-start">File Name</th>
                  <th className="pb-2.5 text-center">Download Links</th>
                  <th className="pb-2.5 text-center">Status</th>
                  <th className="pb-2.5 text-center">Progress</th>
                  <th className="pb-2.5 text-center">{firstGuidelineSelected}</th>
                  <th className="pb-2.5 text-center">{secondGuidelineSelected}</th>
                  <th className="pb-2.5 text-end">Action</th>
                </tr>
              </thead>
              <tbody className="">
                {Object.entries(data)?.map(([section, details], index) => (
                  <>
                    <tr
                      key={index}
                      className="hover:font-bold hover:text-blue-800 cursor-pointer"
                    >
                      <td
                        className="py-5 text-start"
                        onClick={() => handleRowClick(index)}
                      >
                        {section}
                      </td>
                      <td className="py-5 text-center">
                        https://example.com/file1
                      </td>
                      <td className="py-5 text-center flex justify-center">
                        <div
                          className="flex justify-center items-center m-1 font-medium py-1 px-2 text-white rounded-full w-fit opac"
                          style={{
                            background: statuscode.filter(
                              (status) => status.id === 3
                            )[0].secondary,
                          }}
                        >
                          <div
                            className="text-xs font-normal leading-none flex-initial "
                            style={{
                              color: statuscode.filter(
                                (status) => status.id === 3
                              )[0].primary,
                            }}
                          >
                            Completed
                            {/* {item?.status} */}
                          </div>
                        </div>
                      </td>
                      <td className="text-center">
                        <Box
                          sx={{ position: "relative", display: "inline-flex" }}
                        >
                          <CircularProgress
                            variant="determinate"
                            value={100}
                            style={{
                              color: statuscode.filter(
                                (status) => status.id === 3
                              )[0].primary,
                            }}
                            thickness={5}
                            size={32}
                            sx={{
                              colorSecondary: () => "black",
                            }}
                          />
                          <div
                            style={{
                              top: 0,
                              left: 0,
                              bottom: 0,
                              right: 0,
                              position: "absolute",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div className="text-[9px]">100%</div>
                          </div>
                        </Box>
                      </td>
                      <td className="py-3 text-center ">
                        <BsCheckCircle className="text-xl text-[#1A932E] m-auto ring-4 ring-[#1A932E] rounded-full ring-offset-2" />
                        {/* {item.US ? (
                        // <div className="border-4 border-[#1A932E] rounded-full w-fit m-auto p-0.5">
                      ) : (
                        // </div>
                        // <div className="border-4 border-[#EE201C] rounded-full w-fit m-auto p-0.5">
                        <AiOutlineCloseCircle className="text-2xl text-[#EE201C] m-auto ring-4 ring-[#EE201C] rounded-full " />
                        // </div>
                      )} */}
                      </td>
                      <td>
                        <BsCheckCircle className="text-xl text-[#1A932E] m-auto ring-4 ring-[#1A932E] rounded-full ring-offset-2" />
                        {/* {item[filterCountry] ? (
                        // <div className="border-4 border-[#1A932E] rounded-full w-fit m-auto p-0.5">
                      ) : (
                        // </div>
                        // <div className="border-4 border-[#EE201C] rounded-full w-fit m-auto p-0.5">
                        <AiOutlineCloseCircle className="text-2xl text-[#EE201C] m-auto ring-4 ring-[#EE201C] rounded-full " />
                        // </div>
                      )} */}
                      </td>
                      <td className="py-3 text-end">
                        <button>
                          <IoCopy className="text-2xl text-gray-400 ml-auto" />
                        </button>
                        {/* {item.US && item[filterCountry] ? (
                      ) : null} */}
                      </td>
                    </tr>
                    {expandedRow === index && (
                      <tr className="p-[18px] bg-[#F9F7F7] rounded-xl w-full">
                        <td colSpan={7}>
                          <div className="p-[18px] bg-[#eeecec] rounded-xl">
                            <table className="w-full my-6 text-sm">
                              <thead>
                                <tr className="border-b-2">
                                  <th className="pb-2.5 text-start">{firstGuidelineSelected}</th>
                                  <th className="pb-2.5 text-start">
                                    {secondGuidelineSelected}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="pb-2.5 pr-10 text-start w-1/2">
                                    {details[`${firstGuidelineSelected}`]}
                                  </td>
                                  <td className="pb-2.5  pr-10 text-start">
                                    {details[`${secondGuidelineSelected}`]}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="flex h-52 m-auto w-full justify-center items-center">
              <Loader />
            </div>
          )}
        </div>
      </div>
      {/* <PDFViewer
          style={{ width: "100%", height: "1000px" }}
          showToolbar={false}
          
        >
          <DeltaPdfDownload data={data as unknown as { [key: string]: { [key: string]: string } }} filterCountry={filterCountry}/>
        </PDFViewer> */}
    </div>
  );
}

export default DeltaComparision;

const statuscode = [
  {
    id: 0,
    name: "At risk",
    primary: "#EE201C",
    secondary: "#E2BDBC",
  },
  {
    id: 1,
    name: "Delayed",
    primary: "#DFA510",
    secondary: "#F0E6CF",
  },
  {
    id: 2,
    name: "On Going",
    primary: "#E65F2B",
    secondary: "#E0C8BF",
  },
  {
    id: 3,
    name: "Completed",
    primary: "#1A932E",
    secondary: "#BCD1BF",
  },
];
