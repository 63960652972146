
const base_url = process.env.REACT_APP_BASE_URL;
const token = localStorage.getItem("token");

export async function get_Country() {
  const response = await fetch(base_url + `/v0.1/fetch/countries`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  const resultData = await response.json();
  return resultData;
}
export async function get_Class_dropdown() {
  const response = await fetch(base_url + `/v0.1/fetch/classes`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  const resultData = await response.json();
  return resultData;
}
export async function get_Attribute_Dropdown() {
  const response = await fetch(base_url + `/v0.1/fetch/dropdown_attributes`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  const resultData = await response.json();
  return resultData;
}
export async function get_Value_Dropdown(id: any) {
  const response = await fetch(
    base_url + `/v0.1/fetch/dropdown_values?dropdown_id=${id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  const resultData = await response.json();
  return resultData;
}

export async function get_Device() {
  const response = await fetch(base_url + `/v0.1/fetch/devices`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  const resultData = await response.json();
  return resultData;
}
export async function get_Device_Page_Wise_Data(payload: any) {
  const response = await fetch(base_url + `/v1/device/fetch/rows?${payload}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  const resultData = await response.json();
  return resultData;
}
export async function get_Device_Page_Count(payload: any) {
  const response = await fetch(base_url + `/v1/device/fetch/page_count?${payload}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  const resultData = await response.json();
  return resultData;
}

export async function get_Guidelines(country_id: any) {
  const response = await fetch(
    base_url +
      `/v1/guideline/fetch/rows?page_size=50&${
        country_id !== "" && country_id !== null
          ? `country_id=${country_id}`
          : ``
      }`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  const resultData = await response.json();
  return resultData;
}
export async function get_Guideline_Page_Wise_Data(payload: any) {
  const response = await fetch(
    base_url +
      `/v1/guideline/fetch/rows?${payload}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  const resultData = await response.json();
  return resultData;
}
export async function get_Guideline_Page_Count(payload: any) {
  const response = await fetch(
    base_url +
      `/v1/guideline/fetch/page_count?${payload}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  const resultData = await response.json();
  return resultData;
}
export async function get_GuidelinePdf(id: any) {
  const response = await fetch(
    base_url + `/v0.1/fetch/guideline_info?id=${id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  const resultData = await response.json();
  return resultData;
}
export async function get_DevicePdf(id: any) {
  const response = await fetch(
    base_url + `/v0.1/fetch/document_info?id=${id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  const resultData = await response.json();
  return resultData;
}
export async function get_GuidelinesType() {
  const response = await fetch(base_url + `/v0.1/fetch/guideline_types`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  const resultData = await response.json();
  return resultData;
}

export async function get_GuidelineDeltaTableData(firstId: any, secondId: any) {
  console.log("object");
  const response = await fetch(
    base_url +
      `/v0.1/fetch/guideline_delta?guideline_1_id=${firstId}&guideline_2_id=${secondId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  const resultData = await response.json();
  return resultData;
}

export async function get_Component() {
  const response = await fetch(base_url + `/v0.1/fetch/components`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  const resultData = await response.json();
  return resultData;
}
export async function get_Device_Document(id: any, pageNo: any) {
  const response = await fetch(base_url + `/v1/document/fetch/page_count?page_size=${pageNo}&device_id=${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  const resultData = await response.json();
  return resultData;
}
export async function get_Device_Document_pagination(payload: any) {
  const response = await fetch(base_url + `/v1/document/fetch/rows?${payload}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  const resultData = await response.json();
  return resultData;
}
export async function get_timelineData( payload: any) {
  const response = await fetch(base_url + `/v0.1/fetch/device_tasks?device_id=${payload}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,

    }
  });
  const resultData = await response.json();
  return resultData;
}
export async function get_device_by_Id( id: any) {
  const response = await fetch(base_url + `/v1/device/fetch/entity?ids=${id}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,

    }
  });
  const resultData = await response.json();
  return resultData;
}
export async function insert_Component(data: any) {
  const response = await fetch(base_url + "/v0.1/insert/components", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify([data]),
  });
  const resultData = await response.json();
  return resultData;
}

export async function insert_Device(data: any) {
  const response = await fetch(base_url + "/v0.1/insert/devices", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify([data]),
  });
  const resultData = await response.json();
  return resultData;
}
export async function upsert_device(data: any) {
  const response = await fetch(base_url + "/v1/device/upsert/entity", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: data,
  });
  const resultData = await response.json();
  return resultData;
}

export async function insert_relation(data: any) {
  const response = await fetch(
    base_url + "/v0.1/insert/component_device_relationship",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify([data]),
    }
  );
  const resultData = await response.json();
  return resultData;
}
export async function insert_Guidelines(formData?: any) {
  const response = await fetch(
    base_url +
      `/v1/guideline/upsert/entity`,
    {
      method: "POST",
      body: formData,
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  const resultData = await response.json();
  return resultData;
}
export async function insert_Attribute_Dropdown(data: any) {
  const response = await fetch(base_url + `/v0.1/insert/dropdown_attribute`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(data),
  });
  const resultData = await response.json();
  return resultData;
}
export async function insert_Value_Dropdown(data: any) {
  const response = await fetch(base_url + `/v0.1/insert/dropdown_value`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(data),
  });
  const resultData = await response.json();
  return resultData;
}
export async function insert_Add_Device_Guidelines(data: any) {
  const response = await fetch(base_url + `/v1/device/update/guidelines`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(data),
  });
  const resultData = await response.json();
  return resultData;
}
export async function insert_Device_Document(data: any,) {
  const response = await fetch(base_url + `/v1/document/upsert/entity`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,

    },
    body: data,
  });
  const resultData = await response.json();
  return resultData;
}
export async function insert_Device_Document_Final(data: any,) {
  const response = await fetch(base_url + `/v1/device/update/documents`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(data),
  });
  const resultData = await response.json();
  return resultData;
}
export async function update_Add_Device_Guidelines(data: any) {
  const response = await fetch(base_url + `/v1/device/update/guidelines`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(data),
  });
  const resultData = await response.json();
  return resultData;
}
export async function delete_Value_Dropdown(id: any) {
  const response = await fetch(
    base_url + `/v0.1/delete/dropdown_values?id=${id}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  const resultData = await response.json();
  return resultData;
}
export async function delete_Attribute_Dropdown(id: any) {
  const response = await fetch(
    base_url + `/v0.1/delete/dropdown_attribute?id=${id}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  const resultData = await response.json();
  return resultData;
}
export async function delete_Guideline(id: any) {
  const response = await fetch(
    base_url + `/v1/guideline/delete/entity?id=${id}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  const resultData = await response.json();
  return resultData;
}
export async function delete_Device_Document(id: any) {
  const response = await fetch(
    base_url + `/v1/document/delete/entity?id=${id}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  const resultData = await response.json();
  return resultData;
}
export async function delete_Device(id: any) {
  const response = await fetch(
    base_url + `/v1/device/delete/entity?id=${id}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  const resultData = await response.json();
  return resultData;
}
