import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import { IoMdAdd } from "react-icons/io";
import {
  get_Country,
  get_GuidelinesType,
} from "../../../services/adminService";
import toast from "react-hot-toast";

interface Data {
  name: string;
  description: string;
  id: number;
}
interface GuideLinesData {
  name: string;
  description: string;
  id: number;
}

function GuidelinesModalBody(
  confirmModalHandler: any,
  closeModalHandler: any,
  editData?: any
) {
  const [data, setData] = useState<Data[]>([]);
  const [guideLinesData, setGuideLinesData] = useState<GuideLinesData[]>([]);
  const [isDragOver, setIsDragOver] = useState(false);

  useEffect(() => {
    fetchCountry();
    fetchGuidelineType();
  }, []);

  const fetchCountry = () => {
    get_Country()
      .then((res: any) => {
        const sortedData = res?.data.sort((a: Data, b: Data) =>
          a.name.localeCompare(b.name)
        );
        setData(sortedData);
      })
      .catch((err: any) => console.log(err));
  };

  const fetchGuidelineType = () => {
    get_GuidelinesType()
      .then((res: any) => setGuideLinesData(res?.data))
      .catch((err: any) => console.log(err));
  };

  const initialValues = {
    id: editData?.id || null,
    name: editData?.name || "",
    description: editData?.description || "",
    country_id: editData?.country_id || "",
    version: 1,
    guideline_type_id: editData?.guideline_type_id || "",
    file: [] as File[],
  };

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .max(60, "Character limit is 60")
      .required("Please enter a template name."),
    description: yup.string().max(400, "Character limit is 400"),
    country_id: yup.string().required("Please select a country."),
    version: yup.number().required("Please enter a version."),
    file: yup.lazy(() =>
      editData ? yup.mixed() : yup.mixed().required("File is required")
    ),
  });

  const countPdfPages = (data: Uint8Array) => {
    const pdfText = new TextDecoder().decode(data);
    const match = pdfText.match(/\/Type[\s]*\/Page[^s]/g);
    return match ? match.length : 0;
  };

  const onSubmit = (values: any) => {
    values.country_id = parseInt(values.country_id);
    values.guideline_type_id = values?.guideline_type_id
      ? parseInt(values.guideline_type_id)
      : "";

    const formData = new FormData();
    formData.append("file", values?.file[0]);
    formData.append("name", values?.name);
    formData.append("description", values?.description);
    formData.append("guideline_type_id", values?.guideline_type_id);
    formData.append("version", values?.version);
    formData.append("country_id", values?.country_id);

    const file = values.file[0];
    if (file.type === "application/pdf") {
      const reader = new FileReader();

      reader.onload = (event) => {
        const arrayBuffer = event.target?.result;
        const data = new Uint8Array(arrayBuffer as ArrayBuffer);
        const pageCount = countPdfPages(data);

        if (pageCount > 150) {
          notify(
            "This file could not be processed. Please contact support@openenterprise.ai for further assistance."
          );
          return;
        }

        confirmModalHandler(formData);
      };

      reader.readAsArrayBuffer(file);
    } else {
      notify("Only pdf formats are allowed.");
      return;
    }
  };

  const onUpdate = (values: any) => {
    values.country_id = parseInt(values.country_id);
    values.guideline_type_id = values?.guideline_type_id
      ? parseInt(values.guideline_type_id)
      : "";

    const formData = new FormData();
    formData.append("id", values?.id);
    formData.append("name", values?.name);
    formData.append("description", values?.description);
    formData.append("guideline_type_id", values?.guideline_type_id);
    formData.append("version", values?.version);
    formData.append("country_id", values?.country_id);
    confirmModalHandler(formData);
  };

  const notify = (value: any) => {
    toast.error(value);
  };

  return (
    <div className="bg-[#EEEEEE] rounded-2xl">
      <Formik
        initialValues={initialValues}
        onSubmit={editData !== null ? onUpdate : onSubmit}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <Form className="p-6 space-y-[30px]" action="#">
            <div className="">
              <div className="grid grid-cols-2 gap-x-4 gap-y-2.5">
                <label className="m-auto">Name</label>
                <Field
                  style={
                    formik.errors.name && formik.touched.name
                      ? { borderBottom: "2px solid #E61E50" }
                      : {}
                  }
                  type="text"
                  name="name"
                  disabled={editData !== null}
                  placeholder="name"
                  maxLength="60"
                  className="inputField focus-all normal-case"
                />
                <label className="m-auto">Description</label>
                <Field
                  style={
                    formik.errors.description && formik.touched.description
                      ? { borderBottom: "2px solid #E61E50" }
                      : {}
                  }
                  type="text"
                  name="description"
                  placeholder="description"
                  maxLength="60"
                  className="inputField focus-all normal-case"
                />
                <label className="m-auto">Country</label>
                <Field
                  as="select"
                  className="inputField px-10"
                  name="country_id"
                  placeholder="Country"
                  style={
                    formik.errors.country_id && formik.touched.country_id
                      ? { borderBottom: "2px solid #E61E50" }
                      : {}
                  }
                >
                  <option value="" disabled></option>
                  {data?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Field>
                <label className="m-auto">Guidelines Type</label>
                <Field
                  as="select"
                  className="inputField px-10"
                  name="guideline_type_id"
                  placeholder="Guideline Type"
                >
                  <option value="" selected disabled></option>
                  {guideLinesData?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Field>
                <label className="m-auto">File</label>
                <label
                  htmlFor="file"
                  className={`rounded-2xl flex w-full h-10 bg-white `}
                >
                  <p className="m-auto text-[#060606] opacity-50">
                    {formik.values.file.length > 0
                      ? formik.values.file[0]?.name
                      : editData
                      ? editData?.file_path
                      : "Upload here - Drag and Drop"}
                  </p>
                </label>
                <input
                  id="file"
                  name="file"
                  type="file"
                  className="sr-only"
                  disabled={editData !== null}
                  onChange={(e) => formik.setFieldValue("file", e.target.files)}
                />
              </div>
              <h2 className="flex justify-between rounded-md mt-10 w-full">
                <div className="my-auto">
                  <p className="text-[14px] text-black my-auto"></p>
                </div>
                <div className="flex gap-3">
                  <button
                    type="button"
                    onClick={() => closeModalHandler()}
                    className="rounded-2xl bg-secondary px-3 py-[5px] flex text-white"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="rounded-2xl bg-white px-3 py-[5px] flex"
                  >
                    <span className="flex gap-2.5 whitespace-nowrap">
                      <IoMdAdd className="h-6 w-6 bg-primary text-white p-[5px] rounded-full" />
                      Submit
                    </span>
                  </button>
                </div>
              </h2>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default GuidelinesModalBody;
