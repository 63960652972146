import React, { useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Label,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

type DataItem = [string, number];

function getColor(value: any) {
  // Determine the color based on the value
  if (value == 1) {
    return "#FB923C";
  } else if (value == 2) {
    return "#6B7280";
  } else if (value == 3) {
    return "#FACC15";
  } else if (value == 4) {
    return "#3B82F6";
  } else {
    return "#fff";
  }
}
function CustomXAxis({ x, y, payload }: any) {
  const label = payload.value;

  // Define the maximum height for the XAxis labels
  const maxLabelHeight = 200; // Adjust as needed

  return (
    <g transform={`translate(${x},${y})`}>
      <foreignObject width={maxLabelHeight} height="100%">
        <div
          style={{
            width: maxLabelHeight + "px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {label}
        </div>
      </foreignObject>
    </g>
  );
}
function BarGraphComponent(value: any) {
  const [data, setData] = useState([]);

  useEffect(() => {
    const chartData = value?.value?.map(([name, value]: [string, number]) => ({
      name,
      value,
      color: getColor(value),
    }));

    setData(chartData);
  }, [value]);

  // Custom XAxis component

  return (
    <ResponsiveContainer width="100%" height={500} className="">
      <BarChart data={data}>
        <XAxis
          dataKey="name"
          angle={-45}
          interval={0}
          height={200}
          textAnchor="end"
          stroke="#0B4A7B"
          // width={200}
          fontSize={10}
          fontStretch={20}
          style={{
            width: "200px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        />
        <CartesianGrid strokeDasharray="3 0" vertical={false} />
        <YAxis
          className="px-8"
          stroke="#0B4A7B"
          fontSize={12}
          tickLine={false}
          axisLine={false}
          tickCount={6}
          domain={[0, 5]}
        >
          <Label
            value="Severity"
            position="insideLeft"
            angle={-90}
            offset={10}
            fontSize={20}
          />
        </YAxis>

        <Tooltip />

        <Bar dataKey="value" radius={[4, 4, 0, 0]} width={40}>
          {data?.map((entry: any, index: number) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>

    // </div>
  );
}

export default BarGraphComponent;
