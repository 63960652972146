import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";

function Stepper() {
  const location = useLocation();
  const { id } = useParams();
  const [index, setIndex] = useState(0);

  console.log(
    location.pathname.includes("/smartsurgn/devices/addDevice/selectGuidelines")
  );

  useEffect(() => {
    handleStepClick(location.pathname);
  }, [location]);

  const handleStepClick = (path: any) => {
   
    switch (path) {
      case "/smartsurgn/devices/addDevice":
        setIndex(1);
        break;
      case `/smartsurgn/devices/addDevice/selectGuidelines/${id}`:
        setIndex(2);
        break;
      case `/smartsurgn/devices/addDevice/uploadDocument/${id}`:
        setIndex(3);
        break;
      default:
        break;
    }
  };
  return (
    <>
      <div className="mx-4 p-4">
        <div className="flex items-center">
          <div className={`flex items-center ${index===1 ? "text-white": index>1 ?  "text-primary": "text-gray-500"} relative`}>
            <div className={`rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2  ${index=== 1 ? "bg-primary ": index>0 ?  "border-primary" : "border-gray-300"}   text-center`}>
              1
            </div>
            <div className="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-primary">
              Add Device
            </div>
          </div>
          <div className={`flex-auto border-t-2 transition duration-500 ease-in-out ${index>1 ? "border-primary" : "border-gray-300"}`}></div>
          <div className={`flex items-center ${index===2 ? "text-white": index>2 ?  "text-primary": "text-gray-500"} relative`}>
            <div className={`rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2  ${index=== 2 ? "bg-primary ": index>1 ?  "border-primary" : "border-gray-300"}   text-center`}>
              2
            </div>
            <div className="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-primary">
              Guidelines
            </div>
          </div>
          <div className={`flex-auto border-t-2 transition duration-500 ease-in-out ${index>2 ? "border-primary" : "border-gray-300"}`}></div>
          <div className={`flex items-center ${index===3 ? "text-white": index>3 ?  "text-primary": "text-gray-500"} relative`}>
            <div className={`rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2  ${index=== 3 ? "bg-primary ": index>3 ?  "border-primary" : "border-gray-300"}   text-center`}>
              3
            </div>
            <div className="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-gray-500">
              Documents
            </div>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
}

export default Stepper;
