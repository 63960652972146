import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import DatePicker from "../../../../fieldComponent/datePicker";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import UploadDocumentTable from "./uploadDocumentTable";
import { get_Device } from "../../../../services/adminService";
import Timeline from "../timeline";
import VerticalTimelineComponent from "../../../verticalTimeline/verticalTimelineComponent";
import { IoMdAdd } from "react-icons/io";

interface FormValues {
  fileName: string;
  date: string | null;
  file: File | null;
}

interface Data {
  name: string;
  description: string;
  id: number;
  country_name: string;
}

interface FileData {
  task_name: string;
  users: any;
  date: string;
  document_name: string;
  summary: string;
}

const DeviceDashboard: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [verticalTimeline, setVerticalTimeline] = useState(false);
  const [fileData, setFileData] = useState<FileData[]>([
    {
      task_name: "task_1",
      users: ["user_1", "user_2"],
      date: "2024/02/05",
      document_name: "test_12",
      summary: "<extracted_from_page>",
    },
    {
      task_name: "task_2",
      users: ["user_2", "user_3"],
      date: "2024/02/19",
      document_name: "test_14",
      summary: "<extracted_from_page>",
    },
    {
      task_name: "task_2",
      users: ["user_3"],
      date: "2024/02/24",
      document_name: "test_15",
      summary: "<extracted_from_page>",
    },
    {
      task_name: "task_2",
      users: ["user_3", "user_2"],
      date: "2024/03/10",
      document_name: "test_16",
      summary: "<extracted_from_page>",
    },
  ]);

  // useEffect(() => {
  //   fetchDevice();
  // }, []);

  // const fetchDevice = () => {
  //   get_Device()
  //     .then((res) => setData(res?.data))
  //     .catch((err) => console.log(err));
  // };

  const handleRowClick = (rowId: any) => {
    searchParams.set("deviceId", rowId);
    setSearchParams(searchParams);
  };
  console.log(verticalTimeline);
  return (
    <>
      <div className="p-5 bg-[#ECE6E657] rounded-xl gap-y-5 flex flex-col">
        {searchParams.get("deviceId") === null ? (
          <>
            <div className="flex justify-between">
              <label className="main-title ">Devices</label>
            </div>

            <table className="w-full my-6 text-sm">
              <thead className="border-b-2 border-b-[#E1DFDF]">
                <tr>
                  <th className="pb-2.5 text-start">Name</th>
                  <th className="pb-2.5 text-center">Date</th>
                  <th className="pb-2.5 text-end">File Name</th>
                  {/* <th className="pb-2.5 text-start">Actions</th> */}
                </tr>
              </thead>
              <tbody>
                {fileData.map((data, index) => (
                  <tr
                    key={index}
                    className="hover:font-bold hover:text-blue-800 cursor-pointer"
                    // onClick={() => handleRowClick(data.id)}
                  >
                    <td className="pb-2.5 text-start">{data.task_name}</td>
                    <td className="pb-2.5 text-center">
                      {moment(data.date).format("MM / DD / YYYY")}
                    </td>
                    <td className="pb-2.5 text-end">{data.document_name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        ) : (
          <UploadDocumentTable id={searchParams.get("deviceId")} />
        )}
      </div>
      
    </>
  );
};

export default DeviceDashboard;
