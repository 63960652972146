import React, { useEffect, useState } from "react";
import Timeline from "./timeline";
import VerticalTimelineComponent from "../../verticalTimeline/verticalTimelineComponent";
import { useSearchParams } from "react-router-dom";
import { get_timelineData } from "../../../services/adminService";
import ModalComponent from "../../../fieldComponent/modalComponent";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import TimelinePdf from "./timelinePdf";
import { FaFileDownload } from "react-icons/fa";

interface FileData {
  id: number;
  task_name: string;
  users: any;
  task_date: string;
  document_name: string;
  summary: string;
}

function CertificationDetails() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [verticalTimeline, setVerticalTimeline] = useState(false);
  const [fileData, setFileData] = useState<FileData[]>([]);

  useEffect(() => {
    fetchDevice();
  }, []);

  const fetchDevice = () => {
    if (searchParams.get("deviceId") !== null) {
      get_timelineData(searchParams.get("deviceId"))
        .then((res) => {
          const sortedData = res?.data.sort(
            (a: any, b: any) =>
              new Date(a.task_date).getTime() - new Date(b.task_date).getTime()
          );
          setFileData(sortedData);
        })
        .catch((err) => console.log(err));
    }
  };
  
  return (
    <div>
      {fileData.length > 0 ? (
        <>
          <Timeline fileData={fileData} />
          <ModalComponent
            showModal={verticalTimeline}
            closeModal={() => setVerticalTimeline(false)}
            header="Timeline"
            mainBody={
              <div>
                <div className="bg-white py-2 px-4">
                  <PDFDownloadLink
                    document={<TimelinePdf data={fileData} />}
                    fileName="document.pdf"
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        "Loading document..."
                      ) : (
                        <div className="flex gap-2 text-green-500">
                          <FaFileDownload className=" text-xl ml-auto" />{" "}
                          Download
                        </div>
                      )
                    }
                  </PDFDownloadLink>
                </div>
                <div className="p-5 bg-[#ECE6E657] rounded-xl gap-y-5 flex flex-col">
                  <VerticalTimelineComponent data={fileData} />
                </div>
              </div>
            }
          />

          <button
            type="button"
            onClick={() => setVerticalTimeline(true)}
            className="bg-red-500 text-white mb-20 group flex w-fit items-center rounded-md px-2 py-2 text-sm justify-center"
          >
            {" "}
            Show Full Timeline
          </button>
        </>
      ) : (
        <div className="bg-red-600 text-white px-2 py-1 w-fit mb-10 rounded-xl ">
          Please Upload Document To View Timeline
        </div>
      )}
    </div>
  );
}

export default CertificationDetails;
