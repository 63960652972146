import React, { Fragment, useEffect, useState } from "react";
import { styled } from "@mui/system";
import { Avatar, Button, Typography } from "@mui/material";
import { FaRegUser } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Menu, Transition } from "@headlessui/react";

interface Data {
  name: string;
}

const RoundedButton = styled(Button)(({ theme }) => ({
  borderRadius: "20px", // Adjust the border radius as per your preference
  padding: theme.spacing(2),
  marginTop: theme.spacing(0.5),
  width: "auto",
  height: theme.spacing(5),
  display: "flex",
}));

const RoundedAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(4),
  height: theme.spacing(4),
  marginRight: theme.spacing(2),
}));

const ProfileButton: React.FC<{ sections: Data[] }> = ({sections}) => {
  let location = useLocation();
  const [Name, setName] = useState("");
  useEffect(() => {
    if (location.pathname.includes("client")) {
      setName("Client");
    } else if (location.pathname.includes("underwriter")) {
      setName("Underwriter");
    } else {
      setName("Client");
    }
  }, [location]);
  const navigate = useNavigate();
  const logoutHandler = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("section");
    navigate("/login");
    notify("Logout Successfully");
  };
  const notify = (value: any) => {
    toast.success(value);
    // simulate authentication success
  };

  return (
    <>
      <Menu>
        <Menu.Button>
          <RoundedButton
            style={{ background: "white", color: "black" }}
            variant="contained"

            // onClick={()=>logoutHandler()}
          >
            <RoundedAvatar>
            <FaRegUser />
            </RoundedAvatar>
            <Typography variant="body1" className="text-primary">{Name}</Typography>
          </RoundedButton>
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-500 transform"
          enterFrom="opacity-0 scale-0 origin-top-center translate-y-[-20px] translate-x-[-20px]"
          enterTo="opacity-100 scale-100 origin-top-center translate-y-0 translate-x-0"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100 origin-top-center translate-y-0 translate-x-0"
          leaveTo="opacity-0 scale-0 origin-top-center translate-y-[-20px] translate-x-[-20px]"
        >
          <Menu.Items className="absolute right-2 top-12 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
          {sections?.some((item) => item.name === "User Access Control") && (
              <div className="px-1 py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? "bg-orange-400 text-white" : "text-gray-900"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  >
                    <Link className="w-full" to="smartsurgn/user">
                      User Access Control</Link>
                  </button>
                )}
              </Menu.Item>
            </div>)}
            <div className="px-1 py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? "bg-red-500 text-white" : "text-red-500"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm justify-center`}
                    onClick={logoutHandler}
                  >
                    Log Out
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};

export default ProfileButton;
