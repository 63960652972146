import { Field, Form, Formik } from "formik";
import React from "react";
import { IoMdAdd } from "react-icons/io";
import * as yup from "yup";

function AddDocumentModalBody(
  confirmModalHandler: any,
  closeModalHandler: any,
  id: any,
  edit: any
) {
  const initialValues = {
    name: edit?.name || "",
    description: edit?.description|| "",
    device_id: id,
    file: edit?.document_type_id || [] as File[],
  };
  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .max(60, "Character limit is 60")
      .required(" Please enter a template name."),
    description: yup.string().max(400, "Character limit is 400"),
    file: yup.mixed().required("File is required"),
  });
  const onSubmit = (values: any) => {
    console.log(values);

    const formData = new FormData();
    formData.append("device_id", id)
    formData.append("name", values.name)
    formData.append("description", values.description)
    formData.append("file", values.file[0]);
    confirmModalHandler(formData);
  };
  const onUpdate = (values: any) => {
    console.log("update")
    const formData = new FormData();
    formData.append("id", edit.id)
    formData.append("name", values.name)
    formData.append("description", values.description ?? "")
    formData.append("file", values.file[0]);
    confirmModalHandler(formData);
  }
  return (
    <div className="bg-[#EEEEEE] rounded-2xl">
      <Formik
        initialValues={initialValues}
        onSubmit={edit !== null ? onUpdate : onSubmit}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <Form className="p-6 space-y-[30px]" action="#">
            <div className="">
              <div className="grid grid-cols-2 gap-x-4 gap-y-2.5   ">
                <label className="m-auto">Name</label>
                <Field
                  style={
                    formik.errors.name && formik.touched.name
                      ? { borderBottom: "2px solid #E61E50" }
                      : {}
                  }
                  type="text"
                  name="name"
                  placeholder="name"
                  maxlength="60"
                  className="inputField focus-all normal-case"
                />
                <label className="m-auto">Description</label>
                <Field
                  style={
                    formik.errors.description && formik.touched.description
                      ? { borderBottom: "2px solid #E61E50" }
                      : {}
                  }
                  type="text"
                  name="description"
                  placeholder="description"
                  maxlength="60"
                  className="inputField focus-all normal-case"
                />
                <label className="m-auto">File</label>
                <label
                  htmlFor="file"
                  className={`rounded-2xl flex  w-full h-10  bg-white `}
                >
                  <p className="m-auto text-[#060606] opacity-50">
                    {formik.values.file.length > 0
                      ? formik.values.file[0]?.name
                      : "Upload here- Drag and Drop"}
                  </p>
                </label>
                <input
                  id="file"
                  name="file"
                  type="file"
                  className="sr-only"
                  onChange={(e) => formik.setFieldValue("file", e.target.files)}
                />
              </div>
              <h2 className="flex justify-between   rounded-md mt-10 w-full">
                <div className="my-auto">
                  <p className="  text-[14px] text-black my-auto "></p>
                </div>
                <div className=" flex gap-3 ">
                  <button
                    type="button"
                    onClick={() => closeModalHandler()}
                    className="rounded-2xl bg-secondary px-3 py-[5px] flex text-white "
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="rounded-2xl bg-white px-3 py-[5px] flex"
                  >
                    <span className="flex gap-2.5 whitespace-nowrap">
                      <IoMdAdd className="h-6 w-6 bg-primary text-white p-[5px] rounded-full" />
                      Submit
                    </span>
                  </button>
                </div>
              </h2>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AddDocumentModalBody;
