import "../assets/css/modal.css";
import Modal from "react-modal";
import { IoMdClose } from "react-icons/io";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 0,
    width: "60%",
    maxHeight: "472px",
    zIndex: 10000,
  },
};
function ModalComponent(props: any) {
  const { closeModal, showModal, header, mainBody, footer } = props;

  const afterOpenModal = () => {};

  return (
    <Modal
      isOpen={showModal}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      ariaHideApp={false}
      contentLabel="Example Modal"
      // disableScrollLock={true}
    >
      <div className="sticky top-0 z-[99999]">
        <h2 className="  py-2 px-4 bg-[#F8F8FC]  rounded-t ">
          <div className="flex justify-between">
            <h3 className={"md:text-base text-sm"}>{header}</h3>

            <button className="  ml-auto" onClick={closeModal}>
              <IoMdClose className="textblue text-2xl " />
            </button>
          </div>
        </h2>
      </div>
      <div className="bg-[#EEEEEE]">{mainBody}</div>
      {footer}
    </Modal>
  );
}

export default ModalComponent;
