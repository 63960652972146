import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { IoMdAdd } from "react-icons/io";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { getRoleListApi, loginApi } from "../services/authServices";

function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (isAuthenticated()) {
      const role = localStorage.getItem("role");
      initialload(role);
    }
  }, [navigate]);
  const initialValues = {
    userName: "",
    password: "",
  };

  const validationSchema = Yup.object({
    userName: Yup.string().required("Please provide your email"),
    password: Yup.string().required("Please provide your password"),
  });

  const onSubmit = async (values: any) => {
    await loginApi({
      userName: values.userName,
      password: values.password,
    })
      .then((res) => {
        if (res?.["access token"]) {
          notify("Login Successfully");
          localStorage.setItem("token", res?.["access token"]);
          localStorage.setItem("userId", res?.["user id"]);
          const roles: string[] = res.roles.map(
            (item: { role_name: string }) => item.role_name
          );
          localStorage.setItem("role", JSON.stringify(roles));
          initialload(JSON.stringify(roles));
          // const historyRoute = localStorage.getItem("route");
          // navigate(historyRoute || "/smartsurgn/admin");
        } else {
          // Handle invalid login here (e.g., show an error message)
          if (res?.error?.length > 0) {
            notifyFailure(res.error);
          }
          console.log("Invalid login");
        }
      })
      .catch((err) => {
        notifyFailure("Invalid login");
        console.log(err);
      });
  };

  const initialload = async (rolesString: any) => {
    try {
      // Get roles from localStorage
      if (!rolesString) {
        console.error("Roles not found in localStorage.");
        return;
      }

      const roles: string[] = JSON.parse(rolesString);

      // Fetch roles data from the API
      const response = await getRoleListApi(navigate);
      if (response?.status === 403) {
        notifyFailure("Session Expired");
        localStorage.removeItem("token");
        localStorage.removeItem("role");
        return;
      }
      if (!response || !response.data) {
        console.error("Invalid response format.");
        return;
      }

      const formattedData: { name: string }[] = roles?.reduce(
        (acc, roleName) => {
          const role = response.data[roleName];

          if (role) {
            const sections = Object.keys(role)
              .filter((sectionKey) => sectionKey !== "id") // Exclude "id" section
              .map((sectionKey) => ({
                name: sectionKey === "User Access Control" ? "user" : sectionKey,
              }));

            acc.push(...sections);
          } else {
            console.error(`Role "${roleName}" not found in API response.`);
          }
          return acc;
        },
        [] as { name: string }[]
      );

      const uniqueSections = Array.from(
        new Set(formattedData.map((s) => s.name))
      )
        .map((name) => ({
          name,
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
      console.log("ad", uniqueSections);
      const currentPath = window.location.pathname;
      const historyRoute = localStorage.getItem("route");
      console.log("historyRoute", historyRoute)
      if(historyRoute !== null){
        console.log("chat", historyRoute)
        navigate(historyRoute)
      }else {
        console.log("other", historyRoute)
        if (currentPath !== `/smartsurgn/${uniqueSections[0].name}`) {
          navigate(
            `/smartsurgn/${uniqueSections[0].name}`
          );
        }
      }
        // }
      } catch (error) {
      console.error("Error fetching role list:", error);
    }
  };
  
  const notify = (value: any) => {
    toast.success(value);
    // simulate authentication success
  };
  const notifyFailure = (value: string) => {
    toast.error(value.toUpperCase());
    // simulate authentication failure
  };
  return (
    <div className="flex-1 h-screen w-full bg-[#424559]">
      <div className="flex justify-center m-auto h-screen items-center flex-col">
        {/* <div className="text-4xl text-white mb-16">SmartSurgN</div> */}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(formik) => (
            <Form className="flex gap-10 flex-col">
              <div>
                <Field
                  type="text"
                  name="userName"
                  id="userName"
                  placeholder="User Name"
                  autoComplete="false"
                  style={
                    formik.errors.userName
                      ? { borderBottom: "2px solid #E61E50" }
                      : {}
                  }
                  className="inputField min-w-[380px]  h-12"
                />
                <ErrorMessage
                  className="text-red-500"
                  name="userName"
                  component="div"
                />
              </div>
              <div>
                <Field
                  type="password"
                  name="password"
                  autoComplete="false"
                  placeholder="Password"
                  className="inputField min-w-[380px] h-12"
                  style={
                    formik.errors.password
                      ? { borderBottom: "2px solid #E61E50" }
                      : {}
                  }
                />
                <ErrorMessage
                  className="text-red-500"
                  name="password"
                  component="div"
                />
              </div>
              <div className="min-w-[180px]">
                <button className=" rounded-3xl bg-white px-[7px] py-[5px] flex w-1/2 h-12 m-auto">
                  <span className="flex gap-2.5 whitespace-nowrap my-auto">
                    <IoMdAdd className="text-4xl bg-[#E65F2B] text-white p-[5px] rounded-full" />
                    <p className="text-lg my-auto">Login</p>
                  </span>
                </button>
              </div>
              <div className="m-auto text-white">
                <Link to={"#"}>Forget Password ?</Link>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default Login;

function isAuthenticated() {
  // check if the user is authenticated
  return localStorage.getItem("token") !== null;
}
