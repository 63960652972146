import { useNavigate } from "react-router-dom";
import { getRoleListApi } from "./authServices";
import toast from "react-hot-toast";

export const AuthLogic = async (authname: string, navigate: any) => {
  try {
    const rolesString = localStorage.getItem("role");
    // Get roles from localStorage
    if (!rolesString) {
      console.error("Roles not found in localStorage.");
      return;
    }

    const roles: string[] = JSON.parse(rolesString);

    // Fetch roles data from the API
    const response = await getRoleListApi(navigate);
    if (response?.status === 403) {
      // notifyFailure("Session Expired")
      return;
    }
    if (!response || !response.data) {
      console.error("Invalid response format.");
      return;
    }

    const formattedData: { name: string }[] = roles?.reduce((acc, roleName) => {
      const role = response.data[roleName];

      if (role) {
        const sections = Object.keys(role)
          .filter((sectionKey) => sectionKey !== "id") // Exclude "id" section
          .map((sectionKey) => ({
            name: sectionKey === "User Access Control" ? "user" : sectionKey,
          }));

        acc.push(...sections);
      } else {
        console.error(`Role "${roleName}" not found in API response.`);
      }
      return acc;
    }, [] as { name: string }[]);

    // Filter out repeated and unique sections
    const uniqueSections = Array.from(new Set(formattedData.map((s) => s.name)))
      .map((name) => ({
        name,
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
    console.log("ad", uniqueSections);
    const currentPath = window.location.pathname;
    const historyRoute = localStorage.getItem("route");
    if (
      !uniqueSections.some((section) => section.name.toLowerCase() == authname)
    ) {
      if (currentPath !== `/smartsurgn/${authname}`) {
        navigate(`/smartsurgn/${authname}`);
      }
    }
  } catch (error) {
    console.error("Error fetching role list:", error);
  }
};

const notifyFailure = (value: string) => {
  toast.error(value.toUpperCase());
  // simulate authentication failure
};
