import { useEffect, useState } from "react";
import {
  Field,
  FieldArray,
  FieldProps,
  Form,
  Formik,
  FormikHelpers,
} from "formik";
import React from "react";
import * as yup from "yup";
import Select from "react-select";
import { IoMdAdd } from "react-icons/io";
import "../../assets/css/reactSelect.css";
import { BiTrashAlt } from "react-icons/bi";
import { sectionsApi } from "../../services/authServices";

interface TabOption {
  value: number;
  label: string;
}

interface SectionOption {
  id: number;
  name: string;
  tabs: TabOption[];
}
interface SubSection {
  sub_section: string;
  ID: number;
}
interface ApiData {
  [key: string]: SubSection[];
}

function CreateRoleModalBody(
  confirmModalHandler: any,
  closeModalHandler: any,
  editValue: any
) {
  const [options, setOptions] = useState<SectionOption[]>([]);
  const [tabOptions, setTabOptions] = useState<SectionOption[]>([]);

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const apiData: ApiData = await sectionsApi();

      const uniqueSections = Object.keys(apiData);

      const newOptions: SectionOption[] = uniqueSections.map(
        (section, index) => ({
          id: index + 1,
          name: section,
          tabs: apiData[section].map((tab, tabIndex) => ({
            value: tab.ID,
            label: tab.sub_section,
          })),
        })
      );

      // Ensure that sections with no tabs have an empty array in tabOptions
      uniqueSections.forEach((section) => {
        if (!apiData[section].length) {
          const emptySection: SectionOption = {
            id: newOptions.find((opt) => opt.name === section)?.id || 0,
            name: section,
            tabs: [],
          };
          newOptions.push(emptySection);
        }
      });

      setOptions(newOptions);
      setTabOptions(newOptions);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const initialValues = {
    id: editValue?.id || 0,
    name: editValue?.name || "",
    sections: editValue?.sections
      ? editValue?.sections?.map((section: any) => ({
          section:
            options?.filter((item) => item?.name == section?.name)[0]?.id || "",
          tabs: section?.tabs?.map((tab: any) => ({
            label: tab?.name,
            value: options
              .filter((item) => item?.name == section?.name)[0]
              ?.tabs?.filter((value) => value?.label == tab?.name)[0]?.value,
          })),
        }))
      : [
          {
            name: "",
            tabs: [] as TabOption[],
          },
        ],
  };

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .max(60, "Character limit is 60")
      .required(" Please enter a template name. "),
    sections: yup.array().of(
      yup.object().shape({
        section: yup.string().required("Please select a section."),
        tabs: yup.array().of(
          yup.object().shape({
            label: yup.string().required("Please select a tab label."),
            value: yup.string().required("Please select a tab value."),
          })
        ),
      })
    ),
  });

  const onSubmit = (values: any, { resetForm }: FormikHelpers<any>) => {
    console.log("initial", values);
    // Extracting role_name from form values
    const roleName = values.name;

    // Extracting section_subsection_map_id with only tab IDs from form values
    const sectionSubsectionMapId = values.sections?.flatMap((section: any) =>
      section.tabs?.map((tab: TabOption) => tab.value)
    );
    if (values.id == 0) {
      const edit = false;
      const transformedData = {
        role_name: roleName,
        section_subsection_map_id: sectionSubsectionMapId || [],
      };
      confirmModalHandler(transformedData, edit);
    } else if (values.id > 0) {
      const edit = true;
      const transformedData = {
        id: values.id,
        role_name: roleName,
        section_subsection_map_id: sectionSubsectionMapId || [],
      };
      confirmModalHandler(transformedData, edit);
    }

    // values.sections = formattedSections;

    resetForm();
  };

  const clearTabData = (formik: any, index: number) => {
    formik.setFieldValue(`sections.${index}.tabs`, []);
  };
  return (
    <div className="bg-[#EEEEEE] rounded-2xl">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {(formik) => (
          <Form className="p-6 space-y-[30px]" action="#">
            <div className="">
              <div className="grid grid-cols-2 gap-x-4 gap-y-2.5   ">
                <label className="m-auto">Name</label>
                <Field
                  style={
                    formik.errors.name && formik.touched.name
                      ? { borderBottom: "2px solid #E61E50" }
                      : {}
                  }
                  type="text"
                  name="name"
                  placeholder="name"
                  maxlength="60"
                  className="inputField focus-all normal-case"
                />
                <FieldArray name={`sections`}>
                  {({ remove, push }) => (
                    <>
                      {console.log(formik.values)}
                      {formik.values.sections.length > 0 &&
                        formik.values.sections?.map(
                          (sections: any, index: any) => (
                            <>
                              <label className="m-auto">
                                Sections {index + 1}
                              </label>
                              <Field
                                as="select"
                                name={`sections.${index}.section`}
                                className="inputField"
                                placeholder="Section"
                              >
                                <option value=""></option>
                                {options
                                  .filter(
                                    (item) =>
                                      !formik.values.sections
                                        ?.slice(0, index)
                                        ?.map((s: any) => s.section.toString()) // Convert to string
                                        .includes(item.id.toString()) // Convert to string
                                  )
                                  ?.map((item) => (
                                    <option value={item.id}>{item.name}</option>
                                  ))}
                              </Field>

                              {formik?.values?.sections[index]?.section >=
                                "0" && <label className="m-auto">Tabs</label>}
                              <div className="flex col-start-2 gap-10">
                                {formik?.values?.sections[index]?.section >=
                                  "0" && (
                                  <Field name={`sections.${index}.tabs`}>
                                    {({ form, field }: FieldProps) => {
                                      const { setFieldValue } = form;
                                      const { value } = field;
                                      const handleChange = (
                                        selectedOptions: any
                                      ) => {
                                        // Use selectedOptions directly for multi-select
                                        setFieldValue(
                                          `sections.${index}.tabs`,
                                          selectedOptions
                                        );
                                      };
                                      console.log("value", value);
                                      return (
                                        <Select
                                          id="role"
                                          {...field}
                                          isMulti={true} // Add isMulti prop here if it's intended to be multi-select
                                          placeholder="tabs"
                                          value={value}
                                          options={
                                            tabOptions.filter(
                                              (item: any) =>
                                                item.id ==
                                                formik.values.sections[index]
                                                  .section
                                            )[0].tabs
                                          }
                                          onChange={handleChange}
                                          className="basic-multi-select w-full"
                                          classNamePrefix="select"
                                        />
                                      );
                                    }}
                                  </Field>
                                )}
                                <BiTrashAlt
                                  className="text-lg cursor-pointer col-start-2 ml-auto my-auto text-red-500"
                                  onClick={() => {
                                    remove(index);
                                    clearTabData(formik, index);
                                  }}
                                />
                              </div>
                            </>
                          )
                        )}
                      <div className="m-auto col-start-2">
                        <button
                          type="button"
                          className="text-primary bg-white px-3 py-[5px] rounded-2xl"
                          onClick={() => push({})}
                        >
                          Add section
                        </button>
                      </div>
                    </>
                  )}
                </FieldArray>
              </div>
              <h2 className="flex justify-between   rounded-md mt-10 w-full">
                <div className="my-auto">
                  <p className="  text-[14px] text-black my-auto "></p>
                </div>
                <div className=" flex gap-3 ">
                  <button
                    type="button"
                    onClick={() => closeModalHandler()}
                    className="rounded-2xl bg-secondary px-3 py-[5px] flex text-white "
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="rounded-2xl bg-white px-3 py-[5px] flex"
                  >
                    <span className="flex gap-2.5 whitespace-nowrap">
                      <IoMdAdd className="h-6 w-6 bg-primary text-white p-[5px] rounded-full" />
                      Submit
                    </span>
                  </button>
                </div>
              </h2>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default CreateRoleModalBody;
