import { PropsWithChildren, createContext, useContext, useState } from "react";

export interface AppData {
  applicationId: number;
  applicationName: string;
}

export type ContextType = {
  application: AppData[] | undefined;
  setApplication: (application: AppData[]) => void;
};
export const ApplicationContext = createContext<ContextType | undefined>(
  undefined
);

export const AppContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const [application, setApplication] = useState<ContextType["application"]>(
    []
  );

  return (
    <ApplicationContext.Provider value={{ application, setApplication }}>
      {children}
    </ApplicationContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(ApplicationContext);

  if (!context) {
    throw new Error("useAppContext must be used inside the AppContextProvider");
  }

  return context;
};
