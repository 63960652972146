import * as React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import botlogo from "./assets/images/chatbot.png";
import { themeLight } from "./styles/theme";
import { MiniDrawer } from "./dashboard/sidebar";
import { AppContextProvider } from "./data/context";
import ChatSheet from "./chatSection/ChatSheet";
import Login from "./auth/login";
import { ProtectedRoute } from "./auth/protectedRoute";
import NotFound from "./notFound";
import AdminComponent from "./components/admin/adminComponent";
import DevicesComponent from "./components/devices/devicesComponent";
import AnalysisComponent from "./components/analysis/analysisComponent";
import AddDeviceComponent from "./components/admin/addDevice/addDeviceComponent";
import UserComponent from "./components/user/userComponent";
import ChatSection from "./chatSection/ChatSection";
import ChatPageOnly from "./chatSection/ChatPageOnly";
import toast, { Toaster } from "react-hot-toast";
import { getRoleListApi } from "./services/authServices";
import UploadFileDevice from "./components/admin/addDevice/uploadFileDevice";
import Searchlist from "./components/devices/deviceDahborad/searchlist";
import SelectGuidelines from "./components/admin/addDevice/selectGuidelines";
import Stepper from "./components/stepper/stepper";

function App() {
  document.title = "SmartSurgN";

  return (
    <ThemeProvider theme={themeLight}>
      <AppContextProvider>
        <Router>
          {" "}
          {/* Make sure Router wraps your entire app */}
          <CssBaseline />
          <Routes>
            {/* <Route path="/login" element={<Login />} /> */}
            <Route
              path="/*"
              element={
                <MiniDrawer>
                  <Box
                    sx={{
                      marginRight: "1px",
                      marginTop: "10px",
                    }}
                  >
                    <Routes>
                      {/* <Route index path="/" element={<Login />} /> */}
                      <Route index path="/login"  element={<Login />} />
                      <Route path="*" element={<Login />} />
                      <Route element={<ProtectedRoute />}>
                        <Route
                          index
                          path="smartsurgn/admin"
                          element={<AdminComponent />}
                        />
                        <Route
                          path="smartsurgn/devices"
                          element={<DevicesComponent />}
                        />
                        <Route
                          path="smartsurgn/analysis"
                          element={<AnalysisComponent />}
                        />
                        <Route
                          path="smartsurgn/deviceDashboard"
                          element={<Searchlist />}
                        />
                        <Route
                          path="smartsurgn/user"
                          element={<UserComponent />}
                        />
                        <Route element={<Stepper />}>

                        <Route
                          path="smartsurgn/devices/addDevice"
                          element={<AddDeviceComponent />}
                          />
                        <Route
                          path="smartsurgn/devices/addDevice/selectGuidelines/:id"
                          element={<SelectGuidelines />}
                          />
                        <Route
                          path="smartsurgn/devices/addDevice/uploadDocument/:id"
                          element={<UploadFileDevice />}
                          />
                          </Route>
                      </Route>
                    </Routes>
                    <ChatSections />
                  </Box>
                </MiniDrawer>
              }
            />
            <Route element={<ProtectedRoute />}>
              <Route path="/smartsurgn/Chat" element={<ChatPageOnly />} />
            </Route>
              <Route path="/smartsurgn/demochat" element={<ChatPageOnly />} />
          </Routes>
        </Router>
      </AppContextProvider>
      <Toaster
        containerStyle={{
          margin: "20px 0 0 - 250px",
          padding: "0 16px",
          height: "40px",
          zIndex: 9999999,
        }}
        toastOptions={{
          success: {
            style: {
              background: "#149B5F",
              padding: "16px",
              color: "#fff",
              width: "500vw",
            },
          },
          error: {
            style: {
              background: "#E61E50",
              padding: "16px",
              color: "#fff",
              width: "500vw",
            },
          },
        }}
      />
    </ThemeProvider>
  );
}
const ChatSections = () => {
  const [open, setOpen] = React.useState(false);
  const [showChat, setShowChat] = React.useState(false);
  const navigation = useNavigate();
  const location = useLocation()
  let [searchParams, setSearchParams] = useSearchParams();

  React.useEffect(() => {
    if (isAuthenticated()) {
      initialAuth();
    } else {
      setShowChat(false);
    }
  }, [navigation]);

  React.useEffect(() => {
    if (searchParams.get("chatBar") !== null) {
      setOpen(searchParams.get("chatBar") === "true" ? true : false);
    }
  }, [searchParams]);
  const notifyFailure = (value: string) => {
    toast.error(value.toUpperCase());
    // simulate authentication failure
  };

  const initialAuth = async () => {
    try {
      const rolesString = localStorage.getItem("role");
      // Get roles from localStorage
      if (!rolesString) {
        console.error("Roles not found in localStorage.");
        return;
      }

      const roles: string[] = JSON.parse(rolesString);

      // Fetch roles data from the API
      const response = await getRoleListApi(navigation);
      if(response?.status === 403){
        // notifyFailure("Session Expired")
        setShowChat(false);
        return;
      }
      if (!response || !response.data) {
        console.error("Invalid response format.");
        return;
      }
      
     
      const formattedData: { name: string }[] = roles.reduce(
        (acc, roleName) => {
          const role = response.data[roleName];

          if (role) {
            const sections = Object.keys(role)
              .filter((sectionKey) => sectionKey !== "id") // Exclude "id" section
              .map((sectionKey) => ({
                name: sectionKey,
              }));

            acc.push(...sections);
          } else {
            console.error(`Role "${roleName}" not found in API response.`);
          }
          return acc;
        },
        [] as { name: string }[]
      );

      // Filter out repeated and unique sections
      const uniqueSections = Array.from(
        new Set(formattedData.map((s) => s.name))
      ).map((name) => ({
        name,
      }));
      if (
        uniqueSections.some((section) => section.name.toLowerCase() == "chat")
      ) {
        setShowChat(true);
      }
      console.log();
    } catch (error) {
      console.error("Error fetching role list:", error);
    }
  };

  const chatHandler = () => {
    setOpen(true);
    searchParams.set("chatBar", "true");
    setSearchParams(searchParams);
  };
  return (
    <>
      {showChat ? (
        <div
          className="bg-blue-400 fixed right-7 bottom-[10%] rounded-full cursor-pointer flex p-4"
          onClick={() => chatHandler()}
        >
          <img src={botlogo} className="h-10 w-10 m-auto" />
        </div>
      ) : null}

      <ChatSheet open={open} setOpen={setOpen} />
    </>
  );
};

export default App;

function isAuthenticated() {
  // check if the user is authenticated
  return localStorage.getItem("token") !== null;
}
