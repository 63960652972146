import moment from "moment";
import React, { useEffect, useRef, useState } from "react";

interface TimeLineProps {
  fileData: {
    id: number;
    task_name: string;
    users: any;
    task_date: string;
    document_name: string;
    summary: string;
    offset?: number;
  }[];
}

const Timeline: React.FC<TimeLineProps> = ({ fileData }) => {
  // Calculate start and end dates
  const startDate = moment(fileData[0].task_date);
  const endDate = startDate.clone().add(5, "years");
  const lastElementRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [progressWidth, setProgressWidth] = useState(0);

  useEffect(() => {
    if (lastElementRef.current && containerRef.current) {
      const rect = lastElementRef.current.getBoundingClientRect();
      const containerRect = containerRef.current.getBoundingClientRect();
      const newProgressWidth = rect.right - containerRect.left - 90;

      if (newProgressWidth < containerRect.width) {
        setProgressWidth(newProgressWidth);
      } else {
        setProgressWidth(containerRect.width);
      }
    }
  }, []);

  const styles: { [key: string]: React.CSSProperties } = {
    timeline: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      position: "relative",
      marginTop: "20px",
      width: "100%",
      backgroundColor: "#f0f0f0", // Light gray background
      padding: "10px",
      borderRadius: "10px",
    },
    timelineLine: {
      position: "absolute",
      left: 0,
      right: 0,
      borderRadius: "6px",
      top: "50%",
      height: 6,
      backgroundColor: "gray", // Base color for the timeline
    },
    progressLine: {
      position: "absolute",
      left: 0,
      top: "50%",
      borderRadius: "6px",
      height: 6,
      background: "linear-gradient(to right, #FF4B2B, #FF416C)", // Gradient color for the progress part
      width: `${progressWidth}px`,
    },
    timelineElement: {
      position: "relative",
      padding: "10px",
      borderRadius: "5px",
      marginRight: "10px",
      backgroundColor: "#32768B",
      color: "#fff",
      width: "max-content",
    },
    oddElement: {
      backgroundColor: "rgb(0, 169, 47)",
      top: "60%",
    },
    evenElement: {
      bottom: "-50px",
    },
    arrow: {
      position: "absolute",
      bottom: "-30px",
      right: "50%",
      width: "15px",
      height: "15px",
      borderRadius: "13%",
      backgroundColor: "#32768B",
      transform: "translate(0%, -170%) rotate(45deg)",
    },
    arrowLeft: {
      top: "-10px",
      left: "50%",
      backgroundColor: "rgb(0, 169, 47)",
      transform: "translate(0%, 40%) rotate(45deg)",
    },
    content: {
      marginLeft: "10px",
    },
    taskName: {
      fontSize: "12px",
      fontWeight: "bold",
      marginBottom: "5px",
    },
    otherText: {
      fontSize: "10px",
      marginBottom: "2px",
    },
    dateText: {
      position: "absolute",
      fontSize: "10px",
      top: "55%",
      transform: "translateY(-50%)",
    },
    taskDate: {
      position: "absolute",
      fontSize: "10px",
      top: "18%",
      left: "35%",
    },
  };

  return (
    <div className="flex min-h-[350px] relative">
      <div style={styles.timeline} ref={containerRef}>
        <div className="w-screen h-full flex overflow-y-auto ">
          <div
            style={{ ...styles.dateText, left: 0 }}
            className="px-1 py-0.5 bg-red-500 text-white rounded-xl"
          >
            {startDate.format("DD/MM/YYYY")}
          </div>
          <div style={styles.timelineLine} />
          <div style={styles.progressLine} />
          <div
            style={{ ...styles.dateText, right: 0 }}
            className="px-1 py-0.5 bg-green-500 text-white rounded-xl"
          >
            {endDate.format("DD/MM/YYYY")}
          </div>
          {fileData?.map((item, index) => (
            <div
              key={index}
              className="relative"
              ref={index === fileData.length - 1 ? lastElementRef : null}
            >
              {!(index % 2 !== 0) ? (
                <div
                  style={{
                    ...styles.taskDate,
                    top: "calc(60% - 10px)",
                  }}
                >
                  {moment(item.task_date).format("DD/MM/YYYY")}
                </div>
              ) : null}
              <div
                style={{
                  ...styles.timelineElement,
                  ...(index % 2 !== 0 ? styles.oddElement : styles.evenElement),
                }}
              >
                <div
                  style={
                    index % 2 === 0
                      ? styles.arrow
                      : { ...styles.arrow, ...styles.arrowLeft }
                  }
                />
                <div style={styles.content}>
                  <div style={styles.taskName}>{item.task_name}</div>
                  <div style={styles.otherText}>Document: {item.task_name}</div>
                  {Array.isArray(item.users) ? (
                    item.users.map((user: any, idx: any) => (
                      <div key={idx} style={styles.otherText}>
                        User: {user}
                      </div>
                    ))
                  ) : (
                    <div style={styles.otherText}>
                      User: {item.users.users[0]}
                    </div>
                  )}
                  {/* <div style={styles.otherText}>Summary: {item.summary}</div> */}
                </div>
              </div>
              {index % 2 !== 0 ? (
                <div
                  style={{
                    ...styles.taskDate,
                    top: "calc(43% - 10px)",
                  }}
                >
                  {moment(item.task_date).format("DD/MM/YYYY")}
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Timeline;
