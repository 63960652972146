import React, { useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
import RiskManagement from "./components/riskManagement";
import moment from "moment";
import { Box, CircularProgress } from "@mui/material";
import DeltaComparision from "./components/deltaComparision";
import DeviceDashboard from "./components/deviceDashboard/deviceDashboard";
import { getRoleListApi } from "../../services/authServices";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { AuthLogic } from "../../services/authlogic";
import { AuthTabs } from "../../services/authtabs";
import CertificationDetails from "./components/certificationDetails";
import { FaArrowLeftLong } from "react-icons/fa6";
import { get_device_by_Id, upsert_device } from "../../services/adminService";
import CertificateDetails from "./components/certificateDetails/certificateDetails";
import toast from "react-hot-toast";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

interface Data {
  name: string;
}

type ChecklistItem = {
  name: string;
  status: boolean;
};

type ChecklistCategory = {
  description: string;
  items: ChecklistItem[];
};

type ChecklistDataType = {
  [category: string]: {
    description: string;
    items: ChecklistItem[];
  };
};

interface DeviceData {
  id: number;
  name: string;
  country_id: number;
  updated_by: string;
  description: string | null;
  guideline_checklist: {
    data: {
      name: string;
      id: number;
      version: number;
      checklist: ChecklistDataType;
    }[];
  };
  meta_data: {
    CompanyCode: string;
    DeviceIdentifier: string;
    Submitter: string;
    primaryCorrespondent: string;
    productClass: string;
    Panel: string;
    IntentedUse: string;
    Certifications: string;
    class: string;
    // Add other properties of meta_data if needed
  };
}

function AnalysisComponent() {
  const statuscode = [
    {
      id: 0,
      primary: "#EE201C",
      secondary: "#E2BDBC",
    },
    {
      id: 1,
      primary: "#E65F2B",
      secondary: "#E0C8BF",
    },
    {
      id: 2,
      primary: "#DFA510",
      secondary: "#E0D7C0",
    },
    {
      id: 3,
      primary: "#1A932E",
      secondary: "#BCD1BF",
    },
  ];
  const files = [
    {
      deviceName: "SSN ENDOSCOPY",
      productCode: "OWN",
      uploadedBy: "Jocelyn Long",
      duedate: "12/10/2024",
      Status: "In Progress",
      process: 10,
      processId: 3,
      probability: 5,
      probabilityId: 1,
      status: "In Progress",
      statusId: 3,
      Severity: 4,
      SeverityId: 2,
      overall: "Low Risk",
      overallId: 3,
      summary: `The new SSN Endoscopy device platform is a comprehensive surgical visualization solution for use in multiple surgical specialties. Platform includes OLED monitor, Fluorescence imaging, SSN camera, Portable Handheld Imager, OR Hub connection, Insufflator, Light source, cart, printer`,
    },
  ];

  const tabList = [
    "Risk Management",
    "Documents",
    "Certification Details",
    "Delta Comparisons",
  ];

  const [sections, setSections] = useState<Data[]>([]);
  const [data, setData] = useState<DeviceData>();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    initialload();
    initialAuth();
    localStorage.removeItem("route");
    handleGetDataEdit();
  }, []);

  const handleGetDataEdit = async () => {
    try {
      const response = await get_device_by_Id(searchParams.get("deviceId"));
      if (response.success === true) {
        setData(response.data?.entity_info[0]);
      } else {
        // Handle error scenario
        console.error("Failed to fetch device data");
      }
    } catch (error) {
      console.error("Error fetching device data:", error);
    }
  };

  const initialAuth = async () => {
    AuthLogic("device dashboard", navigate);
  };

  const initialload = async () => {
    const data: Data[] = (await AuthTabs(navigate)) as Data[];
    setSections(data);
  };

  const handleClick = (index: any) => {
    searchParams.set("tab", index);
    setSearchParams(searchParams);
  };

  const handleCheckboxChange = (checklistId: number, category: string, itemName: string) => {
    setData((prevData) => {
      if (!prevData) return prevData;

      const updatedData = prevData.guideline_checklist.data.map((checklist) => {
        if (checklist.id !== checklistId) return checklist;

        const updatedChecklist = {
          ...checklist,
          checklist: {
            ...checklist.checklist,
            [category]: {
              ...checklist.checklist[category],
              items: checklist.checklist[category].items.map((item) =>
                item.name === itemName ? { ...item, status: !item.status } : item
              ),
            },
          },
        };

        return updatedChecklist;
      });

      return {
        ...prevData,
        guideline_checklist: {
          data: updatedData,
        },
      };
    });
  };

  const handleSubmit = async () => {
    if (data) {
      const id = searchParams.get("deviceId")
      if(id === null){
        return 
      }
      const formdata = new FormData()
      formdata.append("id", id)
      formdata.append("name", data.name)
      formdata.append("country_id", data.country_id.toString())
      formdata.append("description", data.description ?? "")
      formdata.append("meta_data", data.meta_data !== null ? JSON.stringify(data.meta_data) : "")
      formdata.append("guideline_checklist", JSON.stringify(data.guideline_checklist))

      try {
        const postData = await upsert_device(formdata);
        if(postData.success){
          toast.success(postData.message)
          handleGetDataEdit()
        }
      } catch (error) {
        console.error("Error submitting data:", error);
      }
    }
  };

  return (
    <div>
      <div className="flex  mt-5 ml-5">
        <Link
          to="/smartsurgn/deviceDashboard"
          className="text-primary flex gap-1"
        >
          {" "}
          <FaArrowLeftLong className="my-auto" /> Go Back
        </Link>
      </div>
      <div className="w-full  px-2 py-16 sm:px-0">
        <div className="bg-[#DFDFDF] p-5 rounded-2xl mb-10">
          <div className="flex justify-between gap-36">
            <label className="text-[#060606] font-bold whitespace-nowrap my-auto">
              Device Wise Summary
            </label>
            {/* <div className="flex gap-2 w-full">
              <select
                name="uploadedby"
                id="uploadedby"
                className="inputField w-full "
              >
                <option value="">Uploaded by</option>
              </select>
              <select
                name="dueDate"
                id="dueDate"
                className="inputField w-full "
              >
                <option value="">Due Date</option>
              </select>
              <select name="Status" id="Status" className="inputField w-full ">
                <option value="">Status</option>
              </select>
            </div> */}
          </div>
          <div>
          <table className="w-full my-6 text-sm">
              <thead>
                <tr>
                  <th className="pb-2.5 text-start">Device Name</th>
                  {/* <th className="pb-2.5 text-center">Product Code</th> */}
                  {/* <th className="pb-2.5 text-center">Uploaded By</th> */}
                  {/* <th className="pb-2.5 text-center">Due date</th> */}
                  <th className="pb-2.5 text-center">Updated By</th>
                  <th className="pb-2.5 text-center">Status</th>
                  <th className="pb-2.5 text-center">Progress</th>
                  <th className="pb-2.5 text-center">Probability</th>
                  <th className="pb-2.5 text-center">Severity</th>
                  <th className="pb-2.5 text-end">Overall</th>
                </tr>
              </thead>
              <tbody>
                {files?.map((file, index) => (
                  <>
                    <tr
                      key={index}
                      className="hover:font-bold hover:text-blue-800"
                    >
                      <td className="text-start font-bold">{data?.name}</td>
                      <td className="py-2.5 text-center">{data?.updated_by}</td>
                      {/* <td className="text-center">{data?.name}</td> */}
                      {/* <td className="text-center">{}</td> */}
                      {/* <td className="text-center">
                        {moment(file.duedate).format("DD/MM/YYYY")}
                      </td> */}
                      <td className="text-center flex justify-center ">
                        <div
                          className="flex justify-center items-center m-1 font-medium py-1 px-2 text-white rounded-full w-fit opac"
                          style={{
                            background: statuscode.filter(
                              (item) => item.id === file.statusId
                            )[0].secondary,
                          }}
                        >
                          <div
                            className="text-xs font-normal leading-none flex-initial "
                            style={{
                              color: statuscode.filter(
                                (item) => item.id === file.statusId
                              )[0].primary,
                            }}
                          >
                            {file?.status}
                          </div>
                        </div>
                      </td>
                      <td className="text-center">
                        <Box
                          sx={{ position: "relative", display: "inline-flex" }}
                        >
                          <CircularProgress
                            variant="determinate"
                            value={file.process}
                            style={{
                              color: statuscode.filter(
                                (item) => item.id === file.processId
                              )[0].primary,
                            }}
                            thickness={5}
                            size={32}
                            sx={{
                              colorSecondary: () => "black",
                            }}
                          />
                          <div
                            style={{
                              top: 0,
                              left: 0,
                              bottom: 0,
                              right: 0,
                              position: "absolute",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div className="text-[9px]">{file.process}%</div>
                          </div>
                        </Box>
                      </td>
                      <td className="text-center ">
                        <div className="flex justify-center ">
                          <div
                            className="flex justify-center items-center m-1 font-medium py-1 px-2 text-white rounded-full w-fit opac"
                            style={{
                              background: statuscode.filter(
                                (item) => item.id === file.probabilityId
                              )[0].secondary,
                            }}
                          >
                            <div
                              className="text-xs font-normal leading-none flex-initial "
                              style={{
                                color: statuscode.filter(
                                  (item) => item.id === file.probabilityId
                                )[0].primary,
                              }}
                            >
                              Probability ( {file?.probability} )
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="text-center ">
                        <div className="flex justify-center ">
                          <div
                            className="flex justify-center items-center m-1 font-medium py-1 px-2 text-white rounded-full w-fit opac"
                            style={{
                              background: statuscode.filter(
                                (item) => item.id === file.SeverityId
                              )[0].secondary,
                            }}
                          >
                            <div
                              className="text-xs font-normal leading-none flex-initial "
                              style={{
                                color: statuscode.filter(
                                  (item) => item.id === file.SeverityId
                                )[0].primary,
                              }}
                            >
                              Probability ( {file?.Severity} )
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="text-end">
                        <div className="flex justify-end ">
                          <div
                            className="flex justify-center items-center m-1 font-medium py-1 px-2 text-white rounded-full w-fit opac"
                            style={{
                              background: statuscode.filter(
                                (item) => item.id === file.overallId
                              )[0].secondary,
                            }}
                          >
                            <div
                              className="text-xs font-normal leading-none flex-initial "
                              style={{
                                color: statuscode.filter(
                                  (item) => item.id === file.overallId
                                )[0].primary,
                              }}
                            >
                              {file?.overall}
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    {/* {searchParams.get("deviceId") == "1" && (
                      <tr>
                        <td colSpan={8}>{file.summary}</td>
                      </tr>
                    )} */}
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <CertificationDetails />
        <Tab.Group
          selectedIndex={
            searchParams.get("tab") === "1"
              ? 1
              : searchParams.get("tab") === "2"
              ? 2
              : searchParams.get("tab") === "3"
              ? 3
              : 0
          }
        >
          <Tab.List className="flex space-x-1 rounded-3xl w-2/3  p-1 bg-[#CACACA]">
            {tabList.map((category, index) => {
              const sectionExists = sections?.some(
                (section) => section.name === category
              );

              return sectionExists ? (
                <Tab
                  key={category}
                  onClick={() => handleClick(index)}
                  className={({ selected }) =>
                    classNames(
                      "w-full rounded-3xl py-2.5 text-sm font-medium leading-5 focus:outline-none",
                      selected
                        ? "bg-secondary shadow text-white font-semibold"
                        : "text-black"
                    )
                  }
                >
                  {category}
                </Tab>
              ) : null;
            })}
          </Tab.List>
          <Tab.Panels className="bg-white p-5 mt-3 rounded-lg">
            {sections?.some(
              (section) => section.name === "Risk Management"
            ) && (
              <Tab.Panel>
                <RiskManagement />
              </Tab.Panel>
            )}
            {sections?.some((section) => section.name === "Documents") && (
              <Tab.Panel>
                <DeviceDashboard />
              </Tab.Panel>
            )}
            {sections?.some(
              (section) => section.name === "Certification Details"
            ) && (
              <Tab.Panel>
                <div>
                <CertificateDetails
                  checklistData={data?.guideline_checklist?.data}
                  handleCheckboxChange={handleCheckboxChange}
                  handleSubmit={handleSubmit}
                />
                </div>
              </Tab.Panel>
            )}
            {sections?.some(
              (section) => section.name === "Delta Comparisons"
            ) && (              <Tab.Panel>
                <DeltaComparision />
              </Tab.Panel>  
            )}
          </Tab.Panels>
        </Tab.Group>
       
      </div>
    </div>
  );
}

export default AnalysisComponent;
