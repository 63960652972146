import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

// A custom theme for this app
export const themeLight = createTheme({
  palette: {
    background: {
      default: "#ffffff",
      paper: "#424559",
    },
    primary: {
      main: "#4463C4",
    },
    secondary: {
      // used in AppBar
      main: "#424559",
      contrastText: "#ffffff",
    },
    error: {
      main: red.A400,
    },
    text: {
      primary: "#000000",
      secondary: "#094F48",
    },
  },
});
