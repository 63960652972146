import { ErrorMessage, Field, FieldProps, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import Select from "react-select";
import { IoMdAdd } from "react-icons/io";
import "../../assets/css/reactSelect.css";
import { getRoleListApi } from "../../services/authServices";
import { AuthTabs } from "../../services/authtabs";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

interface Data {
  label: string;
  value: number;
}

interface Section {
  name: string;
}

function CreateUserModalBody(
  confirmModalHandler: any,
  closeModalHandler: any,
  editValue: any,
  edit: any
) {
  useEffect(() => {
    initialload();
  }, []);

  const [options, setoptions] = useState<Data[]>([]);
  const navigate = useNavigate()

  const initialload = async () => {
    try {
      const response = await getRoleListApi(navigate);
      if(response?.status === 403){
        // notifyFailure("Session Expired")
        return;
      }
      if (!response || !response.data) {
        console.error("Invalid response format.");
        return;
      }
      const data: Section[] = (await AuthTabs(navigate)) as Section[];
      console.log(response?.data);
      const formattedData: Data[] = Object.keys(response.data)
        .map((roleKey) => {
          const role = response.data[roleKey];
          const rolename = Object.keys(response.data[roleKey]);

          if (data.some((item) => item?.name === "Role")) {
            return {
              value: role.id,
              label: roleKey,
            };
          } else {
            if (
              rolename.length == 2 &&
              rolename.some((item) => item === "Chat")
            ) {
              return {
                value: role.id,
                label: roleKey,
              };
            }
          }

          return undefined; // Explicitly return undefined for non-matching conditions
        })
        .filter((data): data is Data => data !== undefined); // Filter out undefined values
      console.log(formattedData);
      setoptions(formattedData);
    } catch (error) {
      console.error("Error fetching role list:", error);
    }
  };

  const initialValues = {
    name: editValue?.name || "",
    username: editValue?.username || "",
    password: edit === "null" ? "pass1234" : "",
    newPassword: "",
    confirmNewPassword: "",
    role:
      editValue?.roles_list.map((item: any) => ({
        label: item,
        value: options.filter((data) => data.label == item)[0]?.value,
      })) || [],
  };

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .max(60, "Character limit is 60")
      .required(" Please enter a template name. "),
    username: yup.string().max(400, "Character limit is 400").required(),
    password: yup.string(),
    role: yup.array().of(
      yup.object().shape({
        label: yup.string().required(),
        value: yup.string().required(),
      })
    ),
  });
  const validationSchemaPassword = yup.object().shape({
    name: yup
      .string()
      .max(60, "Character limit is 60")
      .required(" Please enter a template name. "),
    username: yup.string().max(400, "Character limit is 400").required(),
    password: yup.string().required("required"),
    newPassword: yup
      .string()
      .notOneOf(
        [yup.ref("password")],
        "New Password must be different from the old one"
      )
      .required("required"),
    confirmNewPassword: yup
      .string()
      .required("required")
      .oneOf(
        [yup.ref("newPassword")],
        "New Password and Confirm Password must be same"
      ),
    role: yup.array().of(
      yup.object().shape({
        label: yup.string().required(),
        value: yup.string().required(),
      })
    ),
  });
  const validationSchemaUpdate = yup.object().shape({
    name: yup
      .string()
      .max(60, "Character limit is 60")
      .required(" Please enter a template name. "),
    username: yup.string().max(400, "Character limit is 400").required(),
    password: yup.string(),
    role: yup.array().of(
      yup.object().shape({
        label: yup.string().required(),
        value: yup.string().required(),
      })
    ),
  });
  const onSubmit = (value: any) => {
    const selectedRole = value.role?.map((option: any) => option.value);

    value.role = selectedRole;
    if (edit == "null") {
      const paayload = {
        name: value.name,
        userName: value.username,
        password: value.password,
        roleID: selectedRole,
      };
      confirmModalHandler(paayload);
    } else if (edit === "update") {
      const paayload = {
        id: editValue.user_id,
        username: value.username,
        // password: value.password,
        roleID: selectedRole,
      };
      confirmModalHandler(paayload);
    } else if (edit === "password") {
      const paayload = {
        userName: value.username,
        password: value.password,
        newPassword: value.newPassword,
      };
      confirmModalHandler(paayload);
    }
  };
  const notifyFailure = (value: string) => {
    toast.error(value.toUpperCase());
    // simulate authentication failure
  };

  return (
    <div className="bg-[#EEEEEE] rounded-2xl h-full">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={
          edit == "password"
            ? validationSchemaPassword
            : edit == "update"
            ? validationSchemaUpdate
            : validationSchema
        }
      >
        {(formik) => (
          <Form className="p-6 space-y-[30px]" action="#">
            <div className="">
              <div className="grid grid-cols-2 gap-x-4 gap-y-2.5   ">
                <label className="m-auto">Name</label>

                <Field
                  style={
                    formik.errors.name && formik.touched.name
                      ? { borderBottom: "2px solid #E61E50" }
                      : {}
                  }
                  type="text"
                  name="name"
                  placeholder="name"
                  maxlength="60"
                  className="inputField focus-all normal-case"
                  disabled={edit === "password"}
                />
                <label className="m-auto">User Name</label>
                <Field
                  style={
                    formik.errors.username && formik.touched.username
                      ? { borderBottom: "2px solid #E61E50" }
                      : {}
                  }
                  type="text"
                  name="username"
                  disabled={edit === "password"}
                  placeholder="User Name"
                  maxlength="60"
                  className="inputField focus-all normal-case"
                />
                <label className="m-auto">Password</label>
                <Field
                  style={
                    formik.errors.password && formik.touched.password
                      ? { borderBottom: "2px solid #E61E50" }
                      : {}
                  }
                  type="text"
                  name="password"
                  disabled={edit !== "password"}
                  placeholder="password"
                  maxlength="60"
                  className="inputField focus-all normal-case"
                />
                {edit == "password" && (
                  <>
                    <label className="m-auto">New Password</label>
                    <div className="w-full">
                      <Field
                        style={
                          formik.errors.newPassword &&
                          formik.touched.newPassword
                            ? { borderBottom: "2px solid #E61E50" }
                            : {}
                        }
                        type="text"
                        name="newPassword"
                        required
                        placeholder="New Password"
                        maxlength="60"
                        className="inputField focus-all normal-case w-full"
                      />
                      <div className="text-sm text-red-500">
                        <ErrorMessage name="newPassword" />
                      </div>
                    </div>
                    <label className="m-auto">Confirm New Password</label>
                    <div className="w-full">
                      <Field
                        style={
                          formik.errors.confirmNewPassword &&
                          formik.touched.confirmNewPassword
                            ? { borderBottom: "2px solid #E61E50" }
                            : {}
                        }
                        type="text"
                        name="confirmNewPassword"
                        required
                        placeholder="Confirm New Password"
                        maxlength="60"
                        className="inputField focus-all normal-case w-full"
                      />
                      <div className="text-sm text-red-500">
                        <ErrorMessage name="confirmNewPassword" />
                      </div>
                    </div>
                  </>
                )}

                <label className="m-auto">Roles</label>
                <Field name="role">
                  {({ form, field }: FieldProps) => {
                    const { setFieldValue } = form;
                    const { value } = field;

                    const handleChange = (selectedOptions: any) => {
                      // Use selectedOptions directly for multi-select
                      setFieldValue("role", selectedOptions);
                    };

                    return (
                      <Select
                        id="role"
                        isDisabled={editValue == null ? false : true}
                        {...field}
                        isMulti={true} // Add isMulti prop here if it's intended to be multi-select
                        placeholder="Role"
                        value={value}
                        options={options}
                        onChange={handleChange}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    );
                  }}
                </Field>
              </div>
              <h2 className="flex justify-between   rounded-md mt-10 w-full">
                <div className="my-auto">
                  <p className="  text-[14px] text-black my-auto "></p>
                </div>
                <div className=" flex gap-3 ">
                  <button
                    type="button"
                    onClick={() => closeModalHandler()}
                    className="rounded-2xl bg-secondary px-3 py-[5px] flex text-white "
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="rounded-2xl bg-white px-3 py-[5px] flex"
                  >
                    <span className="flex gap-2.5 whitespace-nowrap">
                      <IoMdAdd className="h-6 w-6 bg-primary text-white p-[5px] rounded-full" />
                      Submit
                    </span>
                  </button>
                </div>
              </h2>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default CreateUserModalBody;
