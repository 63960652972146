import React, { useState } from "react";
import { IoMdAdd } from "react-icons/io";
import RelationModalBody from "./relationModalBody";
import toast from "react-hot-toast";
import { insert_relation } from "../../services/adminService";
import ModalComponent from "../../fieldComponent/modalComponent";

function ComponentDeviceRelation() {
  const data = [
    {
      name: "Abc",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorem officiis, dignissimos rerum quisquam corrupti eveniet repellendus harum. Hic quisquam itaque doloribus, rem, nobis accusantium amet laboriosam commodi autem obcaecati esse.",
      country_id: 1,
      device_Id: 2,
    },
    {
      name: "CDE",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorem officiis, dignissimos rerum quisquam corrupti eveniet repellendus harum. Hic quisquam itaque doloribus, rem, nobis accusantium amet laboriosam commodi autem obcaecati esse.",
      country_id: 2,
      device_Id: 3,
    },
    {
      name: "EFG",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorem officiis, dignissimos rerum quisquam corrupti eveniet repellendus harum. Hic quisquam itaque doloribus, rem, nobis accusantium amet laboriosam commodi autem obcaecati esse.",
      country_id: 1,
      device_Id: 3,
    },
  ];
  const [showModal, setshowModal] = useState(false);

  const closeModalHandler = () => {
    setshowModal(false);
  };

  const confirmModalHandler = async (value: any) => {
    const result = await insert_relation(value);

    if (result?.success === 1) {
      setshowModal(false);
      notify("Component Added SuccessFully");
      // fetchComponent();
    } else if (result?.success === 0) {
      setshowModal(false);
      notifyFailure(result?.failed[0].reason);
    }
  };

  const notify = (value: any) => {
    toast.success(value);
    // simulate authentication success
  };
  const notifyFailure = (value: any) => {
    toast.error(value);
    // simulate authentication failure
  };
  return (
    <div>
      <div className="mb-10 flex justify-between">
        <label className="text-[#060606] font-bold text-xl">
          Component Device Relation
        </label>
        <button
          type="button"
          onClick={() => setshowModal(true)}
          className="rounded-2xl bg-white px-[7px] py-[5px] flex"
        >
          <span className="flex gap-2.5 whitespace-nowrap">
            <IoMdAdd className="h-6 w-6 bg-primary text-white p-[5px] rounded-full" />
            Add Component Device Relation
          </span>
        </button>
      </div>
      <ModalComponent
        showModal={showModal}
        closeModal={closeModalHandler}
        header="Add Component Device Relation"
        mainBody={RelationModalBody(confirmModalHandler, closeModalHandler)}
      />
      <div>
        <table className="w-full my-6 text-sm">
          <thead className="border-b-2 border-b-[#E1DFDF]">
            <tr>
              <th className="pb-2.5 text-start">Name</th>
              <th className="pb-2.5 text-center">Description</th>
              <th className="pb-2.5 text-center">Component</th>
              <th className="pb-2.5 text-center">Device</th>
              <th className="pb-2.5 text-end">Country</th>
            </tr>
          </thead>
          <tbody className="">
            {/* {data?.map((item, index) => (
              <>
                <tr
                  key={index}
                  className="hover:font-bold hover:text-blue-800 cursor-pointer"
                >
                  <td className="py-5 text-start">{item.name}</td>
                  <td className="py-5 text-center w-2/3">{item.description}</td>
                  <td className="py-5 text-center ">
                    {
                      deviceOption.filter(
                        (country) => country.value === item.device_Id
                      )[0]?.label
                    }
                  </td>
                  <td className="py-5 text-center">
                    {
                      ComponentOption.filter(
                        (country) => country.value === item.device_Id
                      )[0]?.label
                    }
                  </td>
                  <td className="py-5 text-end">
                    {
                      serviceOption.filter(
                        (country) => country.value === item.country_id
                      )[0]?.label
                    }
                  </td>
                </tr>
              </>
            ))} */}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ComponentDeviceRelation;

const serviceOption = [
  { value: 1, label: "US" },
  { value: 2, label: "Canada" },
  { value: 3, label: "China" },
  { value: 4, label: "India" },
  { value: 5, label: "Malaysia" },
];
const deviceOption = [
  { value: 1, label: "A" },
  { value: 2, label: "B" },
  { value: 3, label: "C" },
  { value: 4, label: "D" },
];
const ComponentOption = [
  { value: 1, label: "A" },
  { value: 2, label: "B" },
  { value: 3, label: "C" },
  { value: 4, label: "D" },
];
