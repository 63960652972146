import { Field } from "formik";
import React, { useEffect, useState } from "react";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";
import {
  get_Attribute_Dropdown,
  get_Class_dropdown,
  get_Country,
  
  get_Value_Dropdown,
} from "../../../services/adminService";
import ModalComponent from "../../../fieldComponent/modalComponent";
import SelectField from "./selectField";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

interface Data {
  name: string;
  description: string;
  id: number;
}
interface ProductCodeData {
  value: string;
  id: number;
}

function AddDeviceForm({ formik }: any) {
  const [modalShow, setModalShow] = useState(false);
  const [confidentiality, setConfidentiality] = useState("");
  const [countryData, setCountryData] = useState<Data[]>([]);
  const [guidelineData, setGuidelineData] = useState<Data[]>([]);
  const [edit, setEdit] = useState<boolean>(false);
  const [dropdownData, setDropdownData] = useState<string[]>([]);
  const [classData, setClassData] = useState<{ [key: string]: string[] }>({});
  
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (
      searchParams.get("edit") !== null &&
      searchParams.get("edit") === "true"
    ) {
      setEdit(true);
      if (countryData?.length > 0) {
        fetchDevice(formik.values.country_id);
      }
    }
  }, [searchParams, countryData, formik]);


  useEffect(() => {
    initialload();
    initialloadClass();
    // initialLoadAttribute();
  }, []);

  const initialload = () => {
    get_Country()
      .then((res) => setCountryData(res?.data))
      .catch((err) => console.log(err));
  };
  const initialloadClass = () => {
    get_Class_dropdown()
      .then((res) => setClassData(res?.data))
      .catch((err) => console.log(err));
  };

  const handleChange = (e: any) => {
    setModalShow(true);
    setConfidentiality(e.target.value);
  };
  const confirmModalHandler = () => {
    setModalShow(false);

    formik.setFieldValue("confidentialityPreference", confidentiality);
  };
  const closeModalHandler = () => {
    setModalShow(false);
  };
  const fetchDevice = (countryId: any) => {
    if (countryId > 0) {
      const countryName = countryData.filter((item) => item.id == countryId)[0]
        .name;
      const dropdowndata = classData[countryName];
      setDropdownData(dropdowndata);
    }
  };

  const countriesData = [
    {
      name: "United States of America",
      id: 1,
      description: null,
      checkboxes: [
        { name: "Slok Traditional" },
        { name: "Slok Special" },
        { name: "Slok abbreviated" },
        { name: "Denovo" },
      ],
    },
    {
      name: "India",
      id: 2,
      description: null,
      checkboxes: [{ name: "Slok Traditional" }, { name: "Denovo" }],
    },
    {
      name: "Philippines",
      id: 3,
      description: null,
      checkboxes: [{ name: "Slok Special" }, { name: "Slok abbreviated" }],
    },
  ];
  return (
    <div className="grid grid-cols-3 gap-4">
      <ModalComponent
        showModal={modalShow}
        closeModal={closeModalHandler}
        header="Confirmation Modal"
        mainBody={ModalBody(confirmModalHandler, closeModalHandler, formik)}
      />
      <div className="bg-[#F8F5F5] p-4 rounded-xl grid grid-cols-2 gap-4 col-span-2 ">
        <label htmlFor="" className="h-11 pt-3">
          Device Name
        </label>
        <Field
          type="text"
          name="name"
          placeholder="Device Name"
          className="inputField w-1/2"
        />
        <label htmlFor="" className="h-11 pt-3">
          Global Medical Device Nomenclature (GMDN)
        </label>
        <Field
          type="text"
          name="meta_data.CompanyCode"
          placeholder="Global Medical Device Nomenclature (GMDN)"
          className="inputField w-1/2"
        />
        <label htmlFor="" className="h-11 pt-3">
          Product Class (US)
        </label>
        <Field
          type="text"
          name="meta_data.productClass"
          placeholder="Product Class (US)"
          className="inputField w-1/2"
        />
        <label htmlFor="" className="h-11 pt-3">
          MODEL No.
        </label>
        <Field
          type="text"
          name="meta_data.DeviceIdentifier"
          placeholder="MODEL No."
          className="inputField w-1/2"
        />
        <label htmlFor="" className="h-11 pt-3">
          Submitter
        </label>
        <Field
          type="text"
          name="meta_data.Submitter"
          placeholder="Submitter"
          className="inputField w-1/2"
        />
        <label htmlFor="" className="h-11 pt-3">
          Primary Correspondent
        </label>
        <Field
          type="text"
          name="meta_data.primaryCorrespondent"
          placeholder="Primary Correspondent"
          className="inputField w-1/2"
        />
        <label htmlFor="" className="h-11 pt-3">
          Panel
        </label>
        <Field
          type="text"
          name="meta_data.Panel"
          placeholder="Generic and Plastic Surgery"
          className="inputField w-1/2"
        />
        <label htmlFor="" className="h-11 pt-3">
          Intended Use
        </label>
        <Field
          type="text"
          name="meta_data.IntentedUse"
          placeholder="Intented Use"
          className="inputField w-1/2"
        />
        {/* <label htmlFor="" className="h-11 pt-3">
          Description
        </label> */}

        <label htmlFor="" className={`min-h-11 pt-3`}>
          Country
        </label>
        <div className={`min-h-11 my-auto`}>
          <div className="flex flex-wrap gap-10 mb-7 h-full my-auto ">
            {countryData?.map((country) => (
              <label key={country.id} className="flex my-auto">
                <Field
                  type="radio"
                  name="country_id"
                  className="form-radio radio my-auto"
                  value={country.id}
                  onChange={(e: any) => {
                    formik.setFieldValue("country_id", e.target.value);
                    fetchDevice(country.id);
                  }}
                  checked={formik.values.country_id == country.id}
                />

                <span className="my-auto uppercase">{country.name}</span>
              </label>
            ))}
          </div>
        </div>
        {/* {dropdownData?.map((item) => (
            <label htmlFor="" className="h-11 pt-3">
              {item.name}
            </label>
          ))} */}
        <label htmlFor="" className="mt-auto">
          Class
        </label>
        <div>
          <Field
            as="select"
            name="meta_data.class"
            className="inputField w-1/2"
          >
            <option value="">Select</option>
            {dropdownData?.map((item) => (
              <option value={item}>{item}</option>
            ))}
          </Field>
        </div>
        {/* {formik.values.country_id && (
          <label htmlFor="" className="mt-auto">
            Guideline
          </label>
        )}

        <div>
          {formik.values.country_id && (
            <div className="grid grid-cols-2 w-full gap-4">
              {guidelineData?.map((checkbox, index) => (
                <div key={checkbox.name}>
                  <label
                    htmlFor={checkbox.name}
                    className="text-sm gap-3 w-fit"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div className="w-6">
                      {formik?.values?.meta_data?.Certifications &&
                      formik.values.meta_data.Certifications &&
                      formik.values.meta_data.Certifications[checkbox.name] ? (
                        <MdCheckBox className="text-[#17C05B] text-2xl w-5" />
                      ) : (
                        <MdCheckBoxOutlineBlank className=" text-2xl w-5" />
                      )}
                    </div>
                    <span className="my-auto break-words">{checkbox.name}</span>
                  </label>
                  <Field
                    type="checkbox"
                    id={checkbox.name}
                    name={`meta_data.Certifications.[${checkbox?.name}]`}
                    className="sr-only"
                  />
                </div>
              ))}
            </div>
          )}
        </div> */}
      </div>
      <div className="h-full flex gap-10">
        <button
          type="submit"
          className=" rounded-2xl bg-white px-[7px] py-[5px] flex mb-4 mt-auto"
          >
          <span className="flex gap-2.5 whitespace-nowrap">
            <IoMdAdd className="h-6 w-6 bg-primary text-white p-[5px] rounded-full" />
            {edit ? "Update and Next" : "Save and Next"}
          </span>
        </button>
          {/* {edit && ( */}
            <button
              type="button"
              className="rounded-2xl bg-white px-[7px] py-[5px] flex mb-4 mt-auto"
              onClick={() => navigate(-1)}
            >
              <span className="flex gap-2.5 whitespace-nowrap">
                <FaArrowLeft className="h-6 w-6 bg-secondary text-white p-[5px] rounded-full" />
                Back To Devices
              </span>
            </button>
          {/* )} */}
      </div>
    </div>
  );
}

export default AddDeviceForm;

const ModalBody = (
  confirmModalHandler: any,
  closeModalHandler: any,
  formik: any
) => {
  return (
    <div className="p-6 space-y-[30px] bg-[#EEEEEE] rounded-2xl">
      <div>Are you sure you want to change this Data?</div>
      <h2 className="flex justify-between   rounded-md mt-10 w-full">
        <div className="my-auto">
          <p className="  text-[14px] text-black my-auto "></p>
        </div>
        <div className=" flex gap-3 ">
          <button
            type="button"
            onClick={() => closeModalHandler()}
            className="rounded-2xl bg-[#E65F2B] px-3 py-[5px] flex text-white "
          >
            No
          </button>
          <button
            type="submit"
            onClick={() => confirmModalHandler()}
            className="rounded-2xl bg-white px-3 py-[5px] flex"
          >
            <span className="flex gap-2.5 whitespace-nowrap">
              <IoMdAdd className="h-6 w-6 bg-[#E65F2B] text-white p-[5px] rounded-full" />
              YES
            </span>
          </button>
        </div>
      </h2>
    </div>
  );
};
