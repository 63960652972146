import { Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { IoMdAdd } from "react-icons/io";
import AddDocumentModalBody from "./addDocumentModalBody";
import ModalComponent from "../../../fieldComponent/modalComponent";
import { useNavigate, useParams } from "react-router-dom";
import {
  delete_Device_Document,
  get_Device_Document,
  get_Device_Document_pagination,
  insert_Device_Document,
  insert_Device_Document_Final,
} from "../../../services/adminService";
import toast from "react-hot-toast";
import moment from "moment";
import { GoTrash } from "react-icons/go";
import { FaArrowLeft } from "react-icons/fa";
import { GrView } from "react-icons/gr";
import { MdModeEdit } from "react-icons/md";
import Pagination from "../../pagination/pagination";

interface Files {
  name: string;
  description: string;
  time_stamp: string;
}

function UploadFileDevice() {
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setdeleteId] = useState(null);
  const [files, setFiles] = useState<Files[]>([]);
  const [showDeleteModal, setshowDeleteModal] = useState<boolean>(false);
  const [pageNo, setPageNo] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [loader, setLoader] = useState(false);
  const [edit, setEdit] = useState(null)


  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    initialLoad();
    localStorage.removeItem("route");
  }, [id]);

  const initialLoad = () => {
    fetchDevicePageCount(perPage);
    // get_Device_Document(id, 5)
    //   .then((res) => {
    //     if (res?.success === true) {
    //       // setFiles(res?.data);
    //     }
    //   })
    //   .catch((err) => console.log(err));
  };
  const fetchDevicePageCount = (perPageValue: number) => {
    setLoader(true);
    get_Device_Document(id, perPageValue)
      .then((res) => {
        if (res.success === true) {
          console.log(res)
          setTotalPages(res.data.page_count);
          setPageNo(1);
          fetchDeviceData(pageNo, perPageValue);
          setLoader(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const fetchDeviceData = (pageNoValue: number, perPageValue: number) => {
    setLoader(true);
    const payload = `device_id=${id}&page=${pageNoValue - 1}&page_size=${perPageValue}`;
    get_Device_Document_pagination(payload)
      .then((res) => {
        setFiles(res?.data);
        setLoader(false);
      })

      .catch((err) => console.log(err));
  };
  const [filterFileName, setFilterFileName] = useState("");
  const [filterUploadedBy, setFilterUploadedBy] = useState("");

  const handleFilterFileNameChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setFilterFileName(e.target.value);
  };

  const handleFilterUploadedByChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setFilterUploadedBy(e.target.value);
  };

  const uniqueUploadedByNames = Array.from(
    new Set(files?.map((file) => file.time_stamp))
  );

  const filteredFiles = files?.reduce((filtered: any, file: any) => {
    if (
      (filterFileName && file.name !== filterFileName) ||
      (filterUploadedBy && file.time_stamp !== filterUploadedBy)
    ) {
      return filtered;
    }

    return [...filtered, file];
  }, [] as File[]);

  const confirmModalHandler = (formdata: any) => {
    insert_Device_Document(formdata)
      .then((res) => {
        if (res?.success === true) {
          notify(res?.message);
          // finalUpload(res.data) 
          setShowModal(false);
          setEdit(null)
          initialLoad();
        } else if (res?.success === false) {
          notifyFailure(res?.message);
        }
      })
      .catch((err) => console.log(err));
    };
    
    const finalUpload = (value: any) => {
      
      const payload  ={
        device_id: id,
        document_ids: [value]
      }
      
      insert_Device_Document_Final(payload)
    }
    const closeModalHandler = () => {
      setShowModal(false);
      setEdit(null)
    };
  const handleDelete = (data: any) => {
    setdeleteId(data.id);
    setshowDeleteModal(true);
  };
  
  const confirmDeleteModalHandler = async () => {
    const response = await delete_Device_Document(deleteId);
    if (response) {
      notify("Successfully Guideline Deleted");
      initialLoad();
      setdeleteId(null);
      setshowDeleteModal(false);
    }
  };
  const closeDeleteModalHandler = () => {
    setshowDeleteModal(false);
    setdeleteId(null);
  };
  const handleViewClick = async (id: any) => {
    setShowModal(true)
    setEdit(id)
  }
  const onNext = () => {
    if (pageNo <= totalPages) {
      setPageNo(pageNo + 1);
      fetchDeviceData(pageNo + 1, perPage);
    }
  };
  const onPrevious = () => {
    if (pageNo > 1) {
      setPageNo(pageNo - 1);
      fetchDeviceData(pageNo - 1, perPage);
    }
  };

  const handleSetPage = (page: number) => {
    setPageNo(page);
    fetchDeviceData(page, perPage);
  };

  const handlePerPage = (value: any) => {
    setPerPage(parseInt(value));
    fetchDevicePageCount(parseInt(value));
  };
  const notify = (value: any) => {
    toast.success(value);
    // simulate authentication success
  };
  const notifyFailure = (value: any) => {
    toast.error(value);
    // simulate authentication failure
  };
  return (
    <div className="p-8 flex flex-col gap-y-10">
      <div className="p-[18px] bg-[#F8F6F6] rounded-xl">
        <div className="flex justify-between">
          <label className="main-title font-bold">Add a Device Document</label>
          <button
            type="button"
            className="rounded-2xl bg-white px-[7px] py-[5px] flex mb-4 mt-auto"
            onClick={() => navigate("/smartsurgn/devices")}
          >
            <span className="flex gap-2.5 whitespace-nowrap">
              <IoMdAdd className="h-6 w-6 bg-primary text-white p-[5px] rounded-full" />
              Done
            </span>
          </button>
        </div>
        <div className="bg-[#F8F5F5] p-4 rounded-xl mt-4">
          <ModalComponent
            showModal={showModal}
            closeModal={closeModalHandler}
            header="Add Device Document"
            mainBody={AddDocumentModalBody(
              confirmModalHandler,
              closeModalHandler,
              id,
              edit
            )}
          />
          <ModalComponent
            showModal={showDeleteModal}
            closeModal={() => setshowDeleteModal(false)}
            header="Confirmation Modal"
            mainBody={ModalBody(
              confirmDeleteModalHandler,
              closeDeleteModalHandler
            )}
          />
          <button
            type="button"
            className=" rounded-2xl bg-white px-[7px] py-[5px] flex mb-4"
            onClick={() => setShowModal(true)}
          >
            {" "}
            <span className="flex gap-2.5 whitespace-nowrap">
              <IoMdAdd className="h-6 w-6 bg-primary text-white p-[5px] rounded-full" />
              Upload file
            </span>
          </button>
          <div className=" w-full">
            <div className="flex justify-between">
              <label className="text-[#060606] font-bold">Attachments</label>
              <div className="flex gap-2 w-1/2">
                <select
                  name="uploadedBy"
                  id="uploadedBy"
                  className="inputField w-full"
                  value={filterUploadedBy}
                  onChange={handleFilterUploadedByChange}
                >
                  <option value="">Uploaded By</option>
                  {uniqueUploadedByNames?.map((item, index) => (
                    <option key={index} value={item}>
                      {moment(item).format("DD / MM / YYYY")}
                    </option>
                  ))}
                </select>
                <select
                  name="fileName"
                  id="fileName"
                  className="inputField w-full"
                  value={filterFileName}
                  onChange={handleFilterFileNameChange}
                >
                  <option value="">File Name</option>
                  {files?.map((item, index) => (
                    <option key={index} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <table className="w-full my-6 text-sm">
              <thead>
                <tr>
                  <th className="pb-2.5 text-start">File Name</th>
                  <th className="pb-2.5 text-center">Description</th>
                  <th className="pb-2.5 text-center">Uploaded by</th>
                  <th className="pb-2.5 text-end">Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredFiles?.map((file: any, index: number) => (
                  <tr
                    key={index}
                    className="hover:font-bold hover:text-blue-800"
                  >
                    <td className="text-start">{file.name}</td>
                    <td className="text-center">{file.description}</td>
                    <td className="text-center">
                      {moment(file.time_stamp).format(" DD / MM / YYYY")}
                    </td>
                    <td className="text-end flex justify-end">
                    <MdModeEdit 
                        className=" text-xl  cursor-pointer"
                        onClick={() => handleViewClick(file)}
                      />
                      <GoTrash
                        title="Delete Device Document"
                        className="text-xl  hover:font-bold hover:text-red-900 text-secondary text-red-500 cursor-pointer"
                        onClick={() => handleDelete(file)}
                      />
                    </td>
                  </tr>
                ))}
                <tr>
                    <td colSpan={5}>
                      <Pagination
                        currentPage={pageNo}
                        onNext={onNext}
                        onPrevious={onPrevious}
                        from={pageNo * perPage - perPage}
                        to={pageNo * perPage}
                        totalItems={totalPages * perPage}
                        perPage={perPage}
                        totalPages={totalPages}
                        setPage={handleSetPage}
                        setperPage={handlePerPage}
                      />
                    </td>{" "}
                  </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="h-full flex gap-10 mt-10 justify-end">
          <button
            type="button"
            className="rounded-2xl bg-white px-[7px] py-[5px] flex mb-4 mt-auto"
            onClick={() => navigate(-1)}
          >
            <span className="flex gap-2.5 whitespace-nowrap">
              <FaArrowLeft className="h-6 w-6 bg-secondary text-white p-[5px] rounded-full" />
              Previous Step
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default UploadFileDevice;

const ModalBody = (
  confirmDeleteModalHandler: any,
  closeDeleteModalHandler: any
) => {
  return (
    <div className="p-6 space-y-[30px] bg-[#EEEEEE] rounded-2xl">
      <div>Are you sure you want to delete this Document?</div>
      <h2 className="flex justify-between   rounded-md mt-10 w-full">
        <div className="my-auto">
          <p className="  text-[14px] text-black my-auto "></p>
        </div>
        <div className=" flex gap-3 ">
          <button
            type="button"
            onClick={() => closeDeleteModalHandler()}
            className="rounded-2xl bg-secondary px-3 py-[5px] flex text-white "
          >
            No
          </button>
          <button
            type="button"
            className="rounded-2xl bg-white px-3 py-[5px] flex"
            onClick={() => confirmDeleteModalHandler()}
          >
            <span className="flex gap-2.5 whitespace-nowrap">
              <IoMdAdd className="h-6 w-6 bg-primary text-white p-[5px] rounded-full" />
              YES
            </span>
          </button>
        </div>
      </h2>
    </div>
  );
};
